import { useContext, useEffect } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Loading from "views/Loading";
import { Outlet, useNavigate } from "react-router-dom";
import ItemColumn from "../ItemColumn";
import Select from "react-select";
import ItemColumnButton from "../ItemColumnButton";
import { context as TemplateContext } from "./provider";
import { useSelector } from "hooks";
import { RootState } from "state/reducers";

export default function TemplateOptions() {
  const {
    templates,
    components,
    placements,
    loading,
    templateID,
    componentID,
    placementID,
    deleteComponent,
    deletePlacement,
    group,
    setGroup,
  } = useContext(TemplateContext);

  const groups = useSelector((state) => state.group.groups);
  const template = templates.find((t) => t.id === templateID);
  const component = components.find((c) => c.id === componentID);

  const projects = useSelector((state: RootState) => state.user.missions);
  const missionID = useSelector((state: RootState) => state.mission.id);

  const skyqraft_employee = useSelector(
    (state: RootState) => state.user.skyqraft_employee
  );

  const editAccess = !group?.access_levels?.includes("readonly");

  const navigate = useNavigate();

  function updateGroup(group: RootState["group"]["groups"][number] | null) {
    setGroup(group);

    // Unset template id when changing group
    if (templateID) {
      navigate("/options/pole/status/template");
    }
  }

  useEffect(() => {
    if (groups.length > 0) {
      const missionGroup = projects.find((project) =>
        project.id === missionID ? project.group : null
      );
      const groupId = missionGroup?.group?.id;

      if (groupId) {
        updateGroup(groups.find((g) => g.id === groupId) ?? null);
      } else if (!skyqraft_employee) {
        updateGroup(groups[0]); // fallback to first available group if not internal user (then global is used)
      }
    }
  }, [groups, missionID, projects]);

  return (
    <Box>
      {loading && (
        <Stack
          width={"100%"}
          p={2}
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Loading relative size={60} color="black" />
          <Typography>Loading templates...</Typography>
        </Stack>
      )}
      <Typography variant="h5">Select Customer</Typography>
      <Typography mb={1}>
        Structure templates are customer specific. Please select a customer
        first.
      </Typography>
      <Select
        options={[
          ...(skyqraft_employee
            ? [
                {
                  value: null,
                  label: "Global",
                },
              ]
            : []),
          ...groups.map((g) => ({ value: g.id, label: g.name })),
        ]}
        value={
          group
            ? {
                value: group.id,
                label: group.name,
              }
            : {
                value: null,
                label: skyqraft_employee ? "Global" : "Select a customer",
              }
        }
        onChange={(e) => {
          updateGroup(groups.find((g) => g.id === e.value) ?? null);
        }}
      />
      <Divider sx={{ my: 2 }} />
      {!loading && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem sx={{ mx: 2 }} />}
        >
          <ItemColumn
            title="Templates"
            description="A template is used to add status data to a pole quickly"
          >
            {templates
              .filter(
                (template) =>
                  template.group_id === group?.id ||
                  (template.group_id === null &&
                    group === null &&
                    skyqraft_employee)
              )
              .map((template) => {
                const templateComponents = new Set();
                for (const item of template.items) {
                  templateComponents.add(item.component.id);
                }
                const nComponents = templateComponents.size;

                return (
                  <ItemColumnButton
                    link={`/options/pole/status/template/${template.id}`}
                    active={templateID === template.id}
                    chip={nComponents}
                    {...(editAccess && {
                      editLink: `/options/pole/status/template/${template.id}/edit`,
                    })}
                  >
                    {template.name}
                  </ItemColumnButton>
                );
              })}
          </ItemColumn>
          <ItemColumn
            title={template?.name ?? "No template selected"}
            description="The following components are present in this template"
          >
            {components.map((c) => {
              return (
                <ItemColumnButton
                  link={`/options/pole/status/template/${templateID}/component/${c.id}`}
                  active={componentID === c.id}
                  chip={c.nPlacements}
                  {...(editAccess && {
                    onDelete: () => {
                      const confirmation = window.confirm(
                        `Are you sure you want to delete component ${c.name} from template ${template.name}?`
                      );
                      if (confirmation) {
                        deleteComponent(templateID, c.id);
                      }
                    },
                  })}
                >
                  {c.name}
                </ItemColumnButton>
              );
            })}
          </ItemColumn>
          <ItemColumn
            title={component?.name ?? "No component selected"}
            description="The following placement are present in this component and template"
          >
            {placements.map((placement) => {
              return (
                <ItemColumnButton
                  link={`/options/pole/status/template/${templateID}/component/${componentID}/placement/${placement.id}`}
                  active={placementID === placement.id}
                  {...(editAccess && {
                    onDelete: () => {
                      const confirmation = window.confirm(
                        `Are you sure you want to delete placement ${placement.name} from template ${template.name}?`
                      );
                      if (confirmation) {
                        deletePlacement(templateID, componentID, placement.id);
                      }
                    },
                  })}
                >
                  {placement.name}
                </ItemColumnButton>
              );
            })}
          </ItemColumn>
        </Stack>
      )}
      <Outlet />
    </Box>
  );
}
