import { ObjectType } from "interfaces";
import { createContext } from "react";
import { getAnnotationsFromAPI } from "../../api";

export type ISpecialAnnotation = Awaited<
  ReturnType<typeof getAnnotationsFromAPI>
>[number];

export interface IContextValue {
  annotations: ISpecialAnnotation[];
  originalAnnotations: ISpecialAnnotation[];
  loading: boolean;
  error?: string;
  savedTypes: number;
  objectTypes: ObjectType[];
  saveFeedback: string;
  steelworkObjectTypeId: number;
  highlightedId: string | null;
  setSavedTypes: (savedTypes: number | null) => void;
  updateAnnotation: (
    annotationID: string,
    annotation: ISpecialAnnotation
  ) => void;
  saveAnnotations: (region, annotation) => Promise<string>;
  setAnnotations: (annotations: ISpecialAnnotation[]) => void;
  setOriginalAnnotations: (annotations: ISpecialAnnotation[]) => void;
  resetAnnotations: () => void;
  deleteAnnotation: (annotationID: string) => void;
  setSelectedAnnotation: (annotationID: string | null) => void;
  getAnnotationsFunction: () => void;
  setHoveredAnnotation: (annotationID: string | null) => void;
  exit: () => void;
}

// Set up a context that is importable
const context = createContext<IContextValue>({
  annotations: [],
  originalAnnotations: [],
  loading: false,
  error: undefined,
  savedTypes: null,
  objectTypes: [],
  saveFeedback: "",
  steelworkObjectTypeId: null,
  highlightedId: null,
  setSavedTypes: () => {},
  updateAnnotation: () => {},
  saveAnnotations: () => Promise.resolve(""),
  setAnnotations: () => {},
  setOriginalAnnotations: () => {},
  resetAnnotations: () => {},
  deleteAnnotation: () => {},
  setSelectedAnnotation: () => {},
  getAnnotationsFunction: () => {},
  setHoveredAnnotation: () => {},
  exit: () => {},
});

export { context };
