import { useState } from "react";
import {
  Alert,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useCurrentProject, useLanguage } from "hooks";
import { useProjectImageLists } from "./hooks";
import ImageListSelector from "./ImageListSelector";
import Loading from "views/Loading";
import translations from "./translations";

type ProjectImageListType = ReturnType<
  typeof useProjectImageLists
>["list"][number];

export default function ImageReport() {
  const { language } = useLanguage();
  const [selectedList, setSelectedList] = useState<ProjectImageListType | null>(
    null
  );
  const currentProject = useCurrentProject();
  const { list, loading } = useProjectImageLists(currentProject?.id);

  function onSelectList(list: ProjectImageListType | null) {
    setSelectedList(list);
  }

  return (
    <Container sx={{ pt: 2 }}>
      <Stack spacing={1}>
        {!currentProject && (
          <Alert severity="warning">
            {translations.NoProjectSelected[language]}
          </Alert>
        )}
        {currentProject && !list.length && (
          <Alert severity="info">{translations.NoImageLists[language]}</Alert>
        )}
        <Typography variant="h4" component="h2">
          {translations.ImageReport[language]}
        </Typography>
        <Typography>{translations.ImageReportDescription[language]}</Typography>
        <Divider />
        {loading ? (
          <Loading relative size={50} color="black" />
        ) : (
          <ImageListSelector
            imageList={list}
            selectedList={selectedList}
            onSelect={onSelectList}
          />
        )}
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="contained" color="primary">
            {translations.GenerateReport[language]}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
