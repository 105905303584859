import { MenuItem } from "@mui/material";
import { bbox, featureCollection, polygon } from "@turf/turf";
import { IUserSettings } from "api/user/userSettings";
import { FilterKey } from "hooks/filter/search_parameters/constants";
import RoleWrapper from "components/RoleHOC/wrapper";
import {
  ANNOTATOR_INSPECTION,
  BURIED_COLOR,
  CRITICAL_INSPECTION,
  HOVER_COLOR,
  MAIN_BLUE,
  NEEDS_REFLIGHT,
  POLE_ZOOM,
  SUPERUSER_INSPECTION,
  SUPERVISOR_INSPECTION,
  WIDTH_HOVER,
  WIDTH_NOT_SELECTED,
  WIDTH_SELECTED,
} from "config";
import { getFilterActive } from "hooks";
import { Pole } from "interfaces";
import { toast } from "react-toastify";
import { setPowerlineController, updateMarkers } from "state/actions";
import store, { RootState } from "state/store";
import { loading } from "state/view/events";
import tokml from "tokml";
import {
  checkTopologyData,
  getFeedIdentifier,
  zoomToFeedIdentifiers,
} from "utils/map";
import {
  authorizedDelete,
  authorizedGet,
  authorizedPost,
  axiosInstance,
} from "utils/request";
import { toGmapBounds } from "utils/utils";
import AnnotatorInspectorItem from "views/map/menu/MenuItems/AnnotatorInspectionItem";
import BuriedItem from "views/map/menu/MenuItems/BuriedItem";
import CalculateLength from "views/map/menu/MenuItems/CalculateLength";
import CriticalInspectionItem from "views/map/menu/MenuItems/CriticalInspectionItem";
import EditImageVisibility from "views/map/menu/MenuItems/EditImageVisibility";
import GMapItem from "views/map/menu/MenuItems/GMapItem";
import HasImagesItem from "views/map/menu/MenuItems/HasImagesItem";
import ShowImage from "views/map/menu/MenuItems/ImageItem";
import InspectionItem from "views/map/menu/MenuItems/InspectionItem";
import ReflightItem from "views/map/menu/MenuItems/ReflightItem";
import SuperInspectionItem from "views/map/menu/MenuItems/SuperInspectionItem";
import ZoneItem from "views/map/menu/MenuItems/ZoneItem";
import { changeMission } from "../mission";
import { getMissionAnnotators } from "./drawingManager";
import { setAnnotatorColor } from "./drawingManager";
import { showMenu } from "./menu";
export * from "./inspectorMode";
export * from "./markers";
export * from "./drawingManager";
export * from "./filterAreaSelector";
export { getMissionAnnotators, setAnnotatorColor };

export const setMapBounds = (bounds: {
  west: number;
  east: number;
  south: number;
  north: number;
}) => {
  return {
    type: "SET_MAP_BOUNDS",
    payload: bounds,
  };
};

export const setPowerlineBlock = (value) => {
  return {
    type: "SET_POWERLINE_BLOCK",
    payload: value,
  };
};

export const setMapType = (value: string) => {
  return {
    type: "SET_MAP_TYPE",
    payload: value,
  };
};

export const setPreviewMode = (value: boolean) => {
  return {
    type: "SET_PREVIEW_MODE",
    payload: value,
  };
};

export const setGoogleMap = (value) => {
  return {
    type: "SET_MAP",
    payload: value,
  };
};

export const setMapTool = (value: string | null) => {
  return {
    type: "SET_MAP_TOOL",
    payload: value,
  };
};

export function setPoles(poles: Pole[]) {
  return {
    type: "SET_POLES",
    payload: poles,
  };
}

export function setPreviousDefectPoles(poles: Pole[]) {
  return {
    type: "SET_PREVIOUS_DEFECT_POLES",
    payload: poles,
  };
}
export function setPreviousDefectPolesPage(page: number) {
  return {
    type: "SET_PREVIOUS_DEFECT_POLES_PAGE",
    payload: page,
  };
}
export function setPreviousDefectPolesCount(value: {
  pages: number;
  poles: number;
}) {
  return {
    type: "SET_PREVIOUS_DEFECT_POLES_COUNT",
    payload: value,
  };
}

export function setPreviousDefectProject(project: number | null) {
  return {
    type: "SET_PREVIOUS_DEFECT_PROJECT",
    payload: project,
  };
}
export const setPolesLoading = (value: boolean) => {
  return {
    type: "SET_POLES_LOADING",
    payload: value,
  };
};

export function onMapClick() {
  // Close Menu
  // biome-ignore lint/nursery/useAwait: It is supposed to return an async function
  return async (dispatch) => {
    dispatch(clearSelection());
    dispatch(hideMenu());
  };
}

function onMapDrag() {
  return hideMenu();
}

export function powerlineColor(
  feature: google.maps.Data.Feature,
  inspectorMode: boolean,
  topologyMode: boolean,
  previewMode: boolean,
  powerlineName: string,
  user: IUserSettings | null = null
) {
  const hasImages = feature.getProperty("hasImages");
  const inspected = feature.getProperty("inspected");
  const needsReflight = feature.getProperty("needsReflight");
  const name = feature.getProperty("name");
  const buried = feature.getProperty("buried");
  const superInspected = feature.getProperty("super_inspected");
  const annotatorInspected = feature.getProperty("annotator_inspected");
  const criticalInspected = feature.getProperty("critical_inspected");
  const topologyColor = feature.getProperty("topologyColor");

  if (!!topologyMode && !!topologyColor) {
    return topologyColor;
  }
  if (buried) {
    return BURIED_COLOR;
  }
  if (!hasImages) {
    return "red";
  }
  if (inspectorMode) {
    if (needsReflight) {
      return NEEDS_REFLIGHT;
    }
    if (user?.superuser && superInspected) {
      return SUPERUSER_INSPECTION;
    }
    if (inspected) {
      return SUPERVISOR_INSPECTION;
    }
    if (annotatorInspected) {
      return ANNOTATOR_INSPECTION;
    }
    if (criticalInspected) {
      return CRITICAL_INSPECTION;
    }
  } else {
    if (name && name === powerlineName) {
      return HOVER_COLOR;
    }
    if (superInspected) {
      return SUPERUSER_INSPECTION;
    }
  }

  if (hasImages) {
    if (criticalInspected) {
      return CRITICAL_INSPECTION;
    } else {
      return MAIN_BLUE;
    }
  } else if (buried) {
    return BURIED_COLOR;
  } else {
    return "red";
  }
}

export function powerlineWidth(
  feature: google.maps.Data.Feature,
  powerlineName,
  inspectorMode
) {
  const name = feature.getProperty("name");
  const hasImages = feature.getProperty("hasImages");
  let width: number;
  if (name && name === powerlineName && !inspectorMode) {
    width = WIDTH_HOVER;
  } else if (hasImages) {
    width = WIDTH_SELECTED;
  } else {
    width = WIDTH_NOT_SELECTED;
  }
  return width;
}

export function getFeatureStyle(
  feature: google.maps.Data.Feature,
  inspectorMode: boolean,
  topologyMode: boolean,
  previewMode: boolean,
  powerlineName: string,
  showPowerlines: boolean,
  showZones: boolean,
  user: IUserSettings | null = null,
  highlight = false,
  feedIdentifiers: string[] | null = null
) {
  const powerline = feature.getProperty("powerline");
  const type = feature.getGeometry().getType();
  const isClusterRadius = feature.getProperty("radius");
  const drawingType = feature.getProperty("drawingType");

  if (type === "Polygon" && !isClusterRadius) {
    const color = feature.getProperty("color");
    if (user?.annotator_color) {
      return {
        zIndex: 1,
        visible: showZones,
        fillColor: color,
        strokeWeight: highlight ? 10 : 1,
      };
    } else {
      return {
        zIndex: 1,
        visible: showZones,
        strokeWeight: highlight ? 10 : 1,
      };
    }
  } else if (drawingType?.id) {
    return getDrawingStyle(feature);
  } else if (powerline !== undefined) {
    return powerlineStyle(
      feature,
      inspectorMode,
      topologyMode,
      previewMode,
      powerlineName,
      showPowerlines,
      user,
      feedIdentifiers
    );
  }
}

export function powerlineStyle(
  feature: google.maps.Data.Feature,
  inspectorMode: boolean,
  topologyMode: boolean,
  previewMode: boolean,
  powerlineName: string,
  showPowerlines: boolean,
  user: IUserSettings | null = null,
  highlightedFeedIdentifiers: string[] | null = null
) {
  const hasImages = feature.getProperty("hasImages");
  const inspected = feature.getProperty("inspected");
  const needsReflight = feature.getProperty("needsReflight");
  const name = feature.getProperty("name");
  const buried = feature.getProperty("buried");
  const superInspected = feature.getProperty("super_inspected");
  const annotatorInspected = feature.getProperty("annotator_inspected");
  const criticalInspected = feature.getProperty("critical_inspected");
  const topologyColor = feature.getProperty("topologyColor");

  const feedBay = feature.getProperty("feedBay");
  const feedStation = feature.getProperty("feedStation");
  const feedIdentifier = getFeedIdentifier(feedStation, feedBay);

  // This is the default style of a powerline
  const style = {
    strokeColor: "red",
    strokeWeight: 2,
    zIndex: 2,
    visible: showPowerlines,
  };

  // Define the color of the powerline
  if (
    highlightedFeedIdentifiers !== null &&
    highlightedFeedIdentifiers.length > 0 &&
    !highlightedFeedIdentifiers.includes(feedIdentifier)
  ) {
    style.strokeColor = "grey";
  } else if (topologyMode && topologyColor) {
    style.strokeColor = topologyColor;
  } else if (buried) {
    style.strokeColor = BURIED_COLOR;
  } else if (superInspected) {
    style.strokeColor = SUPERUSER_INSPECTION;
  } else if (name && name === powerlineName) {
    style.strokeColor = HOVER_COLOR;
  } else if (inspectorMode) {
    if (needsReflight) {
      style.strokeColor = NEEDS_REFLIGHT;
    } else if (user?.superuser && superInspected) {
      style.strokeColor = SUPERUSER_INSPECTION;
    } else if (inspected) {
      style.strokeColor = SUPERVISOR_INSPECTION;
    } else if (annotatorInspected) {
      style.strokeColor = ANNOTATOR_INSPECTION;
    } else if (criticalInspected) {
      style.strokeColor = CRITICAL_INSPECTION;
    } else if (criticalInspected) {
      style.strokeColor = CRITICAL_INSPECTION;
    } else if (hasImages) {
      style.strokeColor = MAIN_BLUE;
    } else {
      style.strokeColor = "red";
    }
  } else if (hasImages) {
    if (criticalInspected) {
      style.strokeColor = CRITICAL_INSPECTION;
    } else {
      style.strokeColor = MAIN_BLUE;
    }
  }

  // Define the width of the powerline
  if (name && name === powerlineName && !inspectorMode) {
    style.strokeWeight = 5;
  } else if (
    highlightedFeedIdentifiers !== null &&
    highlightedFeedIdentifiers.length > 0 &&
    highlightedFeedIdentifiers.includes(feedIdentifier)
  ) {
    style.strokeWeight = 5;
  } else if (hasImages) {
    style.strokeWeight = 3;
  } else {
    style.strokeWeight = 2;
  }

  if (!showPowerlines) {
    style.visible = false;
  }

  return style;
}

export function getDrawingStyle(feature: google.maps.Data.Feature) {
  const drawingType = feature.getProperty("drawingType");

  let strokeColor = "black";
  if (drawingType?.id === 1) {
    strokeColor = "yellow";
  }

  // This is the default style of a powerline
  const style = {
    strokeColor,
    strokeWeight: 2,
    zIndex: 2,
  };

  return style;
}

export function hideMenu() {
  return {
    type: "SET_MENU",
    payload: {
      show: false,
      x: null,
      y: null,
      lat: null,
      lng: null,
      hasImages: false,
      items: [],
    },
  };
}

export function onDataClick(event, map: google.maps.Map) {
  return (dispatch, getState) => {
    dispatch({ type: "HIDE_MENU" });
    const state: RootState = getState();
    const feature = event.feature as google.maps.Data.Feature;
    const powerLine = feature.getProperty("powerline");
    const hasImages = feature.getProperty("hasImages");
    const coveragePolygon = feature.getProperty("coverage");
    const link = feature.getProperty("link");
    const leftClick = event.domEvent?.which === 1;
    const rightClick = event.domEvent?.which === 3;

    if (rightClick) {
      if (link) {
        dispatch(
          showMenu(event, false, getLidarMenuItems(event, map, state), map)
        );
      }
    }

    if (leftClick) {
      if (coveragePolygon) {
        const mission = feature.getProperty("missionid");
        dispatch(changeMission(mission));
      }

      const items = [];

      if (powerLine) {
        const { latLng } = event;
        const data = {
          lat: latLng.lat(),
          lng: latLng.lng(),
        };
        if (hasImages) {
          items.push(<ShowImage key={0} data={data} />);
        }
        items.push(<GMapItem key={1} data={data} />);

        dispatch(showMenu(event, hasImages, items, map));
      }
    }
  };
}

const fetchPowerLines = async (mission: number) => {
  try {
    if (!mission) {
      return { lines: [], features: [], empty: true };
    }
    const response = await authorizedGet<{ lines; features }>(
      `project/${mission}/powerline`
    );
    if (window.location.pathname === "/coverage") {
      return response.lines;
    }
    if (response.features.length === 0) {
      console.log("No powerlines found. Using image bounds");
    }
    return response;
  } catch (error) {
    console.error("failed fetching powerlines: ", error);
    return featureCollection([]);
  } finally {
    loading.sendMessage("powerlines", false);
  }
};

const fetchZones = async (mission: number) => {
  try {
    const response = await authorizedGet<{ features }>(
      `/zone/get${window.location.search}`,
      {
        MissionId: mission,
      },
      {},
      true
    );
    return response;
  } catch (error) {
    console.error("failed fetching zones: ", error);
    return featureCollection([]);
  }
};

export function setTopologyMode(value: boolean) {
  return (dispatch, getState) => {
    if (value) {
      const state: RootState = getState();
      checkTopologyData(state.map.gmap, state.user.language);
    }
    dispatch({
      type: "SET_TOPOLOGY_MODE",
      payload: value,
    });
  };
}

function setLoadingZones(value: boolean) {
  return {
    type: "SET_LOADING_ZONES",
    payload: value,
  };
}

export function loadZones(map: google.maps.Map, mission: number) {
  // Skip if there is not mission
  if (!(!Number.isNaN(mission) && !!mission)) {
    return () => {};
  }

  return async (dispatch, getState) => {
    const state: RootState = getState();
    const zonesLoading = state.ui.zonesLoading;
    const showZones = state.ui.showZones;
    if (zonesLoading || !showZones) {
      // For performance we don't load zones if they aren't displayed.
      return;
    }
    if (map) {
      dispatch(setLoadingZones(true));
      // biome-ignore lint/complexity/noForEach: google maps require forEach features
      map.data.forEach((feature) => {
        if (feature.getProperty("zone")) {
          map.data.remove(feature);
        }
      });
      fetchZones(mission).then((zones) => {
        map.data.addGeoJson(zones);
        dispatch(setLoadingZones(false));
      });
    }
  };
}

export function loadPowerlines(
  map: google.maps.Map,
  updateBounds: boolean,
  mission: number
) {
  return (dispatch, getState) => {
    if (!map) {
      return;
    }
    // biome-ignore lint/complexity/noForEach: google maps require forEach features
    map.data.forEach((feature) => {
      if (feature.getProperty("powerline")) {
        map.data.remove(feature);
      } else if (feature.getProperty("isBoundingBox")) {
        map.data.remove(feature);
      }
    });
    dispatch(setHasPowerlines(false));
    dispatch(setPowerlinesLoaded(false));
    const state: RootState = getState();
    const oldController = state.map.powerlineController;
    if (oldController) {
      oldController.abort();
    }

    const newController = new AbortController();
    dispatch(setPowerlineController(newController));

    // Collect new powerlines
    fetchPowerLines(mission)
      .then((lines) => {
        dispatch(setPowerlineController(null));
        if (lines?.empty) {
          // biome-ignore lint/complexity/noForEach: Google maps require forEach features
          map.data.forEach((feature) => {
            if (feature.getProperty("powerline")) {
              map.data.remove(feature);
            }
          });
          return;
        }
        // Delete all features on the map

        if (window.location.pathname === "/coverage") {
          const hulls = [];
          for (const lineCollection of lines) {
            if (lineCollection.geom) {
              const feature = polygon(
                JSON.parse(lineCollection.geom).coordinates
              );
              feature.properties.missionid = lineCollection.id;
              feature.properties.coverage = true;
              hulls.push(feature);
            }
          }
          const collection = featureCollection(hulls);

          // biome-ignore lint/complexity/noForEach: Google maps require forEach features
          map.data.forEach((feature) => {
            if (feature.getProperty("powerline")) {
              map.data.remove(feature);
            }
          });
          map.data.addGeoJson(collection);
          return;
        }

        for (const feature of lines.features) {
          feature.properties.powerline = true;
        }
        // Zoom to images if we have no powerlines
        let mapBounds: google.maps.LatLngBoundsLiteral;
        if (lines.features.length > 0) {
          mapBounds = toGmapBounds(bbox(lines));
        } else if (lines?.backup) {
          mapBounds = lines.backup;
        } else {
          mapBounds = {
            west: 10,
            east: 20,
            north: 70,
            south: 50,
          };
        }
        if (!window.location.pathname.match(/\/\d+\/\d+.*/g) && updateBounds) {
          map.fitBounds(mapBounds);
        }

        // Add bounding box if no powerlines exists
        if (
          lines.features.length === 0 &&
          !!lines?.backup &&
          !!lines?.backup?.east
        ) {
          dispatch(setPowerlinesLoaded(true));
          dispatch(setHasPowerlines(false));
          lines.features.push({
            geometry: {
              coordinates: [
                [mapBounds.west - 0.001, mapBounds.north + 0.001],
                [mapBounds.east + 0.001, mapBounds.north + 0.001],
                [mapBounds.east + 0.001, mapBounds.south - 0.001],
                [mapBounds.west - 0.001, mapBounds.south - 0.001],
                [mapBounds.west - 0.001, mapBounds.north + 0.001],
              ],
              type: "LineString",
            },
            properties: {
              isBoundingBox: true,
            },
            type: "Feature",
          });
        } else {
          dispatch(setPowerlinesLoaded(true));
          dispatch(setHasPowerlines(true));
        }
        // biome-ignore lint/complexity/noForEach: Google maps require forEach features
        map.data.forEach((feature) => {
          if (feature.getProperty("powerline")) {
            map.data.remove(feature);
          }
        });
        map.data.addGeoJson(lines);
        dispatch(onBoundsChanged());
      })
      .catch(() => {})
      .finally(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const bayFeedURLValue = searchParams.get(FilterKey.FEED_BAY);
        const topologyMode = state.map.topologyMode;
        if (topologyMode) {
          checkTopologyData(map, state.user.language);
        }
        const filterActive = getFilterActive(searchParams);
        dispatch(updateFeedIdentifiers(bayFeedURLValue, filterActive));
      });
  };
}

export function updateFeedIdentifiers(
  bayFeedURLValue: string | null,
  filterActive: boolean
) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const map = state.map.gmap;
    if (!map) return;
    const topologyMode = state.map.topologyMode;
    const inspectorMode = state.map.inspectorMode;
    const previewMode = state.map.previewMode;
    const powerlineName = state.map.powerlineName;
    const showPowerlines = state.ui.showPowerlines;
    const showZones = state.ui.showZones;
    const user = state.user;
    let highlightedFeedIdentifiers = (bayFeedURLValue?.split(",") ?? []).filter(
      (x) => x.length > 0
    );
    if (!filterActive) {
      highlightedFeedIdentifiers = [];
    }

    map.data.setStyle((feature) =>
      getFeatureStyle(
        feature,
        inspectorMode,
        topologyMode,
        previewMode,
        powerlineName,
        showPowerlines,
        showZones,
        user,
        false,
        highlightedFeedIdentifiers
      )
    );
  };
}

export function setMenuMarker(menuMarker) {
  return {
    type: "SET_MENU_MARKER",
    payload: menuMarker,
  };
}

export function onBoundsChanged() {
  return (dispatch) => {
    dispatch(updateMarkers());
  };
}

export function updatePowerlineColor(
  features: google.maps.Data.Feature[],
  parameter: string,
  value,
  map: google.maps.Map
) {
  return (dispatch, getState) => {
    if (features.length > 0) {
      for (const feature of features) {
        map.data.remove(feature);
        feature.setProperty(parameter, value);
        map.data.add(feature);
      }
    }
    dispatch(onMapClick());
  };
}

export function saveBounds(bounds) {
  return {
    type: "SAVE_BOUNDS",
    payload: bounds,
  };
}

export function loadBounds() {
  const state: RootState = store.getState();
  const bounds = state.map.bounds;
  if (bounds) {
    state.map.gmap.fitBounds(bounds);
  }
  return () => {};
}

export function addPole(lat: number, lng: number) {
  return async (dispatch) => {
    await authorizedPost("/poles/add", { lat, lng });
    dispatch(updateMarkers());
  };
}

export function deletePole(pole_id: number) {
  return async (dispatch) => {
    await authorizedDelete(`/poles/${pole_id}`);
    dispatch(updateMarkers());
  };
}

export function onMapRightClick(e) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    if (state.map.inspectorMode) {
      dispatch(
        showMenu(
          e,
          true,
          <MenuItem
            onClick={() => {
              const poles = state.map.poles;
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              const newPole = {
                client_id: null,
                id: 1337,
                loading: true,
                lat: lat,
                lng: lng,
                location: JSON.stringify({
                  type: "Point",
                  coordinates: [lng, lat],
                }),
              };
              const newPoles = [...poles, newPole];
              dispatch(setPoles(newPoles));
              dispatch(addPole(lat, lng));
              dispatch(onMapClick());
            }}
          >
            Add Pole
          </MenuItem>,
          state.map.gmap
        )
      );
    }
  };
}

interface ApiLoaded {
  map: google.maps.Map;
  maps: typeof google.maps;
}
export function onApiLoaded({ map, maps }: ApiLoaded) {
  return async (dispatch, getState) => {
    const streetView = map.getStreetView();

    const options: google.maps.StreetViewPanoramaOptions = {
      enableCloseButton: false,
      addressControl: false,
    };
    streetView.setOptions(options);
    streetView.addListener("visible_changed", () => {
      if (streetView.getVisible()) {
        const bounds = map.getBounds();
        if (bounds) {
          dispatch(saveBounds(bounds));
        }
        dispatch({
          type: "SET_STREETVIEW",
          payload: streetView,
        });
      } else {
        dispatch(loadBounds());
      }
    });

    const state: RootState = getState();
    if (state.ui.demoMode) {
      map.setMapTypeId("satellite");
    }

    // Add map listeners
    map.addListener("drag", () => {
      dispatch(onMapDrag());
    });
    map.addListener("idle", () => {
      dispatch(onBoundsChanged());
    });

    map.addListener("click", () => {
      dispatch(onMapClick());
    });

    map.addListener("mousedown", (e) => {
      const leftClick = e.domEvent && e.domEvent.which === 1;
      const rightClick = e.domEvent && e.domEvent.which === 3;

      if (leftClick) {
        dispatch(onMapClick());
      } else if (rightClick) {
        const state: RootState = getState();
        const { inspectorMode, orderMode } = state.map;
        if (inspectorMode || orderMode) {
          dispatch(startSelection(e, map));
        }
      }
    });

    map.data.addListener("mousedown", (e) => {
      const leftClick = e.domEvent && e.domEvent.which === 1;
      const rightClick = e.domEvent && e.domEvent.which === 3;

      if (leftClick) {
        dispatch(onMapClick());
      } else if (rightClick) {
        const state: RootState = getState();
        const { inspectorMode, orderMode } = state.map;
        if (inspectorMode || orderMode) {
          dispatch(startSelection(e, map));
        }
      }
    });

    // Add data listeners
    map.data.addListener("click", (e) => {
      dispatch(onDataClick(e, map));
    });

    map.data.addListener("rightclick", (e) => {
      dispatch(onMapClick());
      dispatch(onDataClick(e, map));

      const bounds = {
        west: e.latLng.lng(),
        east: e.latLng.lng(),
        south: e.latLng.lat(),
        north: e.latLng.lat(),
      };
      const selection = new google.maps.Rectangle();
      // @ts-ignore
      selection.setBounds({
        west: bounds.west - 0.0001,
        south: bounds.south - 0.0001,
        east: bounds.east + 0.0001,
        north: bounds.north + 0.0001,
      });
      dispatch(visualizeRightClick(e, selection));
    });

    const iconOptions = {
      path: maps.SymbolPath.CIRCLE,
      fillColor: MAIN_BLUE,
      fillOpacity: 1,
      strokeColor: MAIN_BLUE,
      scale: 7,
      strokeWeight: 1,
    };

    dispatch(setMenuMarker(new maps.Marker({ icon: iconOptions })));
    dispatch(setGoogleMap(map));
  };
}

export function updatePowerlineStyle() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const map: google.maps.Map = state.map.gmap;
    if (!map) {
      return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const bayFeedURLValue = searchParams.get(FilterKey.FEED_BAY);
    map.data.setStyle((e) =>
      getFeatureStyle(
        e,
        state.map.inspectorMode,
        state.map.topologyMode,
        state.map.previewMode,
        state.map.powerlineName,
        state.ui.showPowerlines,
        state.ui.showZones,
        state.user,
        false,
        bayFeedURLValue?.split(",")
      )
    );
  };
}
type CallbackFunction = () => void;

export function updatePoles(callback: null | CallbackFunction = null) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const projectID = state.mission?.id ?? -1;
    if (projectID < 0 || !projectID) {
      return null;
    }
    const map: google.maps.Map = state.map.gmap;
    if (!map) {
      return null;
    }
    const zoom = map.getZoom();
    const bounds = map.getBounds();
    if (!bounds) {
      return null;
    }
    const boundsJson = bounds.toJSON();
    if (state.ui.showPoles && zoom >= POLE_ZOOM) {
      let requestURI = "/poles";
      requestURI += `?north=${boundsJson.north}`;
      requestURI += `&east=${boundsJson.east}`;
      requestURI += `&west=${boundsJson.west}`;
      requestURI += `&south=${boundsJson.south}`;

      dispatch(setPolesLoading(true));
      const response = await axiosInstance.get<{ poles: Pole[] }>(requestURI, {
        headers: {
          MissionID: projectID.toString(),
        },
      });
      if (response.status !== 200) {
        console.error("Failed to fetch poles");
        dispatch(setPoles([]));
        dispatch(setPolesLoading(false));
        callback?.();
        return;
      }
      dispatch(setPoles(response.data.poles));
      dispatch(setPolesLoading(false));
      callback?.();
    } else {
      dispatch(setPoles([]));
      dispatch(setPolesLoading(false));
      callback?.();
    }
  };
}

export function getPreviousDefectsPoles(page = 1, updateCount = false) {
  return async (dispatch, getState) => {
    const state: RootState = getState();

    const projectID = state.mission?.id ?? -1;
    if (projectID < 0 || !projectID) {
      return null;
    }
    const previousProject = state.map.previousDefectProject;
    if (!previousProject) {
      return;
    }

    let requestURI = "/poles/previous_defects";
    requestURI += `?previousProjectId=${previousProject}`;
    requestURI += `&page=${page.toString()}`;

    dispatch(setPolesLoading(true));

    try {
      const response = await axiosInstance.get<{
        poles: Pole[];
        total_poles: number;
        total_pages: number;
      }>(requestURI, {
        headers: {
          MissionID: projectID.toString(),
        },
      });
      dispatch(setPreviousDefectPoles(response.data.poles));
      dispatch(setPreviousDefectPolesPage(page));
      dispatch(setPolesLoading(false));
      if (response.data.poles.length === 0) {
        toast.info("No previous defect poles found");
      }
    } catch (error) {
      toast.error("Failed to fetch previous defect poles");
      dispatch(setPreviousDefectPoles([]));
      dispatch(setPolesLoading(false));
    }
    if (updateCount) {
      let requestURI = "/poles/previous_defects_count";
      requestURI += `?previousProjectId=${previousProject}`;
      requestURI += `&page=${page.toString()}`;

      dispatch(setPolesLoading(true));
      try {
        const response = await axiosInstance.get<{
          total_poles: number;
          total_pages: number;
        }>(requestURI, {
          headers: {
            MissionID: projectID.toString(),
          },
        });
        dispatch(
          setPreviousDefectPolesCount({
            pages: response.data.total_pages,
            poles: response.data.total_poles,
          })
        );
        dispatch(setPolesLoading(false));
      } catch (error) {
        toast.error("Failed to fetch previous defect  count");
        dispatch(setPreviousDefectPolesCount({ pages: null, poles: null }));
        dispatch(setPolesLoading(false));
      }
    }
  };
}

export function generatePoles() {
  return async () => {
    // First we should assign all current images to poles
    //await mapImagesToPoles()();

    // Then we generate poles
    const toast_handle = toast.info("Generating poles from clusters..", {
      autoClose: false,
    });

    authorizedPost("/poles/add/from_clusters")
      .then(() => {
        toast.dismiss(toast_handle);
        toast.success("Poles have been generated from clusters");
        // Finally, we assign images to the newly generated poles
        //mapImagesToPoles()();
      })
      .catch(() => {
        toast.dismiss(toast_handle);
        toast.error("Generation fo poles failed.");
      });
  };
}

export function mapImagesToPoles() {
  return async () => {
    const toast_handle = toast.info("Assigning images to poles", {
      autoClose: false,
    });
    authorizedPost("/poles/assign_images")
      .then(() => {
        toast.dismiss(toast_handle);
        toast.success("Assigning images to poles succeeded");
      })
      .catch(() => {
        toast.dismiss(toast_handle);
        toast.error("Assigning images to poles failed");
      });
  };
}

function getBounds(
  selection: google.maps.Rectangle,
  e: google.maps.MapMouseEvent
) {
  // @ts-expect-error
  const startPoint: google.maps.LatLng = selection.startPoint;
  const currentPoint: google.maps.LatLng = e.latLng;

  // Compute the new bounds
  let newBounds = new google.maps.LatLngBounds(startPoint, startPoint);
  newBounds = newBounds.extend(currentPoint);
  return newBounds;
}

export function endSelection(
  e,
  selection: google.maps.Rectangle,
  map: google.maps.Map
) {
  return (dispatch) => {
    // Clearing map and data selectors
    google.maps.event.clearListeners(map, "mouseup");
    google.maps.event.clearListeners(map, "mousemove");
    google.maps.event.clearListeners(map.data, "mouseup");
    google.maps.event.clearListeners(map.data, "mousemove");
    google.maps.event.clearListeners(selection, "mouseup");
    google.maps.event.clearListeners(selection, "mousemove");

    // Enable map dragging again
    map.setOptions({ draggable: true });
    const { east, west, north, south } = selection.getBounds().toJSON();
    if (east - west < 0.00003 && north - south < 0.000008) {
      selection.setMap(null);
      dispatch(onMapRightClick(e));
      return;
    }

    // Find powerlines
    dispatch(visualizeRightClick(e, selection));
  };
}

export function getLidarMenuItems(
  e,
  map: google.maps.Map,
  state: RootState
): JSX.Element[] {
  const menuItems: JSX.Element[] = [];
  const feature = e.feature as google.maps.Data.Feature;
  const link = feature?.getProperty("link");

  if (link) {
    map.data.forEach((feature) => {
      if (feature.getGeometry().getType() === "Polygon") {
        const dataPoly = feature.getGeometry() as google.maps.Data.Polygon;
        const mapPoly = new google.maps.Polygon({
          paths: dataPoly.getAt(0).getArray(),
        });

        if (google.maps.geometry.poly.containsLocation(e.latLng, mapPoly)) {
          const data = {
            zoneId: feature.getProperty("id"),
            zoneLink: feature.getProperty("link"),
            language: state.user.language,
            name: feature.getProperty("name"),
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
            feature: feature,
          };

          menuItems.push(<ZoneItem data={data} />);
        }
      }
    });
  }

  return menuItems;
}

export function visualizeRightClick(
  e,
  selection: google.maps.Rectangle | null = null
) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const { inspectorMode, orderMode } = state.map;
    if (!inspectorMode && !orderMode) {
      return;
    }
    const map = state.map.gmap;

    const menuItems: JSX.Element[] = [];

    menuItems.push(<CalculateLength selection={selection} />);

    menuItems.push(...getLidarMenuItems(e, map, state));

    if (inspectorMode) {
      menuItems.push(
        <RoleWrapper keyName="annotatorInspectPowerline">
          <CriticalInspectionItem key={0} selection={selection} />
          <AnnotatorInspectorItem key={0} selection={selection} />
          <RoleWrapper keyName="supervisorInspectPowerline">
            <InspectionItem key={0} selection={selection} />
          </RoleWrapper>

          <RoleWrapper keyName="superuserInspectPowerline">
            <SuperInspectionItem key={1} selection={selection} />
          </RoleWrapper>
        </RoleWrapper>
      );
      menuItems.push(
        <RoleWrapper keyName="powerlineReflight">
          <ReflightItem key={1} selection={selection} />
        </RoleWrapper>
      );
      menuItems.push(
        <RoleWrapper keyName="powerlineHasImages">
          <HasImagesItem key={2} selection={selection} />
        </RoleWrapper>
      );
      menuItems.push(
        <RoleWrapper keyName="powerlineBuried">
          <BuriedItem key={3} selection={selection} />
        </RoleWrapper>
      );
      if (selection) {
        menuItems.push(
          <RoleWrapper keyName="imageVisibility">
            <EditImageVisibility key={4} selection={selection} />
          </RoleWrapper>
        );
      }
    }

    if (menuItems.length > 0) {
      map.addListener("click", () => {
        dispatch(onMapClick());
        if (selection) {
          selection.setMap(null);
        }
      });
      dispatch(showMenu(e, true, menuItems, map));
    }
  };
}

export function updateSelection(e, selection: google.maps.Rectangle) {
  const bounds = getBounds(selection, e);
  selection.setBounds(bounds);
}

export function setSelection(selection: google.maps.Rectangle | null) {
  return {
    type: "SET_SELECTION",
    payload: selection,
  };
}

export function clearSelection() {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const selection = state.map.selection;
    if (selection) {
      selection.setMap(null);
    }
    dispatch(setSelection(null));
  };
}

export function startSelection(
  e: google.maps.MapMouseEvent,
  map: google.maps.Map
) {
  return async (dispatch) => {
    // Collect selection from state

    // Create a new selection
    const selection = new google.maps.Rectangle({
      strokeColor: "#6666FF",
      strokeOpacity: 0.2,
      fillColor: "#6666FF",
      fillOpacity: 0.4,
      map,
    });

    // Lock the map
    map.setOptions({ draggable: false });

    // Create the starting point
    const newBounds = new google.maps.LatLngBounds(e.latLng, e.latLng);
    // @ts-ignore
    selection.startPoint = e.latLng;
    selection.setBounds(newBounds);

    // Create mouse movement listeners
    map.addListener("mousemove", (e) => updateSelection(e, selection));
    map.data.addListener("mousemove", (e) => updateSelection(e, selection));
    selection.addListener("mousemove", (e) => updateSelection(e, selection));
    dispatch(setSelection(selection));

    // Create mouse up listeners
    map.addListener("mouseup", (e) => {
      const leftClick = e.domEvent && e.domEvent.which === 1;
      if (!leftClick) {
        dispatch(endSelection(e, selection, map));
      }
    });
    map.data.addListener("mouseup", (e) => {
      const leftClick = e.domEvent && e.domEvent.which === 1;
      if (!leftClick) {
        dispatch(endSelection(e, selection, map));
      }
    });
    selection.addListener("mouseup", (e) => {
      const leftClick = e.domEvent && e.domEvent.which === 1;
      if (!leftClick) {
        dispatch(endSelection(e, selection, map));
      }
    });
  };
}

export async function convertToGeoJSON(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData = { type: "FeatureCollection", features: [] };

  const promises = [];
  if (!map) {
    return null;
  }
  // biome-ignore lint/complexity/noForEach: map.data is a google.maps.Data object
  map.data.forEach((feature: google.maps.Data.Feature) => {
    // Check if we need the feature
    if (!filter(feature)) {
      return;
    }
    // Add to the list of promises
    promises.push(
      new Promise((resolve, reject) =>
        feature.toGeoJson((value) => {
          resolve(value);
        })
      )
    );
  });
  mapData.features = await Promise.all(promises);
  return mapData;
}

export async function convertToKml(
  map: google.maps.Map,
  filter: (feature: google.maps.Data.Feature) => boolean
) {
  const mapData = await convertToGeoJSON(map, filter);
  if (mapData) {
    return tokml(mapData);
  } else {
    return null;
  }
}

export function downloadPowerlineData(type: "reflight" | "all") {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const map = state.map.gmap;
    const decisionKey = type === "reflight" ? "needsReflight" : "powerline";

    // Convert to a KML file
    const featureFilter = (f) => f.getProperty(decisionKey);
    const kml = await convertToKml(map, featureFilter);
    if (kml) {
      const blob = new Blob([kml], { type: "kml" });
      const fileName = window.prompt("What do you want the file to be named?");
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      if (fileName) {
        a.download = `${fileName}.kml`;
      } else {
        a.download = `${type}.kml`;
      }
      a.click();
    } else {
      toast.error("No map found");
    }
  };
}

export function setOrderMode(value: boolean) {
  return {
    type: "SET_ORDER_MODE",
    payload: value,
  };
}

export function setHasPowerlines(value: boolean) {
  return {
    type: "SET_HAS_POWERLINES",
    payload: value,
  };
}

export function setPowerlinesLoaded(value: boolean) {
  return {
    type: "SET_POWERLINES_LOADED",
    payload: value,
  };
}
