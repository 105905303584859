import {
  ISkyqraftHiddenSetting,
  skyqraftHiddenSetting,
} from "api/user/userSettings";
import { axiosInstance } from "utils/request";

export interface Processed {
  id: number;
  time: string;
  placer: string;
  comment: string;
}

interface IAnnotation {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  fixed: string;
  reported: string;
  objectHasNoDefect: boolean;
  rotation: number;
  item_id: string | null;
  // And the aggregated properties
  type_id: number[];
  types: number[];
  severities: number[];
  workflow_status: number[];
  confidence: number[];
  skyqraft_hidden: boolean[];
  type_comment: string[];
  processed: Processed[];
  creator: string[];
  updated_by: string[];
  origin: number;
  ring_count: number | null;
}

interface IThinAnnotation {
  x: number;
  y: number;
  w: number;
  h: number;
  rotation: number | null;
  types: number[];
}

export async function getThinAnnotations(
  projectID: number,
  imageID: number,
  showSkyqraftHidden: ISkyqraftHiddenSetting = skyqraftHiddenSetting.hideHidden,
  altProject: number | null = null
) {
  const params: Record<string, string> = {};
  if (showSkyqraftHidden === skyqraftHiddenSetting.showHidden) {
    params.skyqraftHidden = "both";
  } else if (showSkyqraftHidden === skyqraftHiddenSetting.hiddenOnly) {
    params.skyqraftHidden = "only";
  }
  if (altProject !== null) {
    params.mission = altProject.toString();
  }
  const response = await axiosInstance.get<{ annotations: IThinAnnotation[] }>(
    `/image/${imageID}/annotations_thin`,
    {
      params,
      headers: {
        MissionID: projectID.toString(),
      },
    }
  );
  const { annotations } = response.data;
  return annotations;
}

export async function getAnnotations(
  projectID: number,
  imageID: number,
  showSkyqraftHidden: ISkyqraftHiddenSetting = skyqraftHiddenSetting.hideHidden,
  showDsoTso: boolean = false,
  altProject: number | null = null
) {
  const params: Record<string, string> = {};
  if (showSkyqraftHidden === skyqraftHiddenSetting.showHidden) {
    params.skyqraftHidden = "both";
  } else if (showSkyqraftHidden === skyqraftHiddenSetting.hiddenOnly) {
    params.skyqraftHidden = "only";
  }
  if(showDsoTso) {
    params.showDsoTso = "true";
  }
  if (altProject !== null) {
    params.mission = altProject.toString();
  }
  const response = await axiosInstance.get<{ annotations: IAnnotation[] }>(
    `/image/${imageID}/annotations`,
    {
      params,
      headers: {
        MissionID: projectID.toString(),
      },
    }
  );
  const { annotations } = response.data;
  return annotations;
}
