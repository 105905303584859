import { ReactNode, useState, useEffect } from "react";
import { compose, scale, translate } from "transformation-matrix";
import { IMatrix } from "../../types";
import { IContextValue, context } from "./context";
import { useParams } from "react-router-dom";

const defaultMatrix = compose(translate(-5, -50), scale(1.52, 1.52));

interface IProviderProps {
  children: ReactNode;
}

function CanvasProvider({ children }: IProviderProps) {
  // Set up some states to keep track of
  const pathParams = useParams();
  const imageId = parseInt(pathParams.image);
  const [matrix, setMatrix] = useState<IMatrix>(defaultMatrix);
  const [mode, setModeState] = useState<
    | "addDefect"
    | "addDetection"
    | "addSteelwork"
    | "noneDefectVerification"
    | "view"
  >("view");
  const [dragged, setDragged] = useState(false);

  const [zoomSpeed, setZoomSpeed] = useState(17);

  const [imageDimensions, changeImageDimensions] = useState({
    naturalWidth: undefined,
    naturalHeight: undefined,
  });

  function setMode(
    mode:
      | "addDefect"
      | "addDetection"
      | "addSteelwork"
      | "noneDefectVerification"
      | "view"
  ) {
    setModeState(mode);
  }

  // Reset matrix to defaultMatrix on imageId change or component unmount
  useEffect(() => {
    return () => {
      setMatrix(defaultMatrix);
    };
  }, [imageId]);

  const payload: IContextValue = {
    mode,
    setMode,
    matrix,
    setMatrix,
    imageDimensions,
    changeImageDimensions,
    zoomSpeed,
    setZoomSpeed,
    dragged,
    setDragged,
  };

  return <context.Provider value={payload}>{children}</context.Provider>;
}

export default CanvasProvider;
