import React, { useEffect } from "react";
import { matchRoutes, useLocation, useParams } from "react-router-dom";
import { RootState } from "state/store";
import {
  loadSettings,
  getMissions,
  getClientIssueSeverities,
  getClientObjects,
  setListView,
  setInitialURL,
} from "state/actions";
import { HOCAuthenticator } from "authentication";
import "./App.scss";
import ReactGA from "react-ga4";
import routes from "routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

type State = { a: string }; // your state type
type AppDispatch = ThunkDispatch<State, void, AnyAction>;

const _ = require("lodash");

interface IProps {
  children: React.ReactNode;
}

function App({ children }: IProps) {
  const location = useLocation();
  const params = useParams();
  const user = useSelector((state: RootState) => state.user);
  const groups = useSelector((state: RootState) => state.group.groups);
  const projects = useSelector((state: RootState) => state.user.missions);
  const missionId = useSelector((state: RootState) => state.mission.id);
  const currentProject = projects.find((p) => p.id === missionId);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    let googleAnalyticsId = null;
    switch (window.location.origin) {
      case "https://map.skyqraft.com":
        // The GA for map
        googleAnalyticsId = "G-473JSEYQ6D";
        break;
      case "https://hawkeye.skyqraft.com":
        googleAnalyticsId = "G-JTGZEKF92Y";
        break;
      case "https://dev.skyqraft.com":
        googleAnalyticsId = "G-V92EY3BFD5";
        break;
      case "https://app.arkion.co":
        googleAnalyticsId = "G-DTJ87M5QBC";
        break;
      case "https://hawkeye.arkion.co":
        googleAnalyticsId = "G-ZR5DXCTY32";
        break;
      case "https://atom.arkion.co":
        googleAnalyticsId = "G-GNP7881XRE";
        break;
      default:
        break;
    }
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }
  }, []);

  //get clinet issues severities on first app load and when changing mission
  useEffect(() => {
    if (missionId > 0 && groups.length > 0) {
      const missionGroup = _.flatMap(groups, (item) =>
        _(item.missions)
          .filter((item2) => item2.id === missionId)
          .map((v) => item.id)
          .value()
      )[0];

      dispatch(getClientObjects(missionGroup));
      dispatch(getClientIssueSeverities(missionGroup));
    }
    dispatch(setListView(false));
  }, [missionId, groups, dispatch]);

  React.useEffect(() => {
    let client = null;
    switch (window.location.origin) {
      case "https://map.skyqraft.com":
        client = "map";
        break;
      case "https://hawkeye.skyqraft.com":
        client = "hawkeye";
        break;
      case "https://atom.skyqraft.com":
        client = "atom";
        break;
      case "https://us.skyqraft.com":
        client = "us";
        break;
      default:
        // Extract the lowest level of domain for client
        if (window.location.origin.endsWith("arkion.co")) {
          const domain = window.location.origin.split("://")[1];
          const subDomain = domain.split(".")[0];
          client = subDomain;
        }
        break;
    }
    if (user) {
      ReactGA.set({
        username: user.username,
        userId: user.username,
        email: user.email,
        id: user.id,
        superuser: user.superuser,
        skyqraft_employee: user.skyqraft_employee,
      });
    }
    !!client && ReactGA.set({ clientId: client });
  }, [user]);

  useEffect(() => {
    // Set the user permission level based on the mission
    if (currentProject) {
      const projectAccessLevels = currentProject.access_level;
      const accessLevels = {};
      for (const accessLevel of projectAccessLevels) {
        accessLevels[accessLevel] = true;
      }
      ReactGA.set({ accessLevels });
    } else {
      ReactGA.set({ accessLevels: {} });
    }
  }, [currentProject?.id]);

  React.useEffect(() => {
    // Google Analytics
    const matchedRoutes = matchRoutes(routes, location);
    const pathWithoutParams = matchedRoutes
      .reduce((acc, route) => {
        if (acc.endsWith("/")) {
          return `${acc}${route.route.path}`;
        } else {
          return `${acc}/${route.route.path}`;
        }
      }, "")
      .replaceAll("//", "/");

    const payload = {};
    for (const key of Object.keys(params)) {
      payload[key] = params[key];
    }

    if (ReactGA.isInitialized) {
      // Log a page view properly
      ReactGA.send({
        hitType: "pageview",
        page: pathWithoutParams,
        title: document.title,
        ...payload,
      });
    }
  }, [location, params]);

  // Add legacy redirect
  useEffect(() => {
    dispatch(setInitialURL(window.location.pathname));
    const uriQuery = new URLSearchParams(window.location.search);
    const legacyImageParameters = uriQuery.get("ids");
    if (legacyImageParameters) {
      uriQuery.delete("ids");
      uriQuery.delete("images");
      let newQuery = uriQuery.toString();
      if (newQuery.length > 0) {
        newQuery = `?${newQuery}`;
      }
      window.location.replace(
        `${window.location.pathname}/${legacyImageParameters}${newQuery}`
      );
    }

    const legacyImageParameter = uriQuery.get("image");
    if (legacyImageParameter) {
      uriQuery.delete("image");
      let newQuery = uriQuery.toString();
      if (newQuery.length > 0) {
        newQuery = `?${newQuery}`;
      }
      window.location.replace(
        `${window.location.pathname}/${legacyImageParameter}${newQuery}`
      );
    }
  }, []);

  useEffect(() => {
    dispatch(loadSettings());
    dispatch(getMissions());
  }, [dispatch]);

  return children;
}

export default HOCAuthenticator(App);
