import { axiosInstance } from "utils/request";
import { toast } from "react-toastify";
import { captureException, EventHint } from "@sentry/react";
import translations from "translations";

interface nonUserEmail {
  email: string;
  id: number;
}

export async function addNonUserEmail(
  group: number,
  email: string,
  language: string
) {
  const requestURI = `/group/${group}/non_user_email`;

  try {
    await axiosInstance.post(requestURI, { email });
    toast.success(
      translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
        .EmailAddedSuccessfully[language]
    );
  } catch (error) {
    const exceptionHint: EventHint = {
      event_id: "actions.nonUserEmail.add.request",
      originalException: error,
    };
    captureException(error, exceptionHint);
    toast.error(
      translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
        .FailedToAddEmail[language]
    );
  }
}

export async function getNonUserEmails(
  group: number,
  setOptions,
  language: string
) {
  const requestURI = `/group/${group}/non_user_email`;

  try {
    const response = await axiosInstance.get<{
      emails: nonUserEmail[];
    }>(requestURI);
    const nonUserOptions = [
      {
        label: "Non-Users",
        options: response.data.emails
          .sort((a, b) => (a.email < b.email ? -1 : 1))
          .map((nonUser) => ({
            value: -nonUser.id, // Use negative id to differentiate from user id
            label: nonUser.email,
          })),
      },
    ];
    setOptions((prevOptions) => [
      ...nonUserOptions,
      ...prevOptions.filter((option) => option.label !== "Non-Users"),
    ]); // Append nonUserEmails to options
  } catch (error) {
    toast.error(
      translations.MissionLanding.OrderProgress.PlanningPhase.EmailList
        .FailedToFetchEmails[language]
    );
  }
}
