import { useContext, useState } from "react";
import { IconButton, Tooltip, CircularProgress } from "@mui/material";
import { Save, SaveAlt, Done, ErrorOutline } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { ObjectType } from "interfaces";
import { useLanguage } from "hooks";
import { AnnotationContext } from "../provider";
import {
  canSaveObject,
  regionsAreEqual,
  checkBeforeSave,
} from "./AnnotationsList/Utils";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import translations from "translations";
interface IProps {
  exitOnSave?: boolean;
}

export default function SaveButton({ exitOnSave }: IProps) {
  const {
    saveAnnotations,
    annotations,
    originalAnnotations,
    saveFeedback,
    exit,
  } = useContext(AnnotationContext);

  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { language } = useLanguage();

  const objectTypes: ObjectType[] = useSelector(
    (state: RootState) => state.objects.objectTypes
  );

  //This is to check if the annotations can be saved
  const canSaveAnnotations = annotations?.map((region) =>
    canSaveObject(region, objectTypes)
  );
  const saveError = canSaveAnnotations?.find(
    (value) => value.canSave === false
  );
  const canSave = canSaveAnnotations?.every((region) => region.canSave);

  const somethingChanged = regionsAreEqual(annotations, originalAnnotations);

  function renderSaveIcon() {
    if (saveFeedback === "") {
      return exitOnSave ? <SaveAlt /> : <Save />;
    } else if (saveFeedback === "loading") {
      return (
        <CircularProgress
          size={20}
          sx={{ marginTop: "2px", marginLeft: "2px" }}
        />
      );
    } else if (saveFeedback === "saved") {
      return <Done htmlColor="green" />;
    } else if (saveFeedback === "error") {
      return <ErrorOutline htmlColor="red" />;
    }
  }

  async function saveAndExitIfRequired() {
    const result = await saveAnnotations(annotations, originalAnnotations);

    if (exitOnSave && result !== "error") {
      exit();
    }
  }
  return (
    <Tooltip
      title={saveError?.message || (exitOnSave ? "Save and exit" : "Save")}
      arrow
      placement="top"
      disableInteractive
    >
      <span>
        <IconButton
          onClick={async () => {
            //This is to check if any alerts should be shown before saving
            const alertMessage = annotations
              ?.map((region) => checkBeforeSave(region, objectTypes, language))
              .find((a) => !!a);

            if (alertMessage) {
              setAlertMessage(alertMessage);
              return;
            }
            saveAndExitIfRequired();
          }}
          disabled={
            !canSave ||
            somethingChanged ||
            saveFeedback === "loading" ||
            saveFeedback === "saved"
          }
          sx={{ width: "40px" }}
        >
          {renderSaveIcon()}
        </IconButton>

        <Dialog open={!!alertMessage} onClose={() => setAlertMessage(null)}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alertMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertMessage(null)}>
              {translations.AnnotationTool.Cancel[language]}
            </Button>
            <Button
              onClick={async () => {
                setAlertMessage(null);
                saveAndExitIfRequired();
              }}
              autoFocus
            >
              {translations.AnnotationTool.Save[language]}
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </Tooltip>
  );
}
