import {
  ClientIssueSeverity,
  DpType,
  IssueCategory,
  IssueSeverity,
  ObjectCategory,
  ObjectType,
  WorkflowStatus,
} from "interfaces";

export interface IImageObject {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  types: number[];
  severities: number[];
  workflow_status: number[];
  skyqraft_hidden: boolean[];
  objectHasNoDefect: boolean;
  type_comment: string[];
  type_id: number[];
  confidence: number[];
  rotation: number;
  item_id: string | null;
}

export interface IObjectMeta {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  objectCategories: ObjectCategory[];
  detectedCategories: IssueCategory[];
  workflowStatus: WorkflowStatus[];
  dpTypes: DpType[];
}

export interface IClientObject {
  skyqraft_object_type_id: number;
  client_name: string;
  client_object_type_id: number;
  group_id: number;
  id: number;
  category_id: number;
  category_external_id: number;
  category_en_name: string;
  category_se_name: string;
  color: string;
  external_type_id: number;
}

export interface IReviewObjectList {
  imageIds: number[];
  limited: boolean;
}

export interface IState {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  detectedCategories: IssueCategory[];
  objectCategories: ObjectCategory[];
  issueSeverities: IssueSeverity[];
  clientIssueSeverities: ClientIssueSeverity[];
  clientObjects: IClientObject[];
  workflowStatus: WorkflowStatus[];
  loading: boolean;
  showHumanMachine: boolean;
  flights: number[];
  dpTypes: DpType[];
  mlReviewObject: IReviewObjectList;
  supervisorReview: IReviewObjectList;
  falsePositiveReview: {
    image_id: null | number;
    objects: IImageObject[];
    current_image_position: null | number;
    total_images_to_review: null | number;
  };
  falseReview: IReviewObjectList;
  mlModels: { id: number; name: string; types: number[] }[];
  selectedModel: string;
  reviewLoading: boolean;
}

export const initialState = {
  objectTypes: [],
  issueCategories: [],
  detectedCategories: [],
  objectCategories: [],
  issueSeverities: [],
  clientIssueSeverities: [],
  clientObjects: [],
  workflowStatus: [],
  loading: false,
  showHumanMachine: false,
  flights: [],
  dpTypes: [],
  mlReviewObject: {
    imageIds: [],
    limited: false
  },
  supervisorReview: {
    imageIds: [],
    limited: false
  },
  falsePositiveReview: {
    image_id: null,
    objects: [],
    current_image_position: null,
    total_images_to_review: null,
  },
  falseReview: {
    imageIds: [],
    limited: false
  },
  mlModels: [],
  selectedModel: null,
  reviewLoading: false,
};

interface IAction {
  type: string;
  // biome-ignore lint/suspicious/noExplicitAny: Allow any type for payload
  payload: any;
}
const objectReducer = (
  state: IState = initialState,
  action: IAction = { type: null, payload: null }
) => {
  switch (action.type) {
    case "SET_OBJECT_META": {
      const {
        objectTypes,
        issueCategories,
        issueSeverities,
        objectCategories,
        detectedCategories,
        workflowStatus,
        dpTypes,
      } = action.payload;
      return {
        ...state,
        objectTypes,
        issueCategories,
        issueSeverities,
        objectCategories,
        detectedCategories,
        workflowStatus,
        dpTypes,
      };
    }

    case "SET_REVIEW_LOADING": {
      return {
        ...state,
        reviewLoading: action.payload,
      };
    }

    case "SET_ML_MODELS": {
      return {
        ...state,
        mlModels: action.payload,
      };
    }

    case "SET_SELECTED_ML_MODEL": {
      return {
        ...state,
        selectedModel: action.payload,
      };
    }

    case "SET_ML_REVIEW_OBJECTS": {
      return {
        ...state,
        mlReviewObject: action.payload,
      };
    }
    case "SET_SUPERVISOR_REVIEW_OBJECTS": {
      return {
        ...state,
        supervisorReview: action.payload,
      };
    }

    case "SET_FALSE_POSITIVE_REVIEW_OBJECTS": {
      return {
        ...state,
        falsePositiveReview: action.payload,
      };
    }
    case "SET_FALSE_REVIEW_OBJECTS": {
      return {
        ...state,
        falseReview: action.payload,
      };
    }

    case "SET_ALL_TYPES": {
      if (action.payload) {
        const {
          objectTypes,
          detectedCategories,
          issueCategories,
          objectCategories,
          issueSeverities,
          workflowStatus,
          dpTypes,
        } = action.payload;
        return {
          ...state,
          objectTypes,
          detectedCategories,
          issueCategories,
          objectCategories,
          issueSeverities,
          workflowStatus,
          dpTypes,
        };
      } else {
        return state;
      }
    }

    case "SET_OBJECT_TYPES": {
      return {
        ...state,
        objectTypes: action.payload,
      };
    }

    case "SET_ISSUE_CATEGORIES": {
      return {
        ...state,
        issueCategories: action.payload,
      };
    }
    case "SET_ISSUE_SEVERITIES": {
      return {
        ...state,
        issueSeverities: action.payload,
      };
    }

    case "SET_CLIENT_ISSUE_SEVERITIES": {
      return {
        ...state,
        clientIssueSeverities: action.payload,
      };
    }

    case "SET_CLIENT_OBJECTS": {
      return {
        ...state,
        clientObjects: action.payload,
      };
    }

    case "SET_WORKFLOW_STATUS": {
      return {
        ...state,
        workflowStatus: action.payload,
      };
    }

    case "SET_DPPOWER_TYPES": {
      return {
        ...state,
        dpTypes: action.payload,
      };
    }

    case "SET_FLIGHTS": {
      return {
        ...state,
        flights: action.payload,
      };
    }

    case "SET_ANNOTATION_TOOL_HUMAN_MACHINE": {
      return {
        ...state,
        showHumanMachine: action.payload,
      };
    }
    default:
      return state;
  }
};

export default objectReducer;
