import { EventHint, captureException } from "@sentry/browser";
import { getAnnotations } from "api/image/annotation";
import { skyqraftHiddenSetting } from "api/user/userSettings";
import hasAccess from "utils/authTools";
import { authorizedPost } from "utils/request";
import { regionsAreEqual } from "./components/AnnotationsList/Utils";

export type IAnnotation = Awaited<ReturnType<typeof getAnnotations>>[number];
export type IImageAnnotation = IAnnotation;

export interface IObject {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IProcessed {
  id: number;
  time: string;
  placer: string;
  comment: string;
}

export interface ISpecialAnnotation extends IAnnotation {
  origin: number;
  fixed: string;
  reported: string;
  ring_count: number | null;
  active: boolean;
  categories: number[];
  processed: IProcessed[];
  is_defect: boolean[];
  types: number[];
  severities: number[];
  workflow_status: number[];
  skyqraft_hidden: boolean[];
  objectHasNoDefect: boolean;
  type_comment: string[];
  type_id: number[];
  confidence: number[];
  client_types: number[];
  highlighted: boolean;
  visible: boolean;
  editIndex: number | null;
  editType: string | null;
  hover: boolean;
  rotation: number;
  item_id: string | null;
  isLocked: boolean;
}

export interface IAnnotationWithNames extends IAnnotation {
  types_names: string[];
}

export async function getAnnotationsFromAPI(
  projectID: number,
  imageID: number,
  objectTypes
) {
  try {
    const annotations = await getAnnotations(
      projectID,
      imageID,
      skyqraftHiddenSetting.showHidden,
      true
    );
    const specialAnnotations: ISpecialAnnotation[] = annotations.map(
      (annotation) => {
        const types = annotation.types.map((ot) =>
          objectTypes.find((o) => ot === o.id)
        );
        const specialAnnotation = {
          ...annotation,
          isLocked: !!annotation.reported,
          visible: true,
          categories: types.map((t) => t?.category),
          is_defect: types.map((t) => t?.issue),
          highlighted: false,
          hover: false,
          active: false,
          client_types: [],
          editIndex: null,
          editType: null,
        };
        return specialAnnotation;
      }
    );
    return specialAnnotations;
  } catch (error) {
    const exceptionHint: EventHint = {
      event_id: "actions.image.getAnnotations.request",
      originalException: error,
      data: {
        projectID,
        imageID,
        showSkyqraftHidden: skyqraftHiddenSetting.showHidden,
      },
    };
    captureException(error, exceptionHint);
  }
}

export async function deleteBoxFromPole(
  imageID: number,
  annotation: IAnnotation
) {
  const annotationTest = {
    action: "delete",
    id: annotation.id,
  };

  await authorizedPost(`/image/${imageID}/annotations`, {
    annotations: [annotationTest],
  });
}

export async function saveAnnotations(
  imageID: number,
  regions: IAnnotation[],
  annotations: IAnnotation[]
) {
  // Unpack the old regions so we don't change any old data accidentally.
  const newRegions = [...regions];
  const oldRegions = [...annotations];
  // Extract region IDs
  const newRegionsIDs = new Set(newRegions.map((r) => r.id));
  const oldRegionsIDs = new Set(oldRegions.map((r) => r.id));

  // Find regions that were deleted
  const deletedRegions = oldRegions.filter((r) => !newRegionsIDs.has(r.id));
  // Find regions that were created
  const createdRegions = newRegions.filter((r) => !oldRegionsIDs.has(r.id));
  // Find regions that were updated
  const deletedIds = deletedRegions.map((r) => r.id);
  const createdIds = createdRegions.map((r) => r.id);

  // Create the API payload
  const annotationChanges = [];

  // Add deleted regions to the payload
  for (const id of deletedIds) {
    annotationChanges.push({
      action: "delete",
      id: id,
    });
  }

  for (const r of newRegions) {
    let objectAction = "update";
    if (createdIds.includes(r.id)) {
      objectAction = "add";
    }
    const annotationChangesEntry = {
      action: objectAction,
      id: r.id,
      x: r.x,
      y: r.y,
      width: r.w,
      height: r.h,
      rotation: r.rotation,
      defect_fixed: !!r.fixed,
      date_fixed: r.fixed,
      date_reported: r.reported,
      object_has_no_defects: !!r.objectHasNoDefect,
      types: [],
      item_id: r.item_id,
    };

    const oldObjectIndex = oldRegions.findIndex((a) => a.id === r.id);

    for (let i = 0; i < r.types.length; i++) {
      const comment = r.type_comment[i];
      const type_id = r.types[i];
      const severity = r.severities[i];
      const skyqraftHidden = r.skyqraft_hidden[i];
      const workflowStatus = r.workflow_status[i];
      const imageObjectTypeID = r.type_id[i];

      // Find old data
      const oldObjectData = oldRegions.find((a) => a.id === r.id);

      let typeAction = "";
      if (oldObjectIndex === -1) {
        typeAction = "add";
      } else {
        if (!regionsAreEqual([oldObjectData], [r])) {
          typeAction = "update";
        }
      }
      if (typeAction !== "") {
        annotationChangesEntry.types.push({
          action: typeAction,
          id: imageObjectTypeID,
          type: type_id,
          workflow_status: workflowStatus,
          severity: severity,
          skyqraft_hidden: skyqraftHidden,
          comment: comment,
        });
      }
    }
    annotationChanges.push(annotationChangesEntry);

    //Check for deleted types
    if (oldObjectIndex !== -1) {
      if (r.types.length < oldRegions[oldObjectIndex].types.length) {
        const typesToDeleteIds = oldRegions[oldObjectIndex].type_id.filter(
          (element) => {
            return !r.type_id.includes(element);
          }
        );
        for (const u of typesToDeleteIds) {
          annotationChangesEntry.types.push({
            action: "delete",
            id: u,
          });
        }
      }
    }
  }

  let response = "noChanges";

  // Send the payload to the API
  if (annotationChanges.length > 0) {
    await authorizedPost(`/image/${imageID}/annotations`, {
      annotations: annotationChanges,
    })
      .then(() => {
        const UserHasAccess = hasAccess("flagImageForReannotation");
        if (UserHasAccess) {
          authorizedPost(`/image/${imageID}/unflag`, {});
        }
        response = "saved";
      })
      .catch(() => {
        response = "error";
      });
  }

  return response;
}
