import { captureException } from "@sentry/react";
import {
  DpType,
  IssueCategory,
  IssueSeverity,
  ObjectType,
  WorkflowStatus,
} from "interfaces";
import { toast } from "react-toastify";
import { updateAnnotations, updateMarkers } from "state/actions";
import {
  IClientObject,
  IImageObject,
  IObjectMeta,
  IReviewObjectList,
} from "state/reducers/objectreducer";
import { RootState } from "state/store";
import {
  authorizedGet,
  authorizedPost,
  authorizedPut,
  axiosInstance,
} from "utils/request";

export const setObjectMeta = (value: IObjectMeta) => {
  return {
    type: "SET_OBJECT_META",
    payload: value,
  };
};

export const setReviewLoading = (value: boolean) => {
  return {
    type: "SET_REVIEW_LOADING",
    payload: value,
  };
};

export function updateAnnotationsData(newAnnotation, image) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mission = state.mission.id;
    const skyqraftHiddenSetting = state.user.skyqraft_hidden;
    const showDsoTso = state.user.show_dso_tso;
    const response = await authorizedPut(`/object/${newAnnotation.id}`, {
      ...newAnnotation,
      customerId: mission,
      imageId: image,
    });
    if (response) {
      dispatch(
        updateAnnotations(mission, image, skyqraftHiddenSetting, showDsoTso)
      );
    }
  };
}

export const setAnnotationToolHumanMachine = (value: boolean) => {
  return {
    type: "SET_ANNOTATION_TOOL_HUMAN_MACHINE",
    payload: value,
  };
};

interface AllTypesResponse {
  objectTypes: ObjectType[];
  issueCategories: IssueCategory[];
  issueSeverities: IssueSeverity[];
  workflowStatus: WorkflowStatus[];
  dpTypes: DpType[];
}

export function getAllTypes() {
  return async (dispatch) => {
    const response = await authorizedGet<AllTypesResponse>("/object/types");
    if (!!response && !!response.objectTypes) {
      dispatch({
        type: "SET_ALL_TYPES",
        payload: response,
      });
    }
  };
}

export function getObjectTypes() {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const isLoggedIn = state.user.loggedIn;
    if (!isLoggedIn) return;
    const response = await authorizedGet<{ types: ObjectType[] }>(
      "/object/types"
    );
    if (response) {
      dispatch({
        type: "SET_OBJECT_TYPES",
        payload: response.types,
      });
    }
  };
}

export function getIssueCategories() {
  return async (dispatch) => {
    const response = await authorizedGet<{ categories: IssueCategory[] }>(
      "/object/issue_category"
    );
    dispatch({
      type: "SET_ISSUE_CATEGORIES",
      payload: response.categories,
    });
  };
}

export function getDetectedCategories() {
  return async (dispatch) => {
    const response = await authorizedGet<{ categories: IssueCategory[] }>(
      "/object/detected_category"
    );
    dispatch({
      type: "SET_DETECTED_CATEGORIES",
      payload: response.categories,
    });
  };
}

export function getIssueSeverities() {
  return async (dispatch) => {
    const response = await authorizedGet<{ severities: IssueSeverity[] }>(
      "/object/types/issue_severity"
    );
    dispatch({
      type: "SET_ISSUE_SEVERITIES",
      payload: response.severities,
    });
  };
}

export function getClientIssueSeverities(missionGroup) {
  return async (dispatch) => {
    const response = await authorizedGet<{ severities: IssueSeverity[] }>(
      `/object/types/client_issue_severity?group=${missionGroup}`
    );
    if (!!response && !!response.severities) {
      dispatch({
        type: "SET_CLIENT_ISSUE_SEVERITIES",
        payload: response.severities,
      });
    }
  };
}

export function getClientObjects(missionGroup) {
  return async (dispatch) => {
    const response = await authorizedGet<{ objects: IClientObject[] }>(
      `/object/types/client_objects?group=${missionGroup}`
    );
    if (!!response && !!response.objects) {
      dispatch({
        type: "SET_CLIENT_OBJECTS",
        payload: response.objects,
      });
    }
  };
}

export function getWorkflowStatus() {
  return async (dispatch) => {
    const response = await authorizedGet<{ workflows: WorkflowStatus[] }>(
      "/object/workflow_status"
    );
    dispatch({
      type: "SET_WORKFLOW_STATUS",
      payload: response.workflows,
    });
  };
}

export function getDpPowerTypes() {
  return async (dispatch) => {
    const response = await authorizedGet<{ types: DpType[] }>(
      "/object/dppower"
    );
    dispatch({
      type: "SET_DPPOWER_TYPES",
      payload: response.types,
    });
  };
}

export function getMLReviewObjects(
  order_by: string | null = null
) {
  return async (dispatch, getState) => {
    const baseQuery = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};
    const state: RootState = getState();
    if (order_by) {
      params.order_by = order_by;
    }
    if (baseQuery.has("detection")) {
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("flagged")) {
      params.flagged = baseQuery.get("flagged");
    }
    if (baseQuery.has("list")) {
      params.list = baseQuery.get("list");
    }
    if (baseQuery.has("actors")) {
      params.actors = baseQuery.get("actors");
    }
    if (baseQuery.has("severity")) {
      params.severity = baseQuery.get("severity");
    }
    if (baseQuery.has("feed_bay")) {
      params.feed_bay = baseQuery.get("feed_bay")
    }
    if (baseQuery.has("area")) {
      params.area = baseQuery.get("area")
    }

    const projectID = state.mission?.id ?? -1;

    try {
      const { data } = await axiosInstance.get<IReviewObjectList>(
        "/review/machine_learning",
        {
          headers: { MissionID: projectID.toString() },
          params,
        }
      );
      dispatch({
        type: "SET_ML_REVIEW_OBJECTS",
        payload: data,
      });
    } catch (error) {
      if (error.response?.status === 404) {
        toast.error("No (more) matches found");
      } else {
        toast.error("Failed to get ML review objects.");
        captureException(error, {
          event_id: "actions.objects.getMLReviewObjects.request",
          originalException: error,
        });
      }

      dispatch({
        type: "SET_ML_REVIEW_OBJECTS",
        payload: {
          imageIds: [],
          limited: false,
        },
      });
    }
  };
}

export type MachineReviewActions =
  | "request"
  | "approve"
  | "approve-hide"
  | "super-deny"
  | "deny"
  | "supervisor-approve";

export function setMLReview(
  image: number,
  regionID: number,
  regionType: number,
  action: MachineReviewActions,
  severity_id = null
) {
  return async (dispatch, getState) => {
    dispatch(setReviewLoading(true));
    const APPROVED_ACTIONS = [
      "request",
      "approve",
      "approve-hide",
      "super-deny",
      "deny",
      "supervisor-approve",
    ];

    // Assert the action is approved
    if (!APPROVED_ACTIONS.includes(action)) {
      console.error("Status review must be one of following", APPROVED_ACTIONS);
      return;
    }
    // Send the status update
    const response = await authorizedPut(
      `/object/${regionID}/review/${action}`,
      {
        type: regionType,
        severity: severity_id,
      }
    );
    if (response) {
      dispatch(updateMarkers());
      const state: RootState = getState();
      const projectID = state.mission?.id ?? -1;
      const { skyqraft_hidden, show_dso_tso } = state.user;
      dispatch(
        updateAnnotations(projectID, image, skyqraft_hidden, show_dso_tso)
      );
      dispatch(setReviewLoading(false));
    }
  };
}

export function approveSupervisorObjects(
  image: number,
  project: number,
  callback: () => void = null
) {
  return async (dispatch, getState) => {
    dispatch(setReviewLoading(true));
    const state: RootState = getState();
    const type_ids = state.image.filteredAnnotations.flatMap((annotation) => annotation.type_id);
    const response = await axiosInstance.post("/review/supervisor", {
      type_ids: type_ids,
      image: image,
      supervisor_approved: true,
    },
    {
      headers: {
        MissionID: project,
      },
    });

    if (response) {
      callback?.();
    }
    dispatch(setReviewLoading(false));
  };
}

export function getSupervisorReviewObjects() {
  return async (dispatch, getState) => {
    const baseQuery = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};
    const state: RootState = getState();
    if (baseQuery.has("detection")) {
      params.detection = baseQuery.get("detection");
    }
    if (baseQuery.has("flagged")) {
      params.flagged = baseQuery.get("flagged");
    }
    if (baseQuery.has("list")) {
      params.list = baseQuery.get("list");
    }
    if (baseQuery.has("actors")) {
      params.actors = baseQuery.get("actors");
    }
    if (baseQuery.has("severity")) {
      params.severity = baseQuery.get("severity");
    }
    if (baseQuery.has("workflow")) {
      params.workflow_status = baseQuery.get("workflow");
    }

    const projectID = state.mission?.id ?? -1;

    try {
      const { data } = await axiosInstance.get<IReviewObjectList>(
        "/review/supervisor",
        {
          headers: { MissionID: projectID.toString() },
          params,
        }
      );
      dispatch({
        type: "SET_SUPERVISOR_REVIEW_OBJECTS",
        payload: data,
      });
    } catch (error) {
      if (error.response?.status === 404) {
        toast.error("No (more) matches found");
      } else {
        toast.error("Failed to get Supervisor review objects.");
        captureException(error, {
          event_id: "actions.objects.getSupervisorReviewObjects.request",
          originalException: error,
        });
      }

      dispatch({
        type: "SET_SUPERVISOR_REVIEW_OBJECTS",
        payload: {
          imageIds: [],
          limited: false,
        },
      });
    }
  };

}

//FP-review

export function goToNextFalsePositiveImage(
  image: number,
  mlModel: string,
  mlModelDetections: string,
  callback: (image: number) => void
) {
  return async (dispatch) => {
    dispatch(setReviewLoading(true));

    const baseQuery = new URLSearchParams(window.location.search);
    baseQuery.append("image", JSON.stringify(image));
    baseQuery.append("direction", "asc");
    baseQuery.append("mlModel", mlModel);
    baseQuery.append("mlmodeldetections", mlModelDetections);

    const query = baseQuery.toString();
    const response = await authorizedGet<{
      image_id: number;
      objects: IImageObject[];
    }>(`/review/falsepositive?${query}`);

    if (response) {
      callback(response.image_id);
      dispatch({
        type: "SET_FALSE_POSITIVE_REVIEW_OBJECTS",
        payload: response,
      });
    }
    dispatch(setReviewLoading(false));
  };
}
export function goToPreviousFalsePositiveImage(
  image: number,
  mlModel: string,
  mlModelDetections: string,
  callback: (image: number) => void
) {
  return async (dispatch) => {
    dispatch(setReviewLoading(true));

    const baseQuery = new URLSearchParams(window.location.search);
    baseQuery.append("image", JSON.stringify(image));
    baseQuery.append("direction", "desc");
    baseQuery.append("mlModel", mlModel);
    baseQuery.append("mlmodeldetections", mlModelDetections);

    const query = baseQuery.toString();
    const response = await authorizedGet<{
      image_id: number;
      objects: IImageObject[];
    }>(`/review/falsepositive?${query}`);

    if (response) {
      callback(response.image_id);
      dispatch({
        type: "SET_FALSE_POSITIVE_REVIEW_OBJECTS",
        payload: response,
      });
    }
    dispatch(setReviewLoading(false));
  };
}

export function getFalsePositiveReviewObjects(
  image: number,
  mlModel: string,
  mlModelDetections: string,
  callback: (imageID: number) => void = null,
  direction = "same"
) {
  return async (dispatch) => {
    dispatch(setReviewLoading(true));
    let review_objects = null;

    if (image !== null) {
      const baseQuery = new URLSearchParams(window.location.search);
      baseQuery.append("image", JSON.stringify(image));
      baseQuery.append("direction", direction);
      baseQuery.append("mlModel", mlModel);
      baseQuery.append("mlmodeldetections", mlModelDetections);

      const query = baseQuery.toString();
      review_objects = await authorizedGet(`/review/falsepositive?${query}`);
    }

    if (!review_objects) {
      const baseQuery = new URLSearchParams(window.location.search);
      baseQuery.append("mlModel", mlModel);
      baseQuery.append("mlmodeldetections", mlModelDetections);
      const query = baseQuery.toString();
      review_objects = await authorizedGet(`/review/falsepositive?${query}`);
    }

    if (review_objects) {
      dispatch({
        type: "SET_FALSE_POSITIVE_REVIEW_OBJECTS",
        payload: review_objects,
      });
    } else {
      toast.error("No supervisor review objects found");
    }

    if (!!callback && !!review_objects && !!review_objects.image_id) {
      callback(review_objects.image_id);
    }
    dispatch(setReviewLoading(false));
  };
}

export function getMlModels() {
  return async (dispatch) => {
    dispatch(setReviewLoading(true));
    const response = await authorizedGet<{ models: IImageObject[] }>(
      "/review/falsepositive/models"
    );
    dispatch({
      type: "SET_ML_MODELS",
      payload: response.models,
    });
    dispatch(setReviewLoading(false));
  };
}

export function approveFalsePositiveObjects(
  image: number,
  mlModel: number,
  train: boolean,
  emptyText: boolean,
  skip: boolean,
  callback: () => void = null
) {
  return async () => {
    const response = await authorizedPost("/review/falsepositive", {
      image: image,
      mlModel: mlModel,
      train: train,
      empty_txt: emptyText,
      skip: skip,
      supervisor_approved: true,
    });

    if (response) {
      callback?.();
    }
  };
}

export function setSelectedModel(mlModel: string) {
  return {
    type: "SET_SELECTED_ML_MODEL",
    payload: mlModel,
  };
}
