import { Add } from "@mui/icons-material";
import { Paper, Stack, Tooltip, Typography } from "@mui/material";
import { EventHint, captureException } from "@sentry/react";
import { Accordion } from "components";
import { IProject } from "hooks";
import { useLidarLinkableProjects } from "hooks/projectAdmin/useLidarLinkableProjects";
import { useLinkedLidarProjects } from "hooks/projectAdmin/useLinkedLidarProjects";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { authorizedPut } from "utils/request";

export default function LidarSymLinks() {
  const params = useParams();

  const { projects } = useOutletContext<{
    projects: IProject[];
  }>();

  const currentProjectID = parseInt(params.project);
  const currentProject = projects.find((p) => p.id === currentProjectID);

  const [linkableProjects, updateLinkableProjects, loadingLinkableProjects] =
    useLidarLinkableProjects(currentProjectID);
  const [selectedLinkableLidar, setSelectedLinkableLidar] = useState<
    number | null
  >();

  const [linkedProjects, getLinkedProjects] =
    useLinkedLidarProjects(currentProjectID);

  async function linkLidarProject() {
    const targetProject = linkableProjects.find(
      (project) => project.id === selectedLinkableLidar
    );
    if (!targetProject) {
      return;
    }

    if (
      window.confirm(
        `Are you sure you want to link all lidar files from project "${targetProject.name}" to "${currentProject.name}"`
      )
    ) {
      try {
        const response = await authorizedPut<{ links_added: number }>(
          "/lidar/link_project",
          {
            linkable: selectedLinkableLidar,
          },
          { MissionId: currentProject.id }
        );

        toast.success(`Linked ${response.links_added} scenes to this project.`);
        updateLinkableProjects();
        getLinkedProjects();
      } catch (error) {
        // Capture the exception
        const exceptionHint: EventHint = {
          event_id:
            "projectadministration.projectpage.lidarsynlinks.linkLidarProject",
          originalException: error,
          data: {
            projectID: currentProject.id,
          },
        };

        captureException(error, exceptionHint);
        toast.error("Failed to link project.");
      }
    }
  }

  const linkedProjectsComponent = linkedProjects.map((linkedProject) => {
    const project = projects.find(
      (project) => linkedProject.project_id === project.id
    );

    const scenes = linkedProject.scenes.map((scene) => <li>{scene}</li>);
    return (
      <Accordion
        title={`${project.name} - ${project.year} (ID: ${project.id})`}
        description=""
        open={false}
      >
        <ul>{scenes}</ul>
      </Accordion>
    );
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Typography sx={{ mb: 2 }}>Lidar Symbolic Links</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ReactSelect
            styles={{
              // @ts-ignore
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                marginLeft: 8,
              }),
              // @ts-ignore
              control: (provided) => ({
                ...provided,
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
                borderColor: "rgba(0, 0, 0, 0.4)",
                marginLeft: 8,
              }),
              // @ts-ignore
              valueContainer: (provided) => ({
                ...provided,
                padding: 0,
              }),
              // @ts-ignore
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
              }),
            }}
            value={linkableProjects
              .map((a) => ({ value: a.id, label: a.name }))
              .find((a) => a.value === selectedLinkableLidar)}
            onChange={(e) => {
              // @ts-ignore
              setSelectedLinkableLidar(e.value);
            }}
            isLoading={loadingLinkableProjects}
            // @ts-ignore
            options={linkableProjects.map((a) => ({
              value: a.id,
              label: a.name,
            }))}
          />
          <Tooltip
            title="Link project."
            style={{ cursor: "pointer", padding: 2 }}
          >
            <Add onClick={() => linkLidarProject()} />
          </Tooltip>
        </div>

        <Typography sx={{ mb: 2 }}>Linked Projects</Typography>
        {linkedProjectsComponent}
      </Stack>
    </Paper>
  );
}
