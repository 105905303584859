import { CloseOutlined, Done , Edit, MotionPhotosOffOutlined, NavigateBefore, NavigateNext, Replay, ThumbDownAlt, VisibilityOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  getFilterActive,
  useCurrentProject,
  useDispatch,
  useSelector,
  useTranslation,
} from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  MachineReviewActions,
  getMLReviewObjects,
  setExpandAnnotationsList,
  setMLReview,
  updateAnnotations,
} from "state/actions";
import { getWrappedEntryInReviewList } from "../utils";
import OrderByDropDown from "./OrderByDropDown";

export default function MachineReview() {
  const dispatch = useDispatch();
  const machineObjects = useSelector((state) => state.objects.mlReviewObject);
  const filteredAnnotations = useSelector(
    (state) => state.image.filteredAnnotations
  );
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const jumpToImageText = useTranslation("JumpToImage");
  const cancelText = useTranslation("Cancel");
  const confirmText = useTranslation("Confirm");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const currentImage = parseInt(params.image);
  const [searchParams, setSearchParams] = useSearchParams();
  const isMountedRef = useRef(true);
  const [JumpToDialogOpen, setJumpToDialogOpen] = useState(false);
  const handleCloseJumpToDialog = () => {
    setJumpToDialogOpen(false);
  };

  const currentProject = useCurrentProject();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const showDsoTso = useSelector((state) => state.user.show_dso_tso);

  const [sortingOrder, setSortingOrder] = useState("confidence_desc");
  const filterActive = getFilterActive(searchParams);
  const [lastImage, setLastImage] = useState(-1);

  const goToImageByIndex = useCallback(
    (index: number) => {
      // Check if the component is still mounted before navigating
      if (isMountedRef.current) {
        const imageIdAtIndex = getWrappedEntryInReviewList(
          machineObjects,
          index
        );

        if (imageIdAtIndex) {
          navigate(`../../${imageIdAtIndex}/machine-review${location.search}`);
        }
      }
    },
    // Need to include `machineObjects` or else it won't be kept up to date when it's updated by a response.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search, machineObjects]
  );
  const updateAllAction = useCallback(
    (action: MachineReviewActions) => {
      for (const o of filteredAnnotations) {
        const object_id = parseInt(o.id);
        for (const type of o.types) {
          if (!isDefect(type)) {
            dispatch(setMLReview(currentImage, object_id, type, action));
          }
        }
      }
    },
    [currentImage, filteredAnnotations, goToImageByIndex]
  );

  const refreshAnnotations = useCallback(
    () => {
      dispatch(
        updateAnnotations(
          currentProject.id,
          currentImage,
          skyqraftHiddenSetting,
          showDsoTso
        )
      );
    },
    [currentImage, skyqraftHiddenSetting, showDsoTso, updateAnnotations]
  );

  let currentIndex = -1;
  if (machineObjects?.imageIds?.length > 0) {
    currentIndex = machineObjects.imageIds.findIndex(
      (image) => image === currentImage
    );

    // If this is still -1, we know we have a list of images & that we aren't in it.
    // Thus we can move to a valid one.
    if (currentIndex === -1) {
      goToImageByIndex(0);
    }
  }

  useEffect(() => {
    // Exists to make sure we only react to reviews on the current image not switching to another image.
    // This is required to make backtracking work.
    if (lastImage === currentImage) {
      const reviewFinished = filteredAnnotations
        .flatMap((o) => o.workflow_status)
        .every((w) => w !== 1);

      if (reviewFinished) {
        goToImageByIndex(currentIndex + 1);
      }
    }

    // Because the filtered annotations aren't updated at the same time as we switch image, this needs to happen here.
    // It's a guard against auto moving you forward when backtracking.
    // Essentially an "ignore the first update to this image because it's us fetching new data".
    setLastImage(currentImage);
  }, [filteredAnnotations]);

  useEffect(() => {
    if (
      !currentImage ||
      !machineObjects?.imageIds?.length ||
      currentImage !==
        machineObjects.imageIds[machineObjects.imageIds.length - 1]
    ) {
      return;
    }

    const pendingAnnotations = filteredAnnotations.some((annotation) =>
      annotation.workflow_status.some((status) => status === 1)
    );

    if (!pendingAnnotations) {
      toast.info(
        "That was the final image in this review. Refresh browser to see if there are more images to review"
      );
    }
  }, [filteredAnnotations]);

  useEffect(() => {
    dispatch(getMLReviewObjects(sortingOrder));
  }, [sortingOrder, filterActive]);

  // Expand annotation list when you enter Machine Review. David request Jun 2024.
  useEffect(() => {
    dispatch(setExpandAnnotationsList(true));
  }, []);

  if (!machineObjects.imageIds) {
    return <></>;
  }

  let newUrl = location.pathname;
  const regex = /\/machine-review/gi;
  newUrl = newUrl.replaceAll(regex, "");
  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const isDefect = (typeId) => {
    const objectType = objectTypes.find((o) => o.id === typeId);
    return objectType ? objectType.issue : false;
  };

  // Check if any annotation is a defect.
  const hasAnyDefect = filteredAnnotations.some((annotation) =>
    annotation.types.some((id) => objectTypes.find((o) => o.id === id)?.issue)
  );

  return (
    <div className="reviewToolWrapper" style={{ marginLeft: "180px" }}>
      <div className="backgroundControll wide">
        <Tooltip title="Go to previous image" placement="left" arrow>
          <NavigateBefore
            className="navigationArrow"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => {
              goToImageByIndex(currentIndex - 1);
            }}
          />
        </Tooltip>
        <div className="menuWrapper">
          <div className="circleWrapper wide">
            <div
              className={
                hasAnyDefect ? "circle grey disabled" : "circle lightGreen"
              }
            >
              <Tooltip
                title={hasAnyDefect ? "Unavilable for defects" : "Approve all"}
                placement="top"
                arrow
              >
                <Done
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    if (!hasAnyDefect) {
                      updateAllAction("approve");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div
              className={
                hasAnyDefect ? "circle grey disabled" : "circle yellow"
              }
            >
              <Tooltip
                title={
                  hasAnyDefect
                    ? "Unavilable for defects"
                    : "Approve but hide all"
                }
                placement="top"
                arrow
              >
                <MotionPhotosOffOutlined
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    if (!hasAnyDefect) {
                      updateAllAction("approve-hide");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div
              className={hasAnyDefect ? "circle grey disabled" : "circle red"}
            >
              <Tooltip
                title={
                  hasAnyDefect
                    ? "Unavilable for defects"
                    : "Set all as false positive"
                }
                placement="top"
                arrow
              >
                <ThumbDownAlt
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    updateAllAction("deny");
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="subMenuWrapper">
            <Tooltip
              title="Toggle visibility for bounding boxes. Does not change anything on the image"
              placement="bottom"
              arrow
            >
              <VisibilityOutlined
                sx={{ color: "#ffff" }}
                onClick={toggleBoundingBox}
              />
            </Tooltip>
            <Tooltip
              title="Refresh annotations with changes from database"
            >
              <Replay
                sx={{ color: "#ffff" }}
                onClick={refreshAnnotations}
              />
            </Tooltip>
            <Tooltip title="Open image editor in new tab" placement="bottom" arrow>
              <Link
                to={`annotate${location.search}`}
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Edit sx={{ color: "#ffff" }} />
              </Link>
            </Tooltip>
            <OrderByDropDown setSelectedEntry={setSortingOrder} />
            <Tooltip title="Exit review mode" placement="bottom" arrow>
              <CloseOutlined
                sx={{ color: "#ffff" }}
                onClick={() => {
                  navigate(`${newUrl}${location.search}`);
                }}
              />
            </Tooltip>
          </div>
        </div>

        <Tooltip title="Go to next image" placement="right" arrow>
          <NavigateNext
            className="navigationArrow green"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => {
              goToImageByIndex(currentIndex + 1);
            }}
          />
        </Tooltip>
      </div>
      <div
        className="reviewImagesCount"
        onMouseUp={() => setJumpToDialogOpen(true)}
        style={{ cursor: "pointer" }}
      >
        {currentIndex + 1}/{machineObjects.imageIds.length}
        {machineObjects.limited ? "+" : ""}
      </div>
      <Dialog
        open={JumpToDialogOpen}
        onClose={handleCloseJumpToDialog}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            // Remove 1 because arrays start at 0 but our displayed numbering starts at 1.
            const index = Number.parseInt(formData.get("index").toString()) - 1;

            goToImageByIndex(index);

            handleCloseJumpToDialog();
          },
        }}
      >
        <DialogTitle>{jumpToImageText}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="index"
            label="Index"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJumpToDialog}>{cancelText}</Button>
          <Button type="submit">{confirmText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
