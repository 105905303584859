import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Edit,
  RemoveDone,
} from "@mui/icons-material";
import { IconButton, Popover, Tooltip } from "@mui/material";
import { useDispatch, useLanguage, useSelector } from "hooks";
import { groupBy, mapValues, valuesIn } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import translations from "translations";
import { getClientObjectTypeName } from "utils/utils";
import { IImageAnnotation } from "views/AnnotationTool/api";
import { ReviewMode } from "views/image/AnnotationPreview";
import ObjectType from "views/image/MachineReview/ObjectType";

import {
  approveFalseReviewObjects,
  setExpandAnnotationsList,
  updateAnnotationsData,
} from "state/actions";

interface OriginalAnnotation extends IImageAnnotation {
  categories: number;
}

interface IProps {
  image: number;
  setAnnotationListScroll: (boolean) => void;
  annotations: IImageAnnotation[];
  unfilteredAnnotations: IImageAnnotation[];
  reviewMode: ReviewMode;
  setSelectedRegions: (regions: string[]) => void;
  selectedRegions: string[];
  speedZoom: (x: number, y: number, w: number, h: number, id: string) => void;
  toggleBox: (id: string[]) => void;
}

export default function AnnotationsList({
  image,
  setAnnotationListScroll,
  annotations,
  unfilteredAnnotations,
  reviewMode,
  setSelectedRegions,
  selectedRegions,
  speedZoom,
  toggleBox,
}: IProps) {
  const dispatch = useDispatch();
  const expandAnnotationsList = useSelector(
    (state) => state.imageViewer.expandAnnotationsList
  );
  const detectedCategories = useSelector(
    (state) => state.objects.detectedCategories
  );
  const annotatorObjectColor = useSelector(
    (state) => state.user.annotator_color
  );
  const issueCategories = useSelector((state) => state.objects.issueCategories);
  const objectTypes = useSelector((state) =>
    state.objects.objectTypes.filter(
      (t) => !t.skyqraft_only || state.user.skyqraft_employee
    )
  );
  const { language } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleChangeType, setToggleChangeType] = useState<{
    id: string;
    type: number | null;
  }>();
  const [originalAnnotation, setOriginalAnnotation] =
    useState<OriginalAnnotation>();

  const objectTypesWithClientName = objectTypes.map((e) =>
    getClientObjectTypeName(e.id)
  );
  const [previouseImageId, setPrevioseImageId] = useState<number | null>(null);

  useEffect(() => {
    //if the image is not the same as the previous image, then clear the itemId from the url
    if (previouseImageId !== image && previouseImageId !== null) {
      setPrevioseImageId(image);
      searchParams.delete("itemId");
      setSearchParams(searchParams);
      setSelectedRegions([]);
    }
    const itemIDs = searchParams.get("itemId")?.split(",");
    const tempAnnotations = [];

    if (itemIDs) {
      for (const id of itemIDs) {
        const filteredAnnotations = annotations.filter((a) => a.item_id === id);
        for (const annotation of filteredAnnotations) {
          tempAnnotations.push(annotation.id);
        }
      }

      //filter out from tempAnnotations if the annotation is already selected
      const tempAnnotationsFiltered = tempAnnotations.filter(
        (a) => !selectedRegions.includes(a)
      );

      toggleBox(tempAnnotationsFiltered);
    }

    if (previouseImageId === null) {
      setPrevioseImageId(image);
    }
  }, [annotations.length, image]);

  const modeOpen = reviewMode !== ReviewMode.None;

  //Annotations
  let AnnotationsWithMultipleTypes = [];
  let AllAnnotations = [];

  if (objectTypesWithClientName.length > 0) {
    //Get only annotations with multiple types
    AnnotationsWithMultipleTypes = annotations
      .filter((f) => f.types.length > 1)
      .map((e) =>
        e.types.map((t, i) => {
          const objectType = objectTypesWithClientName.find((f) => f.id === t);

          if (
            objectType &&
            (e.workflow_status[i] !== 4 ||
              reviewMode === ReviewMode.FalsePositiveReview)
          ) {
            return {
              id: e.id,
              objectType: objectType,
            };
          }
        })
      );

    for (let i = 0; i < AnnotationsWithMultipleTypes.length; i++) {
      AnnotationsWithMultipleTypes[i] = AnnotationsWithMultipleTypes[i].filter(
        (e) => !!e
      );
    }

    AnnotationsWithMultipleTypes = AnnotationsWithMultipleTypes.filter(
      (e) => e.length > 0
    );

    //Get all annotations as a nested list.
    AllAnnotations = annotations
      .map((annotation) => {
        return annotation.types
          .filter(
            (t, i) =>
              objectTypesWithClientName.some((f) => f.id === t) &&
              (annotation.workflow_status[i] !== 4 ||
                reviewMode !== ReviewMode.None)
          )
          .map((t, i) => {
            return {
              id: annotation.id,
              type: annotation.types[i],
              type_id: annotation.type_id[i],
              workflow_status: annotation.workflow_status[i],
              skyqraft_hidden: annotation.skyqraft_hidden[i],
              confidence: annotation.confidence[i],
              objectType: objectTypesWithClientName.filter(
                (f) => f.id === t
              )[0],
            };
          });
      })
      .filter((t) => t.length > 0);

    AllAnnotations.sort((a, b) => {
      const nameA = a[0].objectType.name.toUpperCase();
      const nameB = b[0].objectType.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  //Get annotations with one type and group them
  let filterOutOneTypes = annotations.filter(
    (f) =>
      f.types.length === 1 &&
      (f.workflow_status[0] !== 4 ||
        reviewMode === ReviewMode.FalsePositiveReview)
  );

  filterOutOneTypes = mapValues(filterOutOneTypes, (val) => {
    return {
      ...val,
      client_object_type_id: [
        getClientObjectTypeName(val.types[0])?.client_object_type_id,
      ],
    };
  });

  let groupedAnnotations = groupBy(
    filterOutOneTypes,
    "client_object_type_id[0]"
  );
  groupedAnnotations = mapValues(groupedAnnotations, (val) => {
    return {
      id: val.map((v) => v.id),
      length: val.length,
      objectType: objectTypesWithClientName.filter(
        (f) => f.id === val[0].types[0]
      )[0],
    };
  });
  groupedAnnotations = valuesIn(groupedAnnotations);

  groupedAnnotations = groupedAnnotations.filter((f) => !!f.objectType);

  //Popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event, id, type) => {
    setToggleChangeType({ id: id, type: type });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setToggleChangeType({ id: "", type: null });
    setAnchorEl(null);
    setOriginalAnnotation(undefined);
  };

  const open = Boolean(anchorEl);

  //Edit Type List stuff
  let sortedDetectedCategories = detectedCategories.sort((a, b) => {
    if (a.sorting_order < b.sorting_order) {
      return 1;
    }
    if (a.sorting_order > b.sorting_order) {
      return -1;
    }
    return 0;
  });

  let sortedIssueCategories = issueCategories.sort((a, b) => {
    if (a.sorting_order < b.sorting_order) {
      return 1;
    }
    if (a.sorting_order > b.sorting_order) {
      return -1;
    }
    return 0;
  });

  const detectedCatIds = sortedDetectedCategories.map((e) => e.id);
  const issueCatIds = sortedIssueCategories.map((e) => e.id);

  const groupedByDetectedCategory = groupBy(
    objectTypesWithClientName.filter(
      (ot) => ot.category_id && detectedCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  const groupedByIssueCategory = groupBy(
    objectTypesWithClientName.filter(
      (ot) => ot.category_id && issueCatIds.includes(ot.category_id)
    ),
    "category_id"
  );

  const groupedDetectedKeys = Object.keys(groupedByDetectedCategory).map((k) =>
    parseInt(k)
  );
  const groupedIssueKeys = Object.keys(groupedByIssueCategory).map((k) =>
    parseInt(k)
  );

  sortedDetectedCategories = sortedDetectedCategories.filter((e) =>
    groupedDetectedKeys.includes(e.id)
  );
  sortedIssueCategories = sortedIssueCategories.filter((e) =>
    groupedIssueKeys.includes(e.id)
  );

  const editTypeList = (sortedCat, t, groupedByCat) => {
    // Using unfilteredAnnotations to not accidentally delete other entries that have been filtered away.
    const idString = String(t.id);
    const tempAnnotation = unfilteredAnnotations.find((a) => a.id === idString);
    if (!tempAnnotation) {
      return <></>;
    }

    return (
      <div key={tempAnnotation.id} className="issueTypeContainer">
        <div className="issueCategoryContainer">
          {sortedCat
            .map((k) => k.id)
            .map((category, categoryIndex) => {
              return (
                <div
                  key={`category-${categoryIndex}`}
                  style={{
                    backgroundColor:
                      t.objectType.category_id === category ? "#FDD" : "",
                  }}
                  className="issueCategoryItem"
                  onMouseUp={() => {
                    const tempAnnotation = annotations.filter(
                      (a) => a.id === t.id
                    )[0];

                    setOriginalAnnotation({
                      ...tempAnnotation,

                      categories: category,
                    });
                  }}
                >
                  <p>
                    {category
                      ? sortedCat.find((item) => item.id === category)?.[
                          `${(language === "NO"
                            ? "EN"
                            : language
                          ).toLowerCase()}_name`
                        ]
                      : ""}
                  </p>
                </div>
              );
            })}
        </div>

        <div className="issueItemContainer">
          {true &&
            Object.keys(groupedByCat)
              .filter((category) => {
                if (originalAnnotation?.categories) {
                  return parseInt(category) === originalAnnotation?.categories;
                } else {
                  return parseInt(category) === t.objectType.category_id;
                }
              })
              .map((category) => {
                return groupedByCat[category]
                  .sort((a, b) => {
                    const nameA = a.name.toUpperCase();

                    const nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((obj) => (
                    <div
                      key={`${obj.id}`}
                      style={{
                        backgroundColor: t.type === obj.id ? "#FDD" : "",
                      }}
                      className="issueTypeItem"
                      onMouseUp={() => {
                        dispatch(
                          updateAnnotationsData(
                            {
                              ...tempAnnotation,
                              types: tempAnnotation.types.map(
                                (value) => (value === t.type ? obj.id : value) // Find by type not index because in review modes the index we get in may differ as we filter out other types.
                              ),
                            },
                            image
                          )
                        );
                        setToggleChangeType({ id: "", type: null });
                        setOriginalAnnotation(undefined);
                      }}
                    >
                      <p> {obj.name}</p>
                    </div>
                  ));
              })}
        </div>
      </div>
    );
  };

  const [speedZoomToggleID, setSpeedZoomToggleID] = useState("");

  let speedZoomToggleList = [];

  function handleClickAnnotationBox(event, toogleValue, e) {
    if (event.shiftKey || event.ctrlKey) {
      let clickedBox: {
        x: number;
        y: number;
        w: number;
        h: number;
      };
      if (Array.isArray(e)) {
        clickedBox = annotations.filter((f) => f.id === e[0].id)[0];
        speedZoom(
          clickedBox.x,
          clickedBox.y,
          clickedBox.w,
          clickedBox.h,
          e[0].id
        );
      } else {
        speedZoomToggleList = e.id;
        const currentIndex = speedZoomToggleList.indexOf(speedZoomToggleID);

        if (currentIndex === -1) {
          clickedBox = annotations.filter((f) => f.id === e.id[0])[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[0]
          );
          setSpeedZoomToggleID(e.id[0]);
        }

        if (currentIndex !== -1 && currentIndex < e.id.length - 1) {
          clickedBox = annotations.filter(
            (f) => f.id === e.id[currentIndex + 1]
          )[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[currentIndex + 1]
          );
          setSpeedZoomToggleID(e.id[currentIndex + 1]);
        }

        if (currentIndex === e.id.length - 1) {
          clickedBox = annotations.filter(
            (f) => f.id === e.id[currentIndex]
          )[0];
          speedZoom(
            clickedBox.x,
            clickedBox.y,
            clickedBox.w,
            clickedBox.h,
            e.id[currentIndex]
          );
          setSpeedZoomToggleID("");
          speedZoomToggleList = [];
        }
      }
    } else {
      toggleBox(toogleValue);
    }
  }

  const groupedAndCombinedAnnotations = [
    <>
      {!expandAnnotationsList &&
        AnnotationsWithMultipleTypes.length > 0 &&
        AnnotationsWithMultipleTypes.map((e, i) => (
          <div
            key={e[0].objectType.name + i}
            className="selectedIndicator"
            style={{
              backgroundColor: `${
                selectedRegions.includes(e[0].id) ? "#EFEFEF" : "#EFEFEF80"
              }`,
              pointerEvents: "all",
            }}
            onMouseUp={(event) => handleClickAnnotationBox(event, [e[0].id], e)}
          >
            <Tooltip
              title={e.map((t, i) => (
                <div key={`${i}`}>{t.objectType.name}</div>
              ))}
              arrow
              placement="right"
              disableInteractive
            >
              <div className="annotationBox">
                {e.map((t, i) => (
                  <div
                    key={t.objectType.id}
                    className="annotationCircle"
                    style={{
                      backgroundColor: annotatorObjectColor
                        ? t.objectType?.class_color
                        : t.objectType?.color,
                      zIndex: 100 - i,
                    }}
                  />
                ))}
              </div>
            </Tooltip>
          </div>
        ))}
    </>,
    <>
      {!expandAnnotationsList &&
        groupedAnnotations?.map((e, i) => (
          <Tooltip
            key={e.objectType.name + i}
            title={e.objectType.name}
            arrow
            placement="left"
            disableInteractive
          >
            <div
              className="selectedIndicator"
              style={{
                backgroundColor: `${
                  selectedRegions.map((r) => e.id.includes(r)).includes(true)
                    ? "#EFEFEF"
                    : "#EFEFEF80"
                }`,
                pointerEvents: "all",
              }}
              onMouseUp={(event) =>
                handleClickAnnotationBox(
                  event,
                  e.id.map((e) => e),
                  e
                )
              }
            >
              <div className="annotationBox">
                <div
                  className="annotationCircle"
                  style={{
                    backgroundColor: annotatorObjectColor
                      ? e.objectType?.class_color
                      : e.objectType?.color,
                    zIndex: 100 - i,
                  }}
                />
                {e.length > 1 && <p className="annotationCount">{e.length}</p>}
              </div>
            </div>
          </Tooltip>
        ))}
    </>,
  ];

  const groupedAndCombinedAnnotationsSorted = groupedAndCombinedAnnotations
    .filter((e) => e.props.children.length > 0)
    .map((e) => e.props.children);
  groupedAndCombinedAnnotationsSorted.sort((a, b) => {
    const nameA = a?.key?.toUpperCase();
    const nameB = b?.key?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const myDiv = useRef(null);

  function handleScroll() {
    if (myDiv.current.scrollHeight > myDiv.current.clientHeight) {
      setAnnotationListScroll(true);
    } else {
      setAnnotationListScroll(false);
    }
  }

  return (
    <div
      style={{
        height: "80vh",
        position: "absolute",
        zIndex: "45",
        pointerEvents: "none",
      }}
    >
      {annotations.length > 0 && (
        <div className="arrowExpandButton">
          <Tooltip
            title={translations.ImageViewer.speedZoom[language]}
            arrow
            placement="top"
            disableInteractive
          >
            {!expandAnnotationsList ? (
              <ArrowForwardIosOutlined
                fontSize="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  dispatch(setExpandAnnotationsList(true));
                }}
                style={{ pointerEvents: "all" }}
              />
            ) : (
              <ArrowBackIosOutlined
                fontSize="small"
                sx={{ color: "#FFFFFF" }}
                onClick={() => {
                  dispatch(setExpandAnnotationsList(false));
                  setAnnotationListScroll(false);
                }}
                style={{ pointerEvents: "all" }}
              />
            )}
          </Tooltip>
        </div>
      )}
      <div
        className="annotationToggleListWrapper"
        ref={myDiv}
        onMouseOver={() => handleScroll()}
        onFocus={() => handleScroll()}
        onMouseLeave={() =>
          setTimeout(() => setAnnotationListScroll(false), 100)
        }
        style={{ pointerEvents: expandAnnotationsList ? "all" : "none" }}
      >
        {expandAnnotationsList &&
          AllAnnotations.length > 0 &&
          AllAnnotations.map((e, i) => (
            <div
              key={`${i}`}
              className="selectedIndicator expanded"
              style={{
                border: "5px solid",
                borderColor: selectedRegions.includes(e[0]?.id)
                  ? "#1976d2"
                  : "#efefef00",
              }}
              onMouseUp={(event) =>
                handleClickAnnotationBox(event, [e[0]?.id], e)
              }
            >
              <div className="annotationBoxExpanded">
                {e.map((t, index) => (
                  <div
                    key={`${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: 35,
                    }}
                    data-testid={`annotationsList.annotation.type_id.${t.type_id}`}
                  >
                    {reviewMode !== ReviewMode.MachineReview && (
                      <div
                        className="annotationCircle"
                        style={{
                          backgroundColor: annotatorObjectColor
                            ? t.objectType?.class_color
                            : t.objectType?.color,
                          zIndex: 100 - i,
                        }}
                      />
                    )}

                    <h4 className="annotationName">{t.objectType.name}</h4>
                  </div>
                ))}
              </div>

              {modeOpen && (
                <div className="ReviewMenuButtons">
                  {e.map((t, index) => (
                    <React.Fragment key={`${index}`}>
                      <div
                        key={`${index}`}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Edit
                          onClick={(event) => handleClick(event, t.id, t.type)}
                          style={{ marginRight: 5 }}
                        />

                        {reviewMode === ReviewMode.MachineReview && (
                          <ObjectType
                            key={`${index}`}
                            regionID={parseInt(e[index].id)}
                            type={t.objectType.id}
                            skyqraft_hidden={t.skyqraft_hidden}
                            workflow_status={t.workflow_status}
                            confidence={t.confidence}
                          />
                        )}
                        {reviewMode === ReviewMode.SuperFalsePositiveReview && (
                          <IconButton
                            sx={{
                              backgroundColor: "#0e7d21",
                              "&:hover": {
                                backgroundColor: "#14b72f",
                              },
                            }}
                            onClick={() =>
                              dispatch(
                                approveFalseReviewObjects(
                                  image,
                                  [parseInt(t.id)],
                                  () => {}
                                )
                              )
                            }
                          >
                            <RemoveDone htmlColor="white" />
                          </IconButton>
                        )}
                      </div>
                      <Popover
                        id={t.id}
                        open={
                          open &&
                          t.id === toggleChangeType?.id &&
                          t.type === toggleChangeType?.type
                        }
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {t.id === toggleChangeType?.id &&
                        t.type === toggleChangeType?.type &&
                        !issueCatIds.includes(t.objectType.category_id)
                          ? editTypeList(
                              sortedDetectedCategories,
                              t,
                              groupedByDetectedCategory
                            )
                          : editTypeList(
                              sortedIssueCategories,
                              t,
                              groupedByIssueCategory
                            )}
                      </Popover>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
          ))}

        {groupedAndCombinedAnnotationsSorted}
      </div>
    </div>
  );
}
