const translations = {
  PoleCatcher: {
    NoImagesFound: {
      EN: "No images found for this pole",
      NO: "Ingen bilder funnet for denne masten",
      SE: "Inga bilder hittades för denna stolpe",
    },
  },
  pole: {
    label: {
      EN: "Label",
      NO: "Merkelapp",
      SE: "Littra",
    },
    feedBay: {
      EN: "Feed bay",
      NO: "Feed bay",
      SE: "Fack nr",
    },
    feedStation: {
      EN: "Feed station",
      NO: "Fôringsstasjon",
      SE: "Fackstation",
    },
    lastViewedBy: {
      EN: "Last viewed by",
      NO: "Sist sett av",
      SE: "Senast visad av",
    },
    lastViewedAt: {
      EN: "Last viewed at",
      NO: "Sist sett kl",
      SE: "Senast visad",
    },
    assignImagesAroundPole: {
      EN: "Assign images around pole",
      NO: "Tildel bilder rundt masten",
      SE: "Tilldela bilder runt stolpe",
    },
  },
  Month: {
    0: {
      EN: "January",
      NO: "januar",
      SE: "Januari",
    },
    1: {
      EN: "February",
      NO: "februar",
      SE: "Februari",
    },
    2: {
      EN: "March",
      NO: "mars",
      SE: "Mars",
    },
    3: {
      EN: "April",
      NO: "april",
      SE: "April",
    },
    4: {
      EN: "May",
      NO: "mai",
      SE: "Maj",
    },
    5: {
      EN: "June",
      NO: "juni",
      SE: "Juni",
    },
    6: {
      EN: "July",
      NO: "juli",
      SE: "Juni",
    },
    7: {
      EN: "August",
      NO: "august",
      SE: "Augusti",
    },
    8: {
      EN: "September",
      NO: "september",
      SE: "September",
    },
    9: {
      EN: "October",
      NO: "oktober",
      SE: "Oktober",
    },
    10: {
      EN: "November",
      NO: "november",
      SE: "November",
    },
    11: {
      EN: "December",
      NO: "desember",
      SE: "December",
    },
  },
  Infobox: {
    NoMission: {
      EN: "No project selected",
      NO: "Ingen prosjekt er valgt",
      SE: "Inget projekt är valt",
    },
    Mission: {
      EN: "Project",
      NO: "Prosjekt",
      SE: "Projekt",
    },
    FilterActive: {
      EN: "Filter is active",
      NO: "Filteret er aktivt",
      SE: "Filter är aktivt",
    },
    UsersConnected: {
      EN: "users connected",
      NO: "brukere tilkoblet",
      SE: "användare uppkopplade",
    },
  },
  // Image viewer
  ImageViewer: {
    Hidden: {
      EN: "Hidden",
      NO: "Skjult",
      SE: "Dold",
    },
    Processed: {
      EN: "Processed",
      NO: "Behandlet",
      SE: "Behandlad",
    },
    MarkAsProcessed: {
      EN: "Mark as processed",
      NO: "Merk som behandlet",
      SE: "Markera som behandlad",
    },
    lidarImage: {
      EN: "Lidar image",
      NO: "Lidar bilde",
      SE: "Lidar bild",
    },
    Discs: {
      EN: "Discs",
      NO: "plater",
      SE: "Tallrikar",
    },
    SendFeedback: {
      EN: "Send feedback about the image to Arkion",
      NO: "Send tilbakemelding om bildet til Arkion",
      SE: "Skicka feedback om bilden till Arkion",
      PoorImageQuality: {
        EN: "Image quality is poor",
        NO: "Bildekvaliteten er dårlig",
        SE: "Dålig bildkvalitet",
      },
      ReportImage: {
        EN: "Report image",
        NO: "Rapporter bilde",
        SE: "Rapportera bild",
      },
      AddComment: {
        EN: "Add a comment. Press cancel to skip comment",
        NO: "Legg til en kommentar. Trykk på avbryt for å hoppe over kommentaren",
        SE: "Lägg till kommentar. Klicka på avbryt för att skippa kommentar",
      },
      AddCommentRequired: {
        EN: "Add a comment.",
        NO: "Legg til en kommentar.",
        SE: "Lägg till kommentar.",
      },
      Registering: {
        EN: "Registering feedback",
        NO: "Registrerer tilbakemelding",
        SE: "Registrerar feedback",
      },
      Registered: {
        EN: "Feedback registered",
        NO: "Tilbakemelding registrert",
        SE: "Feedback mottagen",
      },
      IncorrectDetection: {
        EN: "Incorrect detection",
        NO: "Feil deteksjon",
        SE: "Felaktig detektering",
      },
      MissingBlur: {
        EN: "Sensitive objects visible",
        NO: "Sensitive objekter er synlige",
        SE: "Känsliga object synliga",
      },
      CommentRequired: {
        EN: "A comment is needed for this action",
        NO: "En kommentar er nødvendig for denne handlingen",
        SE: "En kommentar krävs för denna handling",
      },
      Other: {
        EN: "Other",
        NO: "Annen",
        SE: "Annat",
      },
      AlreadyReported: {
        EN: "Already reported",
        NO: "Allerede rapportert",
        SE: "Redan rapporterat",
      },
      Resolve: {
        EN: "Resolve",
        NO: "Løse",
        SE: "Lös",
      },
      Resolved: {
        EN: "Resolved",
        NO: "Løst",
        SE: "Löst",
      },
      ResolvedBy: {
        EN: "Resolved by",
        NO: "Løst av",
        SE: "Löst av",
      },
    },
    Severity: {
      EN: "Severity",
      NO: "Alvorlighetsgrad",
      SE: "Allvarlighetsgrad",
    },
    DefectFixed: {
      EN: "Defect fixed",
      NO: "Avvik fikset",
      SE: "Skada åtgärdad",
    },
    DefectNotFixed: {
      EN: "Defect not fixed",
      NO: "Avvik ikke fikset",
      SE: "Skada ej åtgärdad",
    },
    DefectReported: {
      EN: "Defect reported",
      NO: "Avvik meldt",
      SE: "Skada rapporterad",
    },
    DefectNotReported: {
      EN: "Click to set report date",
      NO: "Klikk for å angi rapportdato",
      SE: "Klicka för att sätta rapportdatum",
    },
    MarkAsFixed: {
      EN: "Not fixed - click to mark as fixed",
      NO: "Ikke løst - klikk for å merke som løst",
      SE: "Ej åtgärdad - klicka för att markera som åtgärdad",
    },
    MarkAsDamaged: {
      EN: "Fixed - click to mark as not fixed",
      NO: "Fikset - klikk for å merke som ikke løst",
      SE: "Åtgärdad - klicka för att markera som ej åtgärdad",
    },
    MarkAsFixedVerify: {
      EN: "Are you sure you want to mark this defect as fixed?",
      NO: "Er du sikker på at du vil merke dette avviket som rettet?",
      SE: "Är du säker att du vill markera skadan som åtgärdad?",
    },
    MarkAsDamagedVerify: {
      EN: "Are you sure you want to mark this defect as damaged?",
      NO: "Er du sikker på at du vil merke dette avviket som skadet?",
      SE: "Är du säker att du vill markera skadan som en skada?",
    },
    NavigateParent: {
      EN: "Navigate to nearby images",
      NO: "Naviger til bilder i nærheten",
      SE: "Navigera till närliggande bilder",
      Title: {
        EN: "Navigate images",
        NO: "Naviger i bilder",
        SE: "Navigera till bild",
      },
    },
    rgbImage: {
      EN: "Show RGB Image",
      NO: "Vis RGB-bilde",
      SE: "Visa RGB bild",
    },
    wideImage: {
      EN: "Show wide angle image",
      NO: "Vis vidvinkelbilde",
      SE: "Visa vidvinkelsbild",
    },
    zoomImage: {
      EN: "Show zoom image. (Tip, shift + click for speed-zoom)",
      NO: "Vis zoombilde. (Tips, shift + klikk for hastighetszoom)",
      SE: "Visa inzoomad bild. (Tips, shift + klicka för snabb-zoom)",
    },
    speedZoom: {
      EN: "Tip, shift + click for speed-zoom on image or list below",
      NO: "Tips, shift + klikk for speed-zoom på bildet eller listen nedenfor",
      SE: "Tips, shift + klicka för snabb-zoom på bilden eller listan nedanför",
    },
    thermalImage: {
      EN: "Show thermal image",
      NO: "Vis termisk bilde",
      SE: "Visa värmebild",
    },
    feedBay: {
      EN: "Feed bay",
      NO: "Feed bay",
      SE: "Littra",
    },
    Label: {
      EN: "Label",
      NO: "Merkelapp",
      SE: "Littra",
    },
    pole: {
      EN: "Pole",
      NO: "Mast",
      SE: "Stolpe",
    },
    flight: {
      EN: "Flight",
      NO: "Flyvning",
      SE: "Flygning",
    },
    StationFeedbay: {
      EN: "Station : Feedbay",
      NO: "Stasjon: Feedbay",
      SE: "Station : Fack",
    },
    imageName: {
      EN: "Image name",
      NO: "Bildenavn",
      SE: "Bildnamn",
    },
    dateImageTaken: {
      EN: "Date captured",
      NO: "Dato fanget",
      SE: "Datum tagen",
    },
    NavigateChild: {
      EN: "Navigate images",
      NO: "Naviger i bilder",
      SE: "Navigera bilder",
    },
    ImageInfo: {
      EN: "Image information",
      NO: "Bildeinformasjon",
      SE: "Bildinformation",
      Title: {
        EN: "Image information",
        NO: "Bildeinformasjon",
        SE: "Bildinformation",
      },
    },
    List: {
      EN: "Assign to list",
      NO: "Tilordne til liste",
      SE: "Tilldela lista",
      Title: {
        EN: "Assign to list",
        NO: "Tilordne til liste",
        SE: "Tilldela lista",
      },
    },
    BoundingBoxes: {
      EN: "Toggle bounding boxes",
      NO: "Slå avgrensningsbokser",
      SE: "Toggla begränsningslåda",
      Title: {
        EN: "Show/Hide boxes",
        NO: "Vis/skjul bokser",
        SE: "Visa/Dölj lådor",
      },
    },
    Pipelines: {
      EN: "Toggle pipelines",
      NO: "Bytt rørledninger",
      SE: "Toggla gasledningar",
      Title: {
        EN: "Show/Hide pipelines",
        NO: "Vis/skjul rørledninger",
        SE: "Visa/Dölj gasledningar",
      },
    },
    GoogleMaps: {
      EN: "Open in Google Maps",
      NO: "Åpne i Google Maps",
      SE: "Öppna i Google Maps",
      Title: {
        EN: "Open in Google Maps",
        NO: "Åpne i Google Maps",
        SE: "Öppna i Google Maps",
      },
    },
    Download: {
      EN: "Download this image",
      NO: "Last ned dette bildet",
      SE: "Ladda ned denna bild",
      Title: {
        EN: "Download image",
        NO: "Last ned bilde",
        SE: "Ladda ned bild",
      },
    },
    Approve: {
      EN: "Approve image",
      NO: "Godkjenn bildet",
      SE: "Godkänn bild",
      Title: {
        EN: "Approve image",
        NO: "Godkjenn bildet",
        SE: "Godkänn bild",
      },
    },
    ShowTemp: {
      EN: "Show temperatures",
      NO: "Vis temperaturer",
      SE: "Visa temperaturer",
      Title: {
        EN: "Show temperatures",
        NO: "Vis temperaturer",
        SE: "Visa temperaturer",
      },
    },
    Edit: {
      EN: "Edit bounding boxes and mark defects",
      NO: "Rediger avgrensningsrammer og merk avvik",
      SE: "Editera objekt på bilden",
      Title: {
        EN: "Edit boxes",
        NO: "Rediger bokser",
        SE: "Justera annoteringar",
      },
    },
    AnnotatorReview: {
      EN: "Review annotations",
      NO: "Gjennomgå merknader",
      SE: "Granska annoteringar",
      Title: {
        EN: "Review annotations",
        NO: "Gjennomgå merknader",
        SE: "Granska annoteringar",
      },
    },
    MLReview: {
      EN: "Review Machine Learning",
      NO: "Gjennomgå maskinlæring",
      SE: "Granska Maskininlärning",
      Title: {
        EN: "Review Machine Learning",
        NO: "Gjennomgå maskinlæring",
        SE: "Granska maskininlärning",
      },
    },
    FalsePositiveReview: {
      EN: "False Positive Review",
      NO: "Falsk positiv anmeldelse",
      SE: "Granska falska positiva",
    },
    SuperFalseReview: {
      EN: "Review false positive classifications",
      NO: "Gjennomgå falske positive klassifiseringer",
      SE: "Granska falska positiva klassifikationer",
    },
    Flag: {
      EN: "Flag image for reannotation",
      NO: "Flagg bilde for reannotering",
      SE: "Flagga för omannotering",
      Title: {
        EN: "Flag image",
        NO: "Flaggbilde",
        SE: "Flagga bild",
      },
    },
    Hide: {
      EN: "Hide this image",
      NO: "Skjul dette bildet",
      SE: "Dölj denna bild",
      Title: {
        EN: "Hide image",
        NO: "Skjul bildet",
        SE: "Dölj bild",
      },
    },
    Fullscreen: {
      EN: "Toggle fullscreen",
      NO: "Slå på fullskjerm",
      SE: "Toggla helskärm",
    },
    Close: {
      EN: "Close image viewer",
      NO: "Lukk bildeviseren",
      SE: "Stäng bildverktyg",
      Title: {
        EN: "Close",
        NO: "Lukke",
        SE: "Stäng",
      },
    },
  },
  // Review tool
  ReviewTool: {
    Approve: {
      EN: "Approve",
      NO: "Vedta",
      SE: "Godkänn",
    },
    Reject: {
      EN: "Flag image for reannotation",
      NO: "Flagg bilde for reannotering",
      SE: "Flagga bild för omannotering",
    },
    RejectML: {
      EN: "Remove AI output. Store this as a false warning",
      NO: "Fjern AI-resultat. Lagre dette som en falsk advarsel",
      SE: "Ta bort AI resultat. Spara som falsk varning",
    },
    ConfirmButHide: {
      EN: "Confirm object. But hide.",
      NO: "Bekreft objekt. Men gjem.",
      SE: "Verifiera objekt, men dölj.",
    },
    Previous: {
      EN: "Skip to previous reviewable object",
      NO: "Gå til forrige gjennomgåbare objekt",
      SE: "Skippa till föregående granskbara objekt.",
    },
    Next: {
      EN: "Skip to next reviewable object",
      NO: "Gå til neste gjennomgåbare objekt",
      SE: "Skippa till nästa granskbara objekt.",
    },
    Exit: {
      EN: "Exit Review Mode",
      NO: "Avslutt gjennomgangsmodus",
      SE: "Lämna granskningsläge",
    },
  },
  // Annotation tool
  AnnotationTool: {
    setDate: {
      EN: "Set date",
      NO: "Sett dato",
      SE: "Välj datum",
    },
    BeforeSaving: {
      EN: "Do you want to save your change and please provide a comment",
      NO: "Vil du lagre endringen og gi en kommentar",
      SE: "Vill du spara dina ändringar. Lägg in en kommentar om ni önskar.",
    },
    SureYouWantToLeave: {
      EN: "Are you sure you want to leave without saving?",
      NO: "Er du sikker på at du vil forlate uten å lagre?",
      SE: "Är du säker att du vill lämna utan att spara?",
    },
    IncompleteObjects: {
      EN: "You must finish your incomplete objects before saving",
      NO: "Du må fullføre de ufullstendige objektene dine før du lagrer",
      SE: "Du måste göra klart objekt som ännu ej blivit ifyllda innan du sparar.",
    },
    getDataFailed: {
      EN: "Getting data failed",
      NO: "Henting av data mislyktes",
      SE: "Misslyckades att hämta data",
    },
    addDetection: {
      EN: "Add object",
      NO: "Legg til objekt",
      SE: "Lägg till objekt",
    },
    addDefect: {
      EN: "Add another defect to the same bounding box",
      NO: "Legg til ettannet avvik  i den samme avgrensningsboksen",
      SE: "Lägg till ytterligare en skada för samma bounding box",
    },
    deleteClass: {
      EN: "Delete class",
      NO: "Slett kurs",
      SE: "Ta bort klassificering",
    },
    editClass: {
      EN: "Edit class",
      NO: "Rediger klasse",
      SE: "Ändra klassificering",
    },
    convertToDefect: {
      EN: "Convert to defect",
      NO: "Konverter til avvik",
      SE: "Konvertera till skada",
    },
    expand: {
      EN: "Expand",
      NO: "Utvide",
      SE: "Utöka",
    },
    collapse: {
      EN: "Collapse",
      NO: "Kollapse",
      SE: "Stäng",
    },
    convertToDetection: {
      EN: "Convert to object",
      NO: "Konverter til objekt",
      SE: "Convertera till objekt",
    },
    Select: {
      EN: "Select",
      NO: "Velge",
      SE: "Välj",
    },
    Detection: {
      EN: "Add a detection",
      NO: "Legg til en deteksjon",
      SE: "Skapa objekt",
    },
    Defect: {
      EN: "Add a defect",
      NO: "Legg til et avvik",
      SE: "Skapa skada",
    },
    Reset: {
      EN: "Reset",
      NO: "Tilbakestill",
      SE: "Återställ",
    },
    AllVerified: {
      EN: "All verified",
      NO: "Alle bekreftet",
      SE: "Alla verifierade",
    },
    VerifyAll: {
      EN: "Verify all",
      NO: "Bekreft alle",
      SE: "Verifiera alla",
    },
    Save: {
      EN: "Save",
      NO: "Spare",
      SE: "Spara",
    },
    Cancel: {
      EN: "Cancel",
      NO: "Avbryt",
      SE: "Avbryt",
    },
    NoDefinitionsSpecified: {
      EN: "No definitions specified",
      NO: "Ingen definisjoner spesifisert",
      SE: "Inga definitioner specificerade",
    },
    DefinitionError: {
      EN: "Definition error",
      NO: "Definisjonsfeil",
      SE: "Definitions fel",
    },
    TypeMissing: {
      EN: "Type missing",
      NO: "Type mangler",
      SE: "Objekttyp saknas",
    },
    TypeError: {
      EN: "Type error",
      NO: "Skrivefeil",
      SE: "Objekttyp fel",
    },
    SeverityMissing: {
      EN: "Severity missing",
      NO: "Alvorlighetsgrad mangler",
      SE: "Allvarlighetsgrad saknas",
    },
    FalsePositivesWillAutomaticallyRemoveTheSeverityLevels: {
      EN: "False positives will automatically remove the severity level",
      NO: "Falske positive vil automatisk fjerne alvorlighetsnivået",
      SE: "Falska positiva kommer automatiskt att ta bort allvarlighetsgraden",
    },
    SetASevrityLevel: {
      EN: "Please set a severity level",
      NO: "Vennligst angi et alvorlighetsnivå",
      SE: "Vänligen sätt en allvarlighetsgrad",
    },
    Close: {
      EN: "Close",
      NO: "Lukke",
      SE: "Stäng",
    },
    HumanMachine: {
      EN: "Display non-defect verification",
      NO: "Vis ikke-avvik bekreftelse",
      SE: "Visa icke-defekt verifiering",
    },
    LastUpdated: {
      EN: "Last updated by",
      NO: "Sist oppdatert av",
      SE: "Senast uppdaterad av",
    },
    EditStatus: {
      EN: "Edit workflow status",
      NO: "Rediger arbeidsflytstatus",
      SE: "Ändra status för arbetsflöde",
    },
    CreatedBy: {
      EN: "Created by you",
      NO: "Laget av deg",
      SE: "Skapad av dig",
    },
    AddComment: {
      EN: "Add comment",
      NO: "Legg til kommentar",
      SE: "Lägg till kommentar",
    },
    ConvertToDetection: {
      EN: "Convert to detection",
      NO: "Konverter til deteksjon",
      SE: "Omvandla till objekt",
    },
    ReportDefect: {
      EN: "Report defect on top of detection",
      NO: "Rapporter feil på toppen av deteksjon",
      SE: "Rapportera skada ovanpå objekt",
    },
    WarningLocked: {
      EN: "Object is locked, are you sure you want to continue editing?",
      NO: "Objektet er låst, er du sikker på at du vil fortsette å redigere?",
      SE: "Objektet är låst, är du säker att du vill fortsätta redigera?",
    },
    Locked: {
      EN: "Locked, click to edit",
      NO: "Låst, klikk for å redigere",
      SE: "Låst, tryck för att redigera",
    },
    Delete: {
      EN: "Delete",
      NO: "Slett",
      SE: "Radera",
    },
    Hide: {
      EN: "Hide",
      NO: "Gjemme",
      SE: "Dölj",
    },
    Show: {
      EN: "Show",
      NO: "Vise",
      SE: "Visa",
    },
    Visibility: {
      EN: "Toggle the visibility for the bounding box on the image. This is useful for when you have multiple overlapping boundingboxes. It does not change anything on the image",
      NO: "Slå av/på synligheten for avgrensningsrammen på bildet. Dette er nyttig når du har flere overlappende avgrensningsbokser. Det endrer ingenting på bildet",
      SE: "Toggla synbarheten för bounding box på bilden. Detta är hjälpsamt om du har flera boundingboxar som ligger över varandra. Det ändrar ingenting på bilden",
    },
    VisibilityAll: {
      EN: "Toggle the visibility for all the bounding boxes. It does not change anything on the image",
      NO: "Veksle på synligheten for alle avgrensningsboksene. Det endrer ingenting på bildet",
      SE: "Toggla synbarheten för alla bounding boxar. Det ändrar ingenting på bilden",
    },
    SelectDetection: {
      EN: "Select detection type",
      NO: "Velg deteksjonstype",
      SE: "Välj objekttyp",
    },
    SelectSeverity: {
      EN: "Select severity",
      NO: "Velg alvorlighetsgrad",
      SE: "Välj allvarlighetsgrad",
    },
    NoObjectsMatches: {
      EN: "No objects matches",
      NO: "Ingen objekter samsvarer",
      SE: "Inga objekt matchar",
    },
    NewRegion: {
      EN: "New region",
      NO: "Ny region",
      SE: "Nytt objekt",
    },
    WorkflowStatusMenu: {
      ReviewRequested: {
        EN: "Request review",
        NO: "Be om gjennomgang",
        SE: "Begär granskning",
      },
      Approve: {
        EN: "Approve",
        NO: "Vedta",
        SE: "Godkänn",
      },
      SupervisorApprove: {
        EN: "Supervisor approve",
        NO: "Veileder godkjenner",
        SE: "Handledargodkännande",
      },
      FalsePositive: {
        EN: "False positive",
        NO: "Falsk positiv",
        SE: "Falsk positiv",
      },
      TruePositive: {
        EN: "True positive",
        NO: "Riktig positiv",
        SE: "Sann positiv",
      },
      SkyqraftHidden: {
        EN: "Hide for non-arkion",
        NO: "Skjul for ikke-arkion",
        SE: "Dölj för icke-arkion",
      },
      SkyqraftVisible: {
        EN: "Show for non-arkion",
        NO: "Vis for ikke-arkion",
        SE: "Visa för icke-arkion",
      },
    },
  },
  Menu: {
    Close: {
      EN: "Close menu",
      NO: "Lukk menyen",
      SE: "Stäng menyn",
    },
    BatchJobs: {
      EN: "Batch Jobs",
      NO: "Batch jobber",
      SE: "Batch Jobs",
    },
    // Missions
    Missions: {
      EN: "Projects",
      NO: "Prosjekter",
      SE: "Projekt",
      SubHeader: {
        EN: "Find a project analyzing your grid",
        NO: "Finn et prosjekt som analyserer rutenettet ditt",
        SE: "Hitta ett projekt som analyserar ditt elnät",
      },
      Tooltip: {
        EN: "Create, edit and delete projects",
        NO: "Opprett, rediger og slett prosjekter",
        SE: "Skapa, ändra och ta bort projekt",
      },
      AddMission: {
        EN: "New project",
        NO: "Nytt prosjekt",
        SE: "Nytt projekt",
      },
      OtherMissions: {
        EN: "Other projects",
        NO: "Andre prosjekter",
        SE: "Andra projekt",
      },
      Mission: {
        EN: "Project",
        NO: "Prosjekt",
        SE: "Projekt",
      },
      AnnotationWithoutImage: {
        AddAnnotationWithoutImage: {
          EN: "Add imageless annotation",
          NO: "Legg til bildeløs merknad",
          SE: "Skapa annotering utan bild",
        },
        Click: {
          EN: "Right-click on map to mark the position of the annotation or type the coordinates.",
          NO: "Høyreklikk på kartet for å markere posisjonen til merknaden eller skriv inn koordinatene.",
          SE: "Högerklicka på kartan för att markera positionen för annoteringen eller skriv in koordinaterna.",
        },
        UseCurrentPosition: {
          EN: "Use Current Position",
          NO: "Bruk nåværende posisjon",
          SE: "Använd Nuvarande Position",
        },
        Type: {
          EN: "Critical defect identified",
          NO: "Kritisk avvik identifisert",
          SE: "Kritisk skada identifierad",
        },
        Severity: {
          EN: "Select Severity",
          NO: "Velg Alvorlighet",
          SE: "Välj allvarlighetsgrad",
        },
        Add: {
          EN: "Add",
          NO: "Legge til",
          SE: "Skapa",
        },
      },
    },
    // Filters
    Filters: {
      EN: "Filters",
      NO: "Filtre",
      SE: "Filter",
      Tooltip: {
        EN: "Apply various filters for images and objects",
        NO: "Bruk ulike filtre for bilder og objekter",
        SE: "Aktivera olika filter för bilder och objekt.",
      },
      DateFilter: {
        EN: "Date Filter",
        NO: "Datofilter",
        SE: "Datumfilter",
        Tooltip: {
          EN: "Filter found images based on the date",
          NO: "Filtrer funnet bilder basert på datoen",
          SE: "Filtrera bilder baserat på datum",
        },
        DatePlaced: {
          EN: "Date defect placed",
          NO: "Dato avvik plassert",
          SE: "Datum defekt placerad",
          Tooltip: {
            EN: "Find all defects created during a specific time window",
            NO: "Finn alle avvik som er opprettet i løpet av et bestemt tidsvindu",
            SE: "Hitta alla defekter skapade under ett specifikt tidsfönster",
          },
        },
        Processed: {
          EN: "Date processed",
          NO: "Dato behandlet",
          SE: "Datum bearbetad",
          Tooltip: {
            EN: "Find all defects processed within a time period",
            NO: "Finn alle avvik behandlet innen en tidsperiode",
            SE: "Hitta alla defekter bearbetade inom en tidsperiod",
          },
        },
      },
      ImageLists: {
        EN: "Image lists",
        NO: "Bildelister",
        SE: "Bildlistor",
        ToolTip: {
          EN: "Manage image lists or activate an image list to use it as a filter",
          NO: "Administrer bildelister eller aktiver en bildeliste for å bruke den som et filter",
          SE: "Hantera bildlistor eller aktivera en bildlista för att använda den som ett filter",
        },
      },
      Distance: {
        EN: "Distance",
        NO: "Avstand",
        SE: "Avstånd",
      },
      LidarTooltip: {
        EN: "Filter distance in 3D data of a project. The filter will create a heatmap of all the areas where the distance between the wire and surrounding objects is lower than the value set",
        NO: "Filtrer avstand i 3D-data for et prosjekt. Filteret vil lage et varmekart over alle områdene der avstanden mellom ledningen og omkringliggende objekter er lavere enn verdien som er satt",
        SE: "Filtrera avstånd i 3D datan tillhörande ett projekt. Filtret skapar en heatmap över områden där avståndet mellan linan och kringliggande objekt är kortare än valt värde",
      },
      ClearFilters: {
        EN: "Clear Filters",
        NO: "Tøm filtre",
        SE: "Rensa Filter",
      },
      TypeSelector: {
        EN: "Type selector",
        NO: "Typevelger",
        SE: "Typ av detektering",
        ToolTip: {
          EN: "Choose to filter on defects or objects",
          NO: "Velg å filtrere på avvik eller objekter",
          SE: "Välja att filtrera på antingen skador eller objekt",
        },
      },
      DefectsAndRemarks: {
        EN: "Defects & Remarks",
        NO: "Avvik og bemerkninger",
        SE: "Skador & upplysningar",
        ToolTip: {
          EN: "Filter different types and severity levels of defects and remarks found on images",
          NO: "Filtrer ulike typer og alvorlighetsnivåer av avvik og merknader funnet på bilder",
          SE: "Filtrera olika typer och allvarlighetsgrader av skador och upplysningar markerade på bilder",
        },
        DefectAndRemarkType: {
          EN: "Grouped types of defects and remarks",
          NO: "Grupperte typer avvik og bemerkninger",
          SE: "Grupperade typer av skador och upplysningar",
        },
        RemarkSeverity: {
          EN: "Remark Severity",
          NO: "Merknadsgrad",
          SE: "Upplysningar allvarlighetsgrad",
          Tooltip: {
            EN: "Severity level of remarks",
            NO: "Alvorlighetsgrad av bemerkninger",
            SE: "Allvarlighetsgrad av upplysningar",
          },
        },
      },
      Defect: {
        EN: "Defect",
        NO: "Avvik",
        SE: "Skada",
      },
      Defects: {
        EN: "Defects",
        NO: "Avvik",
        SE: "Skador",
      },
      Remarks: {
        EN: "Remarks",
        NO: "Merknader",
        SE: "Upplysningar",
      },
      Objects: {
        EN: "Objects",
        NO: "Objekter",
        SE: "Objekt",
        ToolTip: {
          EN: "Filter different types of objects found on images",
          NO: "Filtrer ulike typer objekter som finnes på bilder",
          SE: "Filtrera olika typer av objekt markerade på bilder",
        },
      },
      DefectType: {
        EN: "Defect Type",
        NO: "Avviksype",
        SE: "Typ av Skada",
      },
      DefectSeverity: {
        EN: "Defect severity",
        NO: "Avvik alvorlighetsgrad",
        SE: "Skador allvarlighetsgrad",
        ToolTip: {
          EN: "Severity level of defects",
          NO: "Alvorlighetsgrad av avvik",
          SE: "Allvarlighetsgrad av skador",
        },
      },
      Critical: {
        EN: "Critical",
        NO: "Kritisk",
        SE: "Kritisk",
      },
      Issue: {
        EN: "Issue",
        NO: "Bemerkning",
        SE: "Anmärkning",
      },
      RemarkSeverity: {
        EN: "Remark severity",
        NO: "Merknadsgrad",
        SE: "Upplysningar allvarlighetsgrad",
        ToolTip: {
          EN: "Severity level of remarks",
          NO: "Alvorlighetsgrad av bemerkninger",
          SE: "Allvarlighetsgrad av upplysningar",
        },
      },
      ShouldBeFixed: {
        EN: "Should be fixed",
        NO: "Bør fikses",
        SE: "Bör åtgärdas",
      },
      GoodToKnow: {
        EN: "Good to know",
        NO: "Godt å vite",
        SE: "För info",
      },
      Inventory: {
        EN: "Object",
        NO: "Gjenstand",
        SE: "Objekt",
      },
      WorkFlow: {
        EN: "Workflow",
        NO: "Arbeidsflyt",
        SE: "Arbetsstatus",
        ToolTip: {
          EN: "Filter stages of the annotation flow",
          NO: "Filtrer stadier av kommentarflyten",
          SE: "Filtrera olika typer av stadier i annoteringsflödet",
        },
      },
      Labelled: {
        EN: "Labelled by humans/machine",
        NO: "Merket av mennesker/maskin",
        SE: "Märkt av människor/maskin",
      },
      ImageType: {
        EN: "Image type",
        NO: "Bildetype",
        SE: "Bildtyp",
      },
      FlightFilter: {
        EN: "Flight filter",
        NO: "Flyfilter",
        SE: "Flygfilter",
        ToolTip: {
          EN: "Filter different flight ID's. Most commonly used by drone pilots for root causing in case of issues with the flight and/or image upload",
          NO: "Filtrer forskjellige fly-ID-er. Oftest brukt av dronepiloter for rotårsak i tilfelle problemer med flyreisen og/eller bildeopplasting",
          SE: "Filtrera olika flight ID. Används oftast av drönarpiloter vid eventuell felsökning av flygningar och/eller vid bilduppladdning",
        },
      },
      OriginFilter: {
        EN: "Creator Filter",
        NO: "Skaperfilter",
        SE: "Skaparfilter",
        ToolTip: {
          EN: "Filter the creator of a bounding box. Sometimes called the origin of a bounding box.",
          NO: "Filtrer skaperen av en grenseramme. Noen ganger kalt opprinnelsen til en grenseramme.",
          SE: "Filtrera skaparen av en bounding box. Ibland kallat ursprunget av en bounding box",
        },
      },
      Test: {
        EN: "test en",
        NO: "test en",
        SE: "test sv",
      },
      ImageFilter: {
        EN: "Image types",
        NO: "Bildetyper",
        SE: "Bildtyper",
        Tooltip: {
          EN: "Filter different types of images",
          NO: "Filtrer forskjellige typer bilder",
          SE: "Filtrera för olika typer av bilder",
        },
        New: {
          EN: "New defects",
          NO: "Nye avvik",
          SE: "Nya defekter",
          ToolTip: {
            EN: "Filter images that contains new defects",
            NO: "Filtrer bilder som inneholder nye avvik",
            SE: "Filtrera bilder som innehåller nya defekter",
          },
        },
        RGBTooltip: {
          EN: "Regular 2D images",
          NO: "Vanlige 2D-bilder",
          SE: "Vanliga 2D bilder",
        },
        Flagged: {
          EN: "Flagged images",
          NO: "Flaggede bilder",
          SE: "Flaggade bilder",
          ToolTip: {
            EN: "Filter images flagged for reannotation",
            NO: "Filterbilder flagget for ny merknad",
            SE: "Filtrera bilder flaggade för om-annotering",
          },
        },
        Thermal: {
          EN: "Thermal",
          NO: "Termisk",
          SE: "Värmebilder",
          Tooltip: {
            EN: "Thermal images",
            NO: "Termiske bilder",
            SE: "Värmebilder",
          },
        },
        ListFilter: {
          Active: {
            EN: "Active",
            NO: "Aktiv",
            SE: "Aktiv",
          },
          List: {
            EN: "List",
            NO: "Liste",
            SE: "Lista",
          },
          Images: {
            EN: "Images",
            NO: "Bilder",
            SE: "Bilder",
          },
          Info: {
            EN: "Info",
            NO: "Info",
            SE: "Info",
          },
          Delete: {
            EN: "Delete",
            NO: "Slett",
            SE: "Radera",
          },
          DeleteConfirmation: {
            EN: "Are you sure you want to delete list",
            NO: "Er du sikker på at du vil slette listen",
            SE: "Är du säker att du vill ta bort listan",
          },
          RenamePrompt: {
            EN: "Enter new title for list.",
            NO: "Skriv inn ny tittel for listen.",
            SE: "Ange en nytt titel för lista",
          },
          Share: {
            EN: "Share",
            NO: "Dele",
            SE: "Dela",
          },
          NewList: {
            EN: "New list",
            NO: "Ny liste",
            SE: "Ny lista",
          },
          Add: {
            EN: "Add",
            NO: "Legge til",
            SE: "Skapa",
          },
          CopiedToClipboard: {
            EN: "Link was copied to clipboard",
            NO: "Linken ble kopiert til utklippstavlen",
            SE: "Länk kopierades till urlipp",
          },
          Public: {
            EN: "Public",
            NO: "Offentlig",
            SE: "Offentlig",
          },
          Locked: {
            EN: "Locked",
            NO: "Låst",
            SE: "Låst",
          },
          UnLocked: {
            EN: "Unlocked",
            NO: "Ulåst",
            SE: "Olåst",
          },
          Rename: {
            EN: "Rename",
            NO: "Gi nytt navn",
            SE: "Döp om",
          },
        },
      },
    },
    // Tools
    Tools: {
      EN: "Tools",
      NO: "Verktøy",
      SE: "Verktyg",
      Tooltip: {
        EN: "See various tools available for the current project",
        NO: "Se ulike tilgjengelige verktøy for det aktuelle prosjektet",
        SE: "Se vertyg för detta projekt.",
      },
      ProjectTools: {
        EN: "Project",
        NO: "Prosjekt",
        SE: "Projekt",
      },
      CustomerTools: {
        EN: "Power Grid",
        NO: "Strømnett",
        SE: "Elnät",
      },
      GlobalTools: {
        EN: "Global",
        NO: "Global",
        SE: "Globala",
      },
      PoleStatus: {
        EN: "Structure Template Editor",
        NO: "Editor for strukturmaler",
        SE: "Konstruktions Mall Redigerare",
      },
      Reports: {
        EN: "Reports",
        NO: "Rapporter",
        SE: "Rapporter",
        Tooltip: {
          EN: "Download different reports",
          NO: "Last ned forskjellige rapporter",
          SE: "Nedladdning av olika rapporter",
        },
        ClickOnATabAbove: {
          EN: "Click on a tab above",
          NO: "Klikk på en fane ovenfor",
          SE: "Klicka på en flik ovanför",
        },
        AssetReport: {
          Download: {
            EN: "Download asset report",
            NO: "Last ned eiendelsrapport",
            SE: "Ladda ned tillgångsrapport",
          },
          EN: "Asset report",
          NO: "Eiendelsrapport",
          SE: "Tillgångsrapport",
          Description1: {
            EN: "An asset report will extract a report of all identified object properties. A property is added to a normal image annotation and is included in this report.",
            NO: "En aktivarapport vil trekke ut en rapport over alle identifiserte objektegenskaper. En egenskap legges til en vanlig bildekommentar og er inkludert i denne rapporten.",
            SE: "En tillgångsrapport kommer att extrahera en rapport över alla identifierade objektegenskaper. En egenskap läggs till en normal bildannotering och ingår i denna rapport.",
          },
          Description2: {
            EN: "The asset report gives you an extract of all data without aggregation over physical objects.",
            NO: "Eiendelsrapporten gir deg et utdrag av alle data uten aggregering over fysiske objekter.",
            SE: "Tillgångsrapporten ger dig ett utdrag av alla data utan aggregering över fysiska objekt.",
          },
          PoleReport: {
            EN: "Pole report",
            NO: "Mast-rapport",
            SE: "Stolpstatus rapport",
            Description1: {
              EN: "A pole report extracts all status reports of pole objects. Each status will have a component, placement and status associated with the row.",
              NO: "En mastrapport trekker ut alle statusrapporter for mastobjekter. Hver status vil ha en komponent, plassering og status knyttet til raden.",
              SE: "En stolpstatus rapport extraherar alla statusrapporter för stolpobjekt. Varje status kommer att ha en komponent, placering och status associerad med raden.",
            },
            Description2: {
              EN: "The pole report allows you to see a report of each physical component in your grid. This currently the most useful method to get a status report of your grid.",
              NO: "Mast-rapporten lar deg se en rapport for hver fysiske komponent i rutenettet ditt. Dette er for øyeblikket den mest nyttige metoden for å få en statusrapport for rutenettet ditt.",
              SE: "Stolpstatusrapporten låter dig se en rapport över varje fysisk komponent i ditt nät. Detta är för närvarande det mest användbara sättet att få en statusrapport över ditt nät.",
            },
          },
          FeedBayReport: {
            EN: "Pole ID report",
            NO: "Mast ID-rapport",
            SE: "Stolp ID rapport",
            Description1: {
              EN: "Use this report to get a complete list of all poles in your grid and check if the poles in the real world exist in your asset management system with the correct IDs. The pole IDs that were sent to Arkion in a KML file (or shapefile) can be reviewed here.",
              NO: "Bruk denne rapporten for å få en fullstendig liste over alle master i rutenettet ditt, og sjekk om polene i den virkelige verden finnes i forvaltningssystemet ditt med de riktige IDene. Mast-ID-ene som ble sendt til Arkion i en KML-fil (eller shapefile) kan gjennomgås her.",
              SE: "Använd denna rapport för att få en komplett lista över alla stolpar i ditt nät och kontrollera om stolparna i verkligheten finns i ditt NIS-system med rätt ID. De Stolp-ID som skickades till Arkion i en KML-fil (eller shapefil) kan granskas här.",
            },
            Description2: {
              EN: "Arkion can generate new poles from drone images and accurately give them a GPS location on the map. Use this report to get a spreadsheet of these poles and use the sheet to import ant create new assets in your asset mangement system.",
              NO: "Arkion kan generere nye master fra dronebilder og nøyaktig gi dem en GPS-posisjon på kartet. Bruk denne rapporten til å få et regneark over disse mastene, og bruk arket til å importere og opprette nye eiendeler i forvaltningssystemet ditt.",
              SE: "Arkion kan generera nya stolpar från drönarbilder och exakt ge dem en GPS-position på kartan. Använd denna rapport för att få ett kalkylblad över dessa stolpar och använd kalkylbladet för att importera och skapa nya tillgångar i ditt NIS-system.",
            },
            Description3: {
              EN: "Also use this report to check if there are poles that are missing one or two ID-nummers. Most customers have two ID numbers per pole, one number on the sign of the pole (a pole ID) and one unique database asset number (client asset ID). This report helps you to review this list.",
              NO: "Bruk også denne rapporten til å sjekke om det er master som mangler ett eller to ID-nummer. De fleste kunder har to ID-numre per mast, ett nummer på skiltet til masten (en mast ID) og ett unikt databaseaktivanummer (klientaktiva-ID). Denne rapporten hjelper deg med å se gjennom denne listen.",
              SE: "Använd också denna rapport för att kontrollera om det finns stolpar som saknar ett eller två ID-nummer. De flesta kunder har två ID-nummer per stolpe, ett nummer på stolpens skylt (ett stolp-ID) och ett unikt databas-ID-nummer (klient-ID). Denna rapport hjälper dig att granska denna lista.",
            },
          },
        },
      },
      Upload: {
        EN: "Upload",
        NO: "Last opp",
        SE: "Uppladdning",
        Tooltip: {
          EN: "Upload menu",
          NO: "Last opp meny",
          SE: "Uppladdnings meny",
        },
      },
      Download: {
        EN: "Download",
        NO: "Last ned",
        SE: " Nedladdning",
        Tooltip: {
          EN: "Download menu",
          NO: "Last ned meny",
          SE: "Nedladdnings meny",
        },
        ClickOnATabToDownload: {
          EN: "Click on a tab to download",
          NO: "Klikk på en fane for å laste ned",
          SE: "Klicka på en flik för att ladda ner",
        },
      },
      MapObjectsToggle: {
        NormalImage: {
          EN: "Normal image",
          NO: "Vanlig bilde",
          SE: "Normal bild",
        },
      },
      ManageAccess: {
        EN: "Manage Access",
        NO: "Administrer tilgang",
        SE: "Hantera Åtkomst",
        Users: {
          EN: "Users",
          NO: "Brukere",
          SE: "Användare",
          AddUser: {
            EN: "Add User",
            NO: "Legg til bruker",
            SE: "Lägg till användare",
            Name: {
              EN: "Name of new user",
              NO: "Navn på ny bruker",
              SE: "Namn på ny användare",
            },
            Email: {
              EN: "Email of new user",
              NO: "E-post til ny bruker",
              SE: "Epost på ny användare",
            },
            SendInvite: {
              EN: "Send Invite",
              NO: "Send invitasjon",
              SE: "Skicka inbjudan",
            },
          },
          NoGroups: {
            EN: "No customers",
            NO: "Ingen kunder",
            SE: "Inga kunder",
          },
          FullName: {
            EN: "Full Name",
            NO: "Fullt navn",
            SE: "Fullständigt namn",
          },
          NameMissing: {
            EN: "Name missing",
            NO: "Navn mangler",
            SE: "Namn saknas",
          },
          PhoneNumber: {
            EN: "Phone number",
            NO: "Telefonnummer",
            SE: "telefonnummer",
          },
        },
        Groups: {
          EN: "Customers",
          NO: "Kunder",
          SE: "Kunder",
          GroupBucket: {
            EN: "Customer Bucket",
            NO: "Kundebøtte",
            SE: "Kundhink",
          },
          UsingDefaultBucket: {
            EN: "Using default bucket",
            NO: "Bruker standard bøtte",
            SE: "Använder förinställd hink",
          },
          YourAccessLevel: {
            EN: "Your access level",
            NO: "Ditt tilgangsnivå",
            SE: "Din accessnivå",
          },
          Group: {
            EN: "Customer",
            NO: "Kunde",
            SE: "Kund",
          },
          NoGroup: {
            EN: "No customer",
            NO: "Ingen kunde",
            SE: "Ingen kund",
          },
        },
        Missions: {
          EN: "Projects",
          NO: "Prosjekter",
          SE: "Projekt",
          MissionName: {
            EN: "Project Name",
            NO: "Prosjektnavn",
            SE: "Projektsnamn",
          },
          StopEditing: {
            EN: "Stop editing",
            NO: "Slutt å redigere",
            SE: "Sluta redigera",
          },
          EditMission: {
            EN: "Edit project",
            NO: "Rediger prosjekt",
            SE: "Redigera projekt",
          },
          Delete: {
            EN: "Delete",
            NO: "Slett",
            SE: "Ta bort",
          },
        },
        SelectTabAbove: {
          EN: "Select tab above",
          NO: "Velg fanen ovenfor",
          SE: "Välj flik ovanför",
        },
        Search: {
          EN: "Search by email, name or phone number",
          NO: "Søk på e-post, navn eller telefonnummer",
          SE: "Sök på email, namn eller telefonnummer",
        },
        NoUsersFound: {
          EN: "No users found. Try changing the search text",
          NO: "Ingen brukere funnet. Prøv å endre søketeksten",
          SE: "Inga användare funna, prova att ändra söktexten",
        },
        NoMissionsFound: {
          EN: "No projects found. Try changing the search text",
          NO: "Ingen prosjekter funnet. Prøv å endre søketeksten",
          SE: "Inga projekt funna, prova att ändra söktexten",
        },
      },
      Objects: {
        EN: "Objects",
        NO: "Objekter",
        SE: "Objekt",
      },
      UserManager: {
        EN: "User Manager",
        NO: "Brukerbehandler",
        SE: "Hantera Användare",
      },
      MissionManager: {
        EN: "Project Manager",
        NO: "Prosjektleder",
        SE: "Hantera projekt",
      },
      CustomerManager: {
        EN: "Customer Manager",
        NO: "Kundeansvarlig",
        SE: "Hantera Kunder",
      },
      Changelog: {
        EN: "Changelog",
        NO: "Endringslogg",
        SE: "Ändringslogg",
      },
      BlurImages: {
        EN: "Blur sensitive data",
        NO: "Sløre sensitive data",
        SE: "Sudda ut känslig information",
        Tooltip: {
          EN: "Start batch job blurring images of faces, licence plates and the sky?",
          NO: "Starte batchjobb med å uskarpe bilder av ansikter, bilskilt og himmelen?",
          SE: "Starta ett arbete för att sudda ut ansikten, bilskyltar och himlen?",
        },
        Yes: {
          EN: "Yes",
          NO: "Ja",
          SE: "Ja",
        },
        No: {
          EN: "No",
          NO: "Ingen",
          SE: "Nej",
        },
      },
      ResetTutorial: {
        EN: "Reset Tutorial",
        NO: "Tilbakestill veiledning",
        SE: "Återställ genomgång",
      },
      SelectAMission: {
        EN: "Select a project to see available tools",
        NO: "Velg et prosjekt for å se tilgjengelige verktøy",
        SE: "Välj ett projekt för att se tillgängliga verktyg",
      },
      UsageReport: {
        EN: "Project info",
        NO: "Prosjektinformasjon",
        SE: "Projektsinformation",
        Usage: {
          EN: "Usage",
          NO: "Bruk",
          SE: "Användning",
        },
        Tooltip: {
          EN: "Get a report of how much resources each customer is using",
          NO: "Få en rapport over hvor mye ressurser hver kunde bruker",
          SE: "Hämta en rapport på mängden resurser varje kundgrupp använder",
        },
        GeneratingExcel: {
          EN: "Generating project info for Excel...",
          NO: "Genererer prosjektinformasjon for Excel...",
          SE: "Genererar projektinformation för Excel...",
        },
        GeneratingCSV: {
          EN: "Generating project info in CSV...",
          NO: "Genererer prosjektinformasjon i CSV...",
          SE: "Genererar projektinformation i CSV...",
        },
        Generated: {
          EN: "Report generated!",
          NO: "Rapport generert!",
          SE: "Rapport genererad!",
        },
      },
      WorkSessionReport: {
        EN: "Work sessions info",
        NO: "Informasjon om arbeidsøkter",
        SE: "Information om arbetssessioner",
        Title: {
          EN: "Work Sessions",
          NO: "Arbeidsøkter",
          SE: "Arbetssessioner",
        },
        Tooltip: {
          EN: "Get a report of all work sessions",
          NO: "Få rapport over alle arbeidsøktene",
          SE: "Hämta en rapport på alla arbetssessioner",
        },
        GeneratingExcel: {
          EN: "Generating work session info in Excel...",
          NO: "Genererer arbeidsøktinformasjon i Excel...",
          SE: "Genererar arbetssessioner info i Excel...",
        },
        GeneratingCSV: {
          EN: "Generating work session info in CSV...",
          NO: "Genererer arbeidsøktinformasjon i CSV...",
          SE: "Genererar arbetssessioner info i CSV...",
        },
        Generated: {
          EN: "Report generated!",
          NO: "Rapport generert!",
          SE: "Rapport genererad!",
        },
      },
      InventoryReport: {
        EN: "Object Report",
        NO: "Objektrapport",
        SE: "Objektrapport",
        Inventory: {
          EN: "Object",
          NO: "Gjenstand",
          SE: "Objekt",
        },
        Tooltip: {
          EN: "Get a report for the objects in the grid",
          NO: "Få en rapport for objektene i rutenettet",
          SE: "Hämta en objektrapport för elnätet",
        },
        Download: {
          EN: "Download object report",
          NO: "Last ned objektrapport",
          SE: "Ladda ned objektrapport",
        },
        Rows: {
          EN: "rows",
          NO: "rader",
          SE: "rader",
        },
        Types: {
          EN: "Types",
          NO: "Typer",
          SE: "Typer",
        },
        NoData: {
          EN: "Report had no data. Try changing the filters",
          NO: "Rapporten hadde ingen data. Prøv å bytte filtre",
          SE: "Rapporten hade ingen data. Försök byta filter",
        },
        GeneratingCSV: {
          EN: "Generating CSV file...",
          NO: "Genererer CSV-fil ...",
          SE: "Genererar CSV fil...",
        },
        GeneratingExcel: {
          EN: "Generating Excel file...",
          NO: "Genererer Excel-fil...",
          SE: "Genererar excelfil...",
        },
        ExcelGenerated: {
          EN: "Excel file generated!",
          NO: "Excel-fil generert!",
          SE: "Excelfil genererad!",
        },
        CSVGenerated: {
          EN: "CSV file generated!",
          NO: "CSV-fil generert!",
          SE: "CSV-fil genererad!",
        },
        ExcelFailed: {
          EN: "Excel generation failed.",
          NO: "Excel-generering mislyktes.",
          SE: "Generering av excelfil misslyckades",
        },
        CSVFailed: {
          EN: "CSV generation failed",
          NO: "CSV-generering mislyktes",
          SE: "Generering av CSV-fil misslyckades",
        },
      },
      VegetationReport: {
        EN: "Vegetation Report",
        NO: "Vegetasjonsrapport",
        SE: "Vegitationsrapport",
        Tooltip: {
          EN: "Get a vegetation report",
          NO: "Få en vegetasjonsrapport",
          SE: "Hämta en vegitationsrapport",
        },
      },
      // Defect report
      DefectReport: {
        EN: "Defect Report",
        NO: "Avviksrapport",
        SE: "Defektrapport",
        NewTool: {
          EN: "There is a new tool for creating defect reports. Click here to see the new tool",
          NO: "Det er et nytt verktøy for å lage avviksrapporter. Klikk her for å se det nye verktøyet",
          SE: "Vi har byggt ett nytt verktyg för att skapa defektrapporter. Klicka här för att se det nya verktyget",
        },
        Defect: {
          EN: "Defects",
          NO: "Avvik",
          SE: "Skador",
        },
        ImageReport: {
          EN: "Image",
          NO: "Bild",
          SE: "Bild",
        },
        Tooltip: {
          EN: "Downlad a report of all defects",
          NO: "Last ned en rapport over alle avvik",
          SE: "Ladda ned rapport av alla skador",
        },
        Generating: {
          EN: "Generating...",
          NO: "Genererer...",
          SE: "Genererar...",
        },
        Title: {
          EN: "Download defect report",
          NO: "Last ned avviksrapport",
          SE: "Ladda ned defektrapport",
        },
        SelectAll: {
          EN: "Select All",
          NO: "Velg alle",
          SE: "Välj alla",
        },
        OnePage: {
          EN: "One page per defect",
          NO: "En side per avvik",
          SE: "En sida per anmärkning",
        },
        DownloadOnePagePerDefectPDF: {
          EN: "Download one page per defect PDF",
          NO: "Last ned én side per avvik PDF",
          SE: "Ladda ned en sida per anmärkning PDF ",
        },
        HideFixed: {
          EN: "Hide fixed",
          NO: "Skjul fast",
          SE: "Dölj lagade skador",
        },
        ImageLists: {
          EN: "Image Lists",
          NO: "Bildelister",
          SE: "Bildlistor",
        },
        DefectTypes: {
          EN: "Defect types",
          NO: "Avvikstyper",
          SE: "Typ av Skada",
        },
        Severities: {
          EN: "Severities",
          NO: "Alvorligheter",
          SE: "Allvarlighetsgrad",
        },
        DownloadPDF: {
          EN: "Download PDF",
          NO: "Last ned PDF",
          SE: "Ladda ned PDF",
        },
        DownloadCSV: {
          EN: "Download CSV",
          NO: "Last ned CSV",
          SE: "Ladda ned CSV",
        },
        ExportKML: {
          EN: "Download KML",
          NO: "Last ned KML",
          SE: "Ladda ned KML",
        },
        DownloadExcel: {
          EN: "Download Excel",
          NO: "Last ned Excel",
          SE: "Ladda ned Excel",
        },
        ExportDP: {
          EN: "Export to dpPower (deprecated)",
          NO: "Eksporter til dpPower (avviklet)",
          SE: "Exportera till dpPower (gammal)",
        },
        ExportDPCSV: {
          EN: "Export to dpPower as CSV",
          NO: "Eksporter til dpPower som CSV",
          SE: "Exportera till dpPower som CSV",
        },
        ExportDPExcel: {
          EN: "Export to dpPower as Excel",
          NO: "Eksporter til dpPower som Excel",
          SE: "Exportera till dpPower som Excel",
        },
        ExportDPLock: {
          EN: "Export to dpPower and lock objects",
          NO: "Eksporter til dpPower og lås objekter",
          SE: "Exportera till dpPower och lås objekt",
        },
        ExportIBM: {
          EN: "Export to IBM Maximo",
          NO: "Eksporter til IBM Maximo",
          SE: "Exportera till IBM Maximo",
        },
        ExportSAP: {
          EN: "Export to SAP",
          NO: "Eksporter til SAP",
          SE: "Exportera till SAP",
        },
        Objects: {
          EN: "objects",
          NO: "gjenstander",
          SE: "objekt",
        },
        Images: {
          EN: "Images",
          NO: "Bilder",
          SE: "Bilder",
        },
        Poles: {
          EN: "Poles",
          NO: "polakker",
          SE: "Stolpar",
        },
      },
      AssetReport: {
        EN: "Assets",
        NO: "Eiendeler",
        SE: "Anläggningrapport",
        Title: {
          EN: "Download an asset report",
          NO: "Last ned en eiendelsrapport",
          SE: "Ladda ned anläggningsrapport",
        },
        Description: {
          EN: "An asset report allows you to identify all properties regarding each pole on the current project. This can be used to find which towers have been damaged the most by rust, or which towers are tilting the most.",
          NO: "En aktivarapport lar deg identifisere alle egenskapene for hver pol på det aktuelle prosjektet. Denne kan brukes til å finne hvilke tårn som har blitt mest skadet av rust, eller hvilke tårn som vipper mest.",
          SE: "En anläggningsrapport låter dig identifiera alla egenskaper angående varje stolpe på nuvarand projekt. Detta låter dig hitta vilka stolpar som har skadats mest av rost, eller vilka stolpar som lutar mest.",
        },
      },
      UploadZones: {
        EN: "Upload zones",
        NO: "Last opp soner",
        SE: "Ladda upp zoner",
        Description: {
          EN: "Upload a KML file with zone data to current map. ",
          NO: "Last opp en KML-fil med sonedata til gjeldende kart.",
          SE: "Ladda upp en KML-fil med Zon-data till nuvaranda projekt. ",
        },
        Support: {
          EN: "We currently only support MultiPolygons.",
          NO: "Vi støtter for øyeblikket bare MultiPolygons.",
          SE: "Vi stöder för tillfället bara MultiPolygons",
        },
        ChooseFile: {
          EN: "Choose File",
          NO: "Velg Fil",
          SE: "Välj Fil",
        },
        UploadZoneKml: {
          EN: "Upload Zone KML",
          NO: "Last opp sone KML",
          SE: "Ladda upp zon-KML",
        },
      },

      // Image Clusters
      ImageClusters: {
        EN: "Image Clusters",
        NO: "Bildeklynger",
        SE: "Bild kluster",
        Clusters: {
          EN: "Clusters",
          NO: "Klynger",
          SE: "Kluster",
        },
        Tooltip: {
          EN: "Manage image cluster data",
          NO: "Administrer bildeklyngedata",
          SE: "Hantera klusterdata för bilder",
        },
        Download: {
          EN: "Download",
          NO: "Last ned",
          SE: "Nedladdning",
          Title: {
            EN: "Download cluster data",
            NO: "Last ned klyngedata",
            SE: "Ladda ned klusterdata",
          },
          Description: {
            EN: "Download data of all clusters connected to this project. This data groups together images that are nearby and allows the user to view them as a cluster.",
            NO: "Last ned data for alle klynger knyttet til dette prosjektet. Disse dataene grupperer bilder som er i nærheten og lar brukeren se dem som en klynge.",
            SE: "Ladda ned data för alla kluster kopplade till detta projekt. Denna data gruppar ihop bilder som är i närheten av varandra och låter användaren se dem som ett kluster.",
          },
          Button: {
            EN: "Download",
            NO: "Last ned",
            SE: "Ladda ned",
          },
        },
        Upload: {
          EN: "Upload",
          NO: "Last opp",
          SE: "Uppladdning",
          ClickOnATabToUpload: {
            EN: "Click on a tab to upload",
            NO: "Klikk på en fane for å laste opp",
            SE: "Klicka på en flik för att göra en uppladdning",
          },
          Title: {
            EN: "Upload cluster data",
            NO: "Last opp klyngedata",
            SE: "Ladda upp klusterdata",
          },
          Description: {
            EN: "Upload data of all clusters connected to this project. This data groups together images that are nearby and allows the user to view them as a cluster.",
            NO: "Last opp data for alle klynger knyttet til dette prosjektet. Disse dataene grupperer bilder som er i nærheten og lar brukeren se dem som en klynge.",
            SE: "Ladda upp data för alla kluster kopplade till detta projekt. Denna data gruppar ihop bilder som är i närheten av varandra och låter användaren se dem som ett kluster.",
          },
          Button: {
            EN: "Upload",
            NO: "Last opp",
            SE: "Ladda upp",
          },
          ChooseFile: {
            EN: "Choose File",
            NO: "Velg Fil",
            SE: "Välj fil",
          },
          Uploading: {
            EN: "Uploading data",
            NO: "Laster opp data",
            SE: "Laddar upp data",
          },
          Uploaded: {
            EN: "Upload successful. Reload page to see changes.",
            NO: "Opplastingen var vellykket. Last inn siden på nytt for å se endringer.",
            SE: "Uppladdning lyckades. Ladda om sidan för att se ändringar",
          },
          UploadFailed: {
            EN: "Upload failed",
            NO: "Opplasting mislyktes",
            SE: "Uppladdning misslyckades",
          },
          TooLarge: {
            EN: "is too large, please pick a smaller file",
            NO: "er for stor, velg en mindre fil",
            SE: "är för stor, var god välj en mindre fil",
          },
        },
      },
      // Image Objects
      ImageObjects: {
        EN: "Image Objects",
        NO: "Bildeobjekter",
        SE: "Bildobjekt",
        Tooltip: {
          EN: "Synchronize annotation data",
          NO: "Synkroniser merknadsdata",
          SE: "Synkronisera annoteringsdata",
        },
        // Download
        Download: {
          EN: "Download",
          NO: "Last ned",
          SE: "Nedladdning",
          Title: {
            EN: "Download image objects",
            NO: "Last ned bildeobjekter",
            SE: "Ladda ned bildobjekt",
          },
          Description: {
            EN: "Download Human Annotation and ML Detections as a JSON file. Objects are downloaded per flight. The number of objects per flight is show within the (braces).",
            NO: "Last ned Human Annotation and ML Detections som en JSON-fil. Objekter lastes ned per flytur. Antall objekter per flytur vises innenfor (klommerne).",
            SE: "Ladda ned mänskliga annoteringar och maskindetekterade objekt som en JSON fil. Bildobjekt är nedladdare för varje flygning. Antal objekt per flygning visas med en (parantes).",
          },
          Human: {
            EN: "Human",
            NO: "Menneskelig",
            SE: "Människa",
          },
          ML: {
            EN: "Arkion ML",
            NO: "Arkion ML",
            SE: "Arkion ML",
          },
          AllFlights: {
            EN: "All flights",
            NO: "Alle flyreiser",
            SE: "Alla flygningar",
          },
          Flight: {
            EN: "Flight",
            NO: "Flyvning",
            SE: "Flygning",
          },
          ObjectsAvailable: {
            EN: "Objects available for download.",
            NO: "Objekter tilgjengelig for nedlasting.",
            SE: "Objekt tillgängliga för nedladdning.",
          },
          NoObjects: {
            EN: "No Objects to download",
            NO: "Ingen objekter å laste ned",
            SE: "Inga object att ladda ner",
          },
          DownloadFailed: {
            EN: "Download failed",
            NO: "Nedlasting mislyktes",
            SE: "Nedladdning misslyckades",
          },
          PreparingHuman: {
            EN: "Preparing Human Annotations for download",
            NO: "Forbereder menneskelige merknader for nedlasting",
            SE: "Förbereder objekt av människor för nedladdning",
          },
          PreparingML: {
            EN: "Preparing ML Object Files",
            NO: "Klargjøring av ML-objektfiler",
            SE: "Förbereder objekt från Maskininlärning för nedladdning",
          },
        },
        // Upload
        Upload: {
          EN: "Upload",
          NO: "Last opp",
          SE: "Uppladdning",
          Title: {
            EN: "Upload image objects",
            NO: "Last opp bildeobjekter",
            SE: "Ladda upp bildobjekt",
          },
          Description: {
            EN: "Upload a JSON file with image objects.",
            NO: "Last opp en JSON-fil med bildeobjekter.",
            SE: "Ladda upp en JSON fil med bildobjekt.",
          },
          SelectUser: {
            EN: "Select user",
            NO: "Velg bruker",
            SE: "Välj användare",
            FromFile: {
              EN: "User from file",
              NO: "Bruker fra fil",
              SE: "Användare från fil",
            },
            Human: {
              EN: "Human Input - Arkion Demo",
              NO: "Menneskelig input - Arkion Demo",
              SE: "Människa - Arkion Demo",
            },
            Vattenfall: {
              EN: "Vattenfall human user import",
              NO: "Vattenfall import av menneskelige brukere",
              SE: "Vattenfall människa import",
            },
          },
          Upload: {
            EN: "Upload",
            NO: "Last opp",
            SE: "Ladda upp",
          },
          ChooseFile: {
            EN: "Choose File",
            NO: "Velg Fil",
            SE: "Välj Fil",
          },
        },
      },
      ImageUpload: {
        EN: "Image Upload",
        NO: "Bildeopplasting",
        SE: "Bilduppladdning",
        Tooltip: {
          EN: "Import images to this project",
          NO: "Importer bilder til dette prosjektet",
          SE: "Importera bilder till detta projekt",
        },
        Images: {
          EN: "Images",
          NO: "Bilder",
          SE: "Bilder",
        },
        Upload: {
          EN: "Upload",
          NO: "Last opp",
          SE: "Ladda upp",
          Tooltip: {
            EN: "Upload images to cloud provider",
            NO: "Last opp bilder til skyleverandøren",
            SE: "Ladda upp bilder till molnleverantör",
          },
          InfoText: {
            EN: "The selected folder for uploads should be the date when the images were taken. It should not be the date of the upload.",
            NO: "Den valgte mappen for opplastinger skal være datoen da bildene ble tatt. Det skal ikke være datoen for opplastingen.",
            SE: "Den valda mappen för uppladdning borde vara datumet bilden blev tagen. Det borde inte vara datumet för uppladdning.",
          },
          DragAndDrop: {
            EN: "Drag and drop files and folders here or click below to select folders or images.",
            NO: "Dra og slipp filer og mapper her eller klikk nedenfor for å velge mapper eller bilder.",
            SE: "Släpp flera mappar och filer här, eller klicka nedanför för att välja en mapp eller bilder.",
          },
          DropHere: {
            EN: "Drop the folder here",
            NO: "Slipp mappen her",
            SE: "Släpp mappen här",
          },
          StartAutomatically: {
            EN: "Start import automatically after upload",
            NO: "Start import automatisk etter opplasting",
            SE: "Starta import automatiskt efter uppladdning",
          },
          OfflineStatus: {
            EN: "You are offline",
            NO: "Du er frakoblet",
            SE: "Du är offline",
          },
          ContinueUpload: {
            EN: "Continue Upload",
            NO: "Fortsett opplasting",
            SE: "Fortsätt uppladdning",
          },
          ImportStarted: {
            EN: "Import started",
            NO: "Importen startet",
            SE: "Import påbörjad",
          },
          UploadFinished: {
            EN: "Upload finished",
            NO: "Opplasting fullført",
            SE: "Uppladdning klar",
          },
          NewUpload: {
            EN: "Reset",
            NO: "Tilbakestill",
            SE: "Rensa",
          },
          StopUpload: {
            EN: "Stop upload",
            NO: "Stopp opplastingen",
            SE: "Stoppa uppladdning",
          },
          Images: {
            EN: "Images",
            NO: "Bilder",
            SE: "Bilder",
          },
          SaveInNewFolder: {
            EN: "Save in new folder",
            NO: "Lagre i ny mappe",
            SE: "Spara i ny mapp",
          },
          SaveInExistingFolder: {
            EN: "Save in existing folder",
            NO: "Lagre i eksisterende mappe",
            SE: "Spara i befintlig mapp",
          },
          NoFoldersFound: {
            EN: "No folders found",
            NO: "Ingen mapper funnet",
            SE: "Inga mappar hittades",
          },
        },
        Import: {
          EN: "Import",
          NO: "Import",
          SE: "Importera",
          Tooltip: {
            EN: "Import images from cloud provider to map",
            NO: "Importer bilder fra skyleverandør til kart",
            SE: "Importera bilder from molnleverantör till kartan",
          },
          Home: {
            EN: "Home",
            NO: "Hjem",
            SE: "Hem",
          },
          FoldersFound: {
            EN: "Folders Found",
            NO: "Mapper funnet",
            SE: "Mappar hittade",
          },
          Rerun: {
            EN: "Rerun",
            NO: "Kjør på nytt",
            SE: "Kör igen",
            Tooltip: {
              EN: "Ignore import.json",
              NO: "Ignorer import.json",
              SE: "Ignorera import.json",
            },
          },
          Yolo: {
            EN: "Enable queue",
            NO: "Aktiver kø",
            SE: "Aktivera kö",
            Tooltip: {
              EN: "Start YOLO after import",
              NO: "Start YOLO etter import",
              SE: "Starta YOLO efter import",
            },
          },
          OverwriteDB: {
            EN: "Overwrite DB",
            NO: "Overskriv DB",
            SE: "Skriv över DB",
            Tooltip: {
              EN: "overwrites 'is_thermal', 'lat', 'lng', 'compass_dir', 'location' and 'flight_id'",
              NO: "overskriver 'is_thermal', 'lat', 'lng', 'compass_dir', 'location' og 'flight_id'",
              SE: "Skriver över 'is_thermal', 'lat', 'lng', 'compass_dir', 'location' och 'flight_id'",
            },
          },
          CreateJob: {
            EN: "New job",
            NO: "Ny jobb",
            SE: "Skapa nytt jobb",
          },
          Images: {
            EN: "Images",
            NO: "Bilder",
            SE: "Bilder",
          },
          Created: {
            EN: "Created",
            NO: "Opprettet",
            SE: "Skapat",
          },
          Folder: {
            EN: "Folder",
            NO: "Mappe",
            SE: "Mapp",
          },
          Status: {
            EN: "Status",
            NO: "Status",
            SE: "Status",
          },
          Skipped: {
            EN: "Skipped",
            NO: "Hoppet over",
            SE: "Skippade",
          },
          Logs: {
            EN: "Logs",
            NO: "Logger",
            SE: "Loggar",
          },
          SeeLogs: {
            EN: "See log",
            NO: "Se logg",
            SE: "Se loggar",
          },
          Aborted: {
            EN: "Aborted",
            NO: "Avbrutt",
            SE: "Avbruten",
          },
          Successful: {
            EN: "Successful",
            NO: "Vellykket",
            SE: "Lyckades",
          },
          Running: {
            EN: "Running",
            NO: "Løper",
            SE: "Pågående",
          },
          PartiallySuccessful: {
            EN: "Partially Successful",
            NO: "Delvis vellykket",
            SE: "Lyckades delvis",
          },
          Job: {
            EN: "Job",
            NO: "Jobb",
            SE: "Jobb",
          },
          Finished: {
            EN: "finished",
            NO: "ferdig",
            SE: "klart",
          },
          CloudDuplicate: {
            EN: "Cloud Duplicates",
            NO: "Cloud Duplicates",
            SE: "Dubletter i moln",
          },
          DatabaseDuplicate: {
            EN: "Database Duplicates",
            NO: "Database duplikater",
            SE: "Dubletter i database",
          },
        },
      },
      MapTool: {
        EN: "Map Tool",
        NO: "Kartverktøy",
        SE: "Kartverktyg",
        Disabled: {
          EN: "Disabled",
          NO: "Funksjonshemmet",
          SE: "Inaktiverad",
        },
        AddPowerline: {
          EN: "Add powerline",
          NO: "Legg til strømledning",
          SE: "Skapa ledning",
        },
        AddZone: {
          EN: "Add zone",
          NO: "Legg til sone",
          SE: "Skapa zon",
        },
      },
      // Powerlines
      Powerlines: {
        EN: "Powerlines",
        NO: "Kraftledninger",
        SE: "Ledningar",
        Tooltip: {
          EN: "Powerline tools",
          NO: "Powerline verktøy",
          SE: "Ledningsverktyg",
        },
        ClickOnATabAbove: {
          EN: "Click on a tab above",
          NO: "Klikk på en fane ovenfor",
          SE: "Klicka på en flik ovanför",
        },
        Poles: {
          EN: "Poles",
          NO: "Master",
          SE: "Stolpar",
          Title: {
            EN: "Manage Poles",
            NO: "Administrer Master",
            SE: "Hantera Stolpar",
          },
          Description: {
            EN: "Assign images to poles, or generate poles from clusters",
            NO: "Tilordne bilder til master, eller generer master fra klynger",
            SE: "Tilldela bilder till stolpar, eller generera stolpar från kluster.",
          },
          PoleGenerator: {
            EN: "Pole Generator",
            NO: "Mast generator",
            SE: "Stolp Generator",
          },
          AssignImagesToExistingPoles: {
            EN: "Assign Images To existing Poles",
            NO: "Tilordne bilder til eksisterende master",
            SE: "Tilldela bilder till nuvarande stolpar",
          },
        },
        Zones: {
          EN: "Zones",
          NO: "Soner",
          SE: "Zoner",
          Menu: {
            Lidar: {
              EN: "Open in LIDAR View",
              NO: "Åpne i LIDAR View",
              SE: "Öppna i LIDAR visaren",
            },
            Link: {
              EN: "Copy Zone Link",
              NO: "Kopier sonekobling",
              SE: "Kopiera Zon-länken",
            },
          },
        },
        Bluify: {
          EN: "Bluify",
          NO: "blåfarge",
          SE: "Blåifiera",
          Title: {
            EN: "Bluify powerlines",
            NO: "blåfarge kraftledninger",
            SE: "Blåifiera ledningar",
          },
          Description: {
            EN: "All powerlines with images close will turn blue.",
            NO: "Alle kraftledninger med bilder nær vil bli blå.",
            SE: "Alla ledningar på kartan med bilder i närheten kommer bli blåa.",
          },
          Button: {
            EN: "Bluify powerlines",
            NO: "blåfarge kraftledninger",
            SE: "Blåifiera ledningar",
          },
          MakeBlue: {
            EN: "Making powerlines blue...",
            NO: "Gjør kraftledninger blå...",
            SE: "Gör ledningar blåa...",
          },
          MakeBlueCompleted: {
            EN: "Powerlines are now blue. Reload page to see changes",
            NO: "Kraftledninger er nå blå. Last inn siden på nytt for å se endringer",
            SE: "Ledningar är nu blåa. Ladda om för att se ändringarna",
          },
        },
        Divide: {
          EN: "Divide",
          NO: "Dele",
          SE: "Dela upp",
          Title: {
            EN: "Divide up powerlines",
            NO: "Del opp kraftledninger",
            SE: "Dela upp ledningar",
          },
          Description: {
            EN: "Divide all powerlines longer than 200m into many smaller powerlines",
            NO: "Del opp alle kraftledninger lengre enn 200m i mange mindre kraftledninger",
            SE: "Dela upp alla ledningar längre än 200m till flera mindre ledningar.",
          },
          Button: {
            EN: "Divide powerlines",
            NO: "Del opp kraftledninger",
            SE: "Dela upp ledningar",
          },
          Dividing: {
            EN: "Dividing powerlines job started",
            NO: "Oppdeling av kraftledninger startet",
            SE: "Uppdelning av ledningar har startat",
          },
          DividingComplete: {
            EN: "Division is completed. Please reload page",
            NO: "Delingen er fullført. Last inn siden på nytt",
            SE: "Uppdelning av ledningar är klart.",
          },
        },
        Upload: {
          EN: "Upload",
          NO: "Last opp",
          SE: "Uppladdning",
          Title: {
            EN: "Upload powerline",
            NO: "Last opp kraftledning",
            SE: "Ladda upp ledning",
          },
          Description: {
            EN: "Upload powerline data and load the powerlines into the current map.",
            NO: "Last opp kraftledningsdata og last inn strømledningene inn i det gjeldende kartet.",
            SE: "Ladda upp data för ledningar och ladda in i nuvarande kartan.",
          },
          Button: {
            EN: "Upload powerline",
            NO: "Last opp kraftledninger",
            SE: "Ladda upp ledningar",
          },
          ChooseFile: {
            EN: "Choose File",
            NO: "Velg Fil",
            SE: "Välj Fil",
          },
          UploadSucceeded: {
            EN: "Upload succeded. Reload page to see changes.",
            NO: "Opplastingen var vellykket. Last inn siden på nytt for å se endringer.",
            SE: "Uppladdning lyckades. Ladda om sida för att se ändringar.",
          },
          Uploading: {
            EN: "Uploading File",
            NO: "Laster opp fil",
            SE: "Laddar upp fil",
          },
          Disclaimer: {
            EN: "We currently have support for KML and Shape files (zip)",
            NO: "Vi har for øyeblikket støtte for KML- og Shape-filer (zip)",
            SE: "Vi stödjer för KML och Shapefiler (zip)",
          },
          TooLarge: {
            EN: "is too large, please pick a smaller file",
            NO: "er for stor, velg en mindre fil",
            SE: "är för stor, var god välj en mindre fil",
          },
        },
        Download: {
          EN: "Download KML",
          NO: "Last ned KML",
          SE: "Ladda ned KML",
          Title: {
            EN: "Download KML",
            NO: "Last ned KML",
            SE: "Ladda ned KML",
          },
          Description: {
            EN: "Download all powerlines as a .kml file.",
            NO: "Last ned alle kraftledninger som en .kml-fil.",
            SE: "Ladda ned alla ledningar som en .kml fil.",
          },
          Button: {
            EN: "Download KML",
            NO: "Last ned KML",
            SE: "Ladda ned KML",
          },
          ButtonReflight: {
            EN: "Download Reflight KML",
            NO: "Last ned Reflight KML",
            SE: "Ladda ned KML för omflygning",
          },
        },
      },
      // User Admin
      UserAdmin: {
        EN: "User admin",
        NO: "Bruker admin",
        SE: "Användaradmin",
        Tooltip: {
          EN: "Add users and edit permissions",
          NO: "Legg til brukere og rediger tillatelser",
          SE: "Lägg till användare och justera behörigheter",
        },
        GroupName: {
          EN: "Customer Name",
          NO: "Kundens navn",
          SE: "Kundnamn",
        },
        AssignedUsers: {
          EN: "Assigned users",
          NO: "Tilordnede brukere",
          SE: "Användare tilldelade",
        },
        AssignedMissions: {
          EN: "Assigned projects",
          NO: "Tildelte prosjekter",
          SE: "projekt tilldelade",
        },
        UserPermissions: {
          EN: "User permissions",
          NO: "Brukertillatelser",
          SE: "Användarbehörigheter",
        },
        CloudPostfix: {
          EN: "Cloud Postfix",
          NO: "Cloud Postfix",
          SE: "Molntjänst postfix",
        },
        SkyqraftInspectors: {
          EN: "Arkion Inspectors",
          NO: "Arkion Inspektører",
          SE: "Arkion Inspektörer",
        },
        NameTooShort: {
          EN: "Name too short",
          NO: "Navnet er for kort",
          SE: "Namnet är för kort",
        },
        AddGroup: {
          EN: "Add customer",
          NO: "Legg til kunde",
          SE: "Skapa kund",
        },
        AddUser: {
          EN: "Add user",
          NO: "Legg til bruker",
          SE: "Skapa användare",
        },
        Users: {
          EN: "Users",
          NO: "Brukere",
          SE: "Användare",
        },
        Missions: {
          EN: "Projects",
          NO: "Prosjekter",
          SE: "projekt",
        },
        Permissions: {
          EN: "Permissions",
          NO: "Tillatelser",
          SE: "Behörigheter",
        },
        Password: {
          EN: "Password",
          NO: "Passord",
          SE: "Lösenord",
        },
        TemporaryPassword: {
          EN: "Temporary Password",
          NO: "Midlertidig passord",
          SE: "Temporärt Lösenord",
        },
        AddUserFailed: {
          EN: "Invite failed",
          NO: "Invitasjonen mislyktes",
          SE: "Inbjudan misslyckades",
        },
        AddUserSuccess: {
          EN: "Invite sent",
          NO: "Invitasjon sendt",
          SE: "Inbjudan skickad",
        },
        AddGroupFailed: {
          EN: "Adding customer failed",
          NO: "Kunne ikke legge til kunde",
          SE: "Skapa kund misslyckades",
        },
        AddGroupSuccess: {
          EN: "Adding customer succeeded",
          NO: "Å legge til kunde lyktes",
          SE: "Skapa kund lyckades",
        },
        Saving: {
          EN: "Saving settings",
          NO: "Lagrer innstillinger",
          SE: "Sparar inställningar",
        },
        Saved: {
          EN: "Saved settings",
          NO: "Lagrede innstillinger",
          SE: "Inställningar sparade",
        },
        PartialSave: {
          EN: "Update partially failed.",
          NO: "Oppdatering mislyktes delvis.",
          SE: "Inställningar sparades delvis",
        },
      },
      // Tutorials
      Tutorials: {
        EN: "Tutorials",
        NO: "Veiledninger",
        SE: "Handledningar",
        Tooltip: {
          EN: "Edit tutorial for users",
          NO: "Rediger veiledning for brukere",
          SE: "Ändra handledningar för användare",
        },
        Undo: {
          EN: "Undo Changes",
          NO: "Angre endringer",
          SE: "Ångra ändringar",
        },
        Save: {
          EN: "Save",
          NO: "Spare",
          SE: "Spara",
        },
        Close: {
          EN: "Close",
          NO: "Lukke",
          SE: "Stäng",
        },
        AddNew: {
          EN: "Add new item",
          NO: "Legg til nytt element",
          SE: "Lägg till nytt steg",
        },
      },
      ObjectManager: {
        EN: "Object manager",
        NO: "Objektbehandler",
        SE: "Objekthanteraren",
        Tooltip: {
          EN: "Edit object types and categories",
          NO: "Rediger objekttyper og kategorier",
          SE: "Ändra objekttyper och kategorier",
        },
      },
      Broadcast: {
        EN: "Broadcast message",
        NO: "Kringkast melding",
        SE: "Sänd meddelande",
        Warning: {
          EN: "This will be broadcasted to all active users. Are you sure you want to do this?",
          NO: "Dette vil bli kringkastet til alle aktive brukere. Er du sikker på at du vil gjøre dette?",
          SE: "Detta meddelande kommer skickas till alla användare. Är du säker att du vill göra detta?",
        },
      },
    },
    // Statistics
    Statistics: {
      EN: "Statistics",
      NO: "Statistikk",
      SE: "Statistik",
      Tooltip: {
        EN: "See some stats of the current project",
        NO: "Se litt statistikk for det nåværende prosjektet",
        SE: "Se statistik för nuvarande projekt",
      },
      nImages: {
        EN: "Image count",
        NO: "Antall bilder",
        SE: "Antal bilder",
      },
      lidarLength: {
        EN: "Grid with lidar",
        NO: "Gitter med lidar",
        SE: "Nät med lidar",
      },
      nPoles: {
        EN: "Pole count",
        NO: "masttelling",
        SE: "Antal stolpar",
      },
      nSupervisorReviewImages: {
        EN: "Images to superannotate",
        NO: "Bilder som skal superannoteres",
        SE: "Bilder att superannotera",
      },
      nMachineReviewImages: {
        EN: "Images to review",
        NO: "Bilder til vurdering",
        SE: "Bilder att annotera",
      },
      nFlaggedImages: {
        EN: "Flagged images",
        NO: "Flaggede bilder",
        SE: "Flaggade bilder",
      },
      // Powergrid
      Powergrid: {
        EN: "Powerline",
        NO: "Kraftnett",
        SE: "Kraftnät",
        Length: {
          EN: "Grid length",
          NO: "Rutenettlengde",
          SE: "Nätlängd",
        },
        Images: {
          EN: "Grid with images",
          NO: "Nett med bilder",
          SE: "Nät med bilder",
        },
        Inspected: {
          EN: "Analyzed",
          NO: "Analysert",
          SE: "Analyserat",
        },
        Reflight: {
          EN: "Reflight needed",
          NO: "Reflight nødvendig",
          SE: "Omflygning krävs",
        },
        Helicopter: {
          EN: "Helicopter emissions",
          NO: "Helikopterutslipp",
          SE: "Helikopter utsläpp",
        },
        Drone: {
          EN: "Drone emissions",
          NO: "Droneutslipp",
          SE: "Drönare utsläpp",
        },
        CO2: {
          EN: "CO2 saved",
          NO: "CO2 spart",
          SE: "Koldioxid sparad",
        },
      },
      MachineLearning: {
        EN: "Machine Learning",
        NO: "Maskinlæring",
        SE: "Maskininlärning",
        WorkflowStatus: {
          EN: "Workflow Status",
          NO: "Arbeidsflytstatus",
          SE: "Arbetsflöde Status",
        },
        ShowWithInventory: {
          EN: "Show With objects",
          NO: "Vis med objekter",
          SE: "Visa med objekt",
        },
        DetectionCategories: {
          EN: "Detection Categories",
          NO: "Deteksjonskategorier",
          SE: "Objekt Kategorier",
        },
        IssueCategories: {
          EN: "Issue Categories",
          NO: "Avvikskategorier",
          SE: "Skada Kategorier",
        },
        FixedDefects: {
          EN: "Fixed Defects",
          NO: "Faste avvik",
          SE: "Reparerade Objekt",
        },
        NotFixed: {
          EN: "Not Fixed",
          NO: "Ikke løst",
          SE: "Ej Reparerat",
        },
        Fixed: {
          EN: "Fixed",
          NO: "Fikset",
          SE: "Reparerat",
        },
        Detections: {
          EN: "All Detections",
          NO: "Alle deteksjoner",
          SE: "Alla Objekt",
        },
        Defect: {
          EN: "Defect",
          NO: "Avvik",
          SE: "Skador",
        },
        Inventory: {
          EN: "Objects",
          NO: "Objekter",
          SE: "Objekt",
        },
        Human: {
          EN: "Human",
          NO: "Menneskelig",
          SE: "Människa",
        },
        Machine: {
          EN: "Machine",
          NO: "Maskin",
          SE: "Maskin",
        },
        Updated: {
          EN: "last updated",
          NO: "sist oppdatert",
          SE: "senast uppdaterad",
        },
        Accuracy: {
          EN: "Accuracy",
          NO: "Nøyaktighet",
          SE: "Nogrannhet",
        },
        Precision: {
          EN: "Precision",
          NO: "Presisjon",
          SE: "Precision",
        },
        Recall: {
          EN: "Recall",
          NO: "Minnes",
          SE: "Återkallelse",
        },
        WorkReduction: {
          EN: "Work reduction",
          NO: "Arbeidsreduksjon",
          SE: "Arbetsminskning",
        },
        Positive: {
          EN: "Positive",
          NO: "Positivt",
          SE: "Positiv",
        },
        Negative: {
          EN: "Negative",
          NO: "Negativ",
          SE: "Negativ",
        },
        True: {
          EN: "True",
          NO: "ekte",
          SE: "Sann",
        },
        False: {
          EN: "False",
          NO: "falsk",
          SE: "Falsk",
        },
        MachineDetectionCategories: {
          EN: "Machine Detection Categories",
          NO: "Maskindeteksjonskategorier",
          SE: "Maskin Detekterings kategorier",
        },
      },
    },
    // Settings
    Settings: {
      EN: "Settings",
      NO: "Innstillinger",
      SE: "Inställningar",
      Tooltip: {
        EN: "Edit settings for a more customised experience",
        NO: "Rediger innstillingene for en mer tilpasset opplevelse",
        SE: "Justera inställningar för en mer anpassad upplevelse",
      },
      ZoomSpeed: {
        EN: "Zoom speed for images",
        NO: "Zoomhastighet for bilder",
        SE: "Zoom-hastighet för bilder",
      },
      ClusterDistance: {
        EN: "Proximity radius",
        NO: "Nærhetsradius",
        SE: "Cirkelns radie",
      },
      RoleOverride: {
        EN: "Role Override",
        NO: "Rolleoverstyring",
        SE: "Rollöverskridning",
      },
      ClusterMethod: {
        EN: "Thumbnail method",
        NO: "Miniatyrbildemetode",
        SE: "Ikon-logik",
      },
      ThumbnailSorting: {
        EN: "Thumbnail sorting",
        NO: "Sortering av miniatyrbilder",
        SE: "Ikon-sortering",
      },
      ShowDatesBeforeProjectName: {
        EN: "Show dates before project name",
        NO: "Vis datoer før prosjektnavn",
        SE: "Visa datum före projektnamn",
      },
      Proximity: {
        EN: "Proximity",
        NO: "Nærhet",
        SE: "Närliggande",
      },
      Cluster: {
        EN: "Show thumbnail images",
        NO: "Vis miniatyrbilder",
        SE: "Visa ikon-bilder",
      },
      Language: {
        EN: "Language",
        NO: "Språk",
        SE: "Språk",
      },
      ClientNaming: {
        EN: "Client Naming",
        NO: "Kundenavn",
        SE: "Klient Namngivning",
      },
      ClientSettings: {
        EN: "Client Settings",
        NO: "Klientinnstillinger",
        SE: "Klient Inställningar",
      },
      CustomizeDefectTypes: {
        EN: "Customize defect types",
        NO: "Tilpass avvikstyper",
        SE: "Anpassa skadekategorier",
      },
      EditClientIssueSeverities: {
        EN: "Edit client issue severities",
        NO: "Rediger klientproblemets alvorlighetsgrad",
        SE: "Redigera klient Anmärknings allvarlighetsgrad",
      },
      NamingConvention: {
        EN: "Naming convention for objects, defects and severity levels",
        NO: "Navnekonvensjon for objekter, avvik og alvorlighetsnivåer",
        SE: "Namngivningstyp för objekt, defekter och allvarlighetsgrader",
      },
      Map: {
        EN: "Map",
        NO: "Kart",
        SE: "Karta",
      },
      InspectorMode: {
        EN: "Inspector mode",
        NO: "Inspektørmodus",
        SE: "Inspektörsläge",
        Tooltip: {
          EN: "Mark analyzed powerlines green/yellow/blue using inspector mode. Use right mouse button and mark an area.",
          NO: "Merk analyserte kraftledninger grønt/gult/blått ved hjelp av inspektørmodus. Bruk høyre museknapp og merk et område.",
          SE: "Markera analyserade ledningar i inspektörsläge. Använd högerklick för att markera ett område",
        },
      },
      ExtraInfo: {
        EN: "Show extra info",
        NO: "Vis ekstra info",
        SE: "Visa extra information",
        Tooltip: {
          EN: "Show more info in the infobox to the right",
          NO: "Vis mer info i infoboksen til høyre",
          SE: "Visa mer information i lådan till höger",
        },
      },
      DemonstrationMode: {
        EN: "Demonstration mode",
        NO: "Demonstrasjonsmodus",
        SE: "Demonstrationsläge",
        Tooltip: {
          EN: "Enter demonstration mode for sales and demos",
          NO: "Gå inn i demonstrasjonsmodus for salg og demoer",
          SE: "Aktivera demonstrationsläge för sälj och demo",
        },
      },
      Algorithm: {
        EN: "Algorithm",
        NO: "Algoritme",
        SE: "Algoritm",
      },
      AutoSpeedZoomInReviewModes: {
        EN: "Automatically zoom into the first annotation in review modes",
        NO: "Zoom automatisk inn på den første kommentaren i gjennomgangsmodus",
        SE: "Zooma automatiskt in på den första annoteringen i granskningslägen",
      },
      ShowDsoTso: {
        EN: "Show DSO and TSO structures on images",
        NO: "Vis DSO- og TSO-strukturer på bilder",
        SE: "Visa DSO och TSO strukturer på bilder",
      },
    },
    Account: {
      EN: "Account",
      NO: "Konto",
      SE: "Konto",
      Save: {
        EN: "Save",
        NO: "Spare",
        SE: "Spara",
      },
      Cancel: {
        EN: "Cancel",
        NO: "Kansellere",
        SE: "Avbryt",
      },
      NotSet: {
        EN: "Not set",
        NO: "Ikke satt",
        SE: "Inte inställt",
      },
    },
    Logout: {
      EN: "Log out",
      NO: "Logg ut",
      SE: "Logga ut",
    },
  },
  MissionUpload: {
    SelectMission: {
      EN: "Project Name",
      NO: "Prosjektnavn",
      SE: "Projekts Namn",
    },
    UploadPowerline: {
      EN: "Upload Powerline",
      NO: "Last opp kraftledning",
      SE: "Ladda up ledning",
    },
    MarkOrderType: {
      EN: "Mark order type",
      NO: "Merk ordretype",
      SE: "Markera order typ",
    },
    Back: {
      EN: "Back",
      NO: "Tilbake",
      SE: "Bakåt",
    },
    Next: {
      EN: "Next",
      NO: "Neste",
      SE: "Nästa",
    },
    Finish: {
      EN: "Finish",
      NO: "Fullfør",
      SE: "Klar",
    },
    ClickNextToEdit: {
      EN: "Create a new Project by adding a project name.",
      NO: "Opprett et nytt prosjekt ved å legge til et prosjektnavn.",
      SE: "Skapa ett nytt projekt genom att skriva in ett projektsnamn nedan.",
    },
    IfCreatingNewMission: {
      EN: "Local Area + Purpose",
      NO: "Lokalområde + Formål",
      SE: "Område + Syfte",
    },
    CreateNewMission: {
      EN: "New project",
      NO: "Nytt prosjekt",
      SE: "Nytt projekt",
    },
    RightClickAndDrag: {
      EN: "Right click and drag to mark type of order on powerline. Click finish when done.",
      NO: "Høyreklikk og dra for å merke type bestilling på kraftledning. Klikk fullfør når du er ferdig.",
      SE: "Högerklicka och dra för att markera ordertyp på ledningen. Klicka på klar när du är klar",
    },
    MissionName: {
      EN: "Project name",
      NO: "Prosjektnavn",
      SE: "Projektets namn",
    },
    UploadSuccessful: {
      EN: "Upload Successful",
      NO: "Opplastingen var vellykket",
      SE: "Uppladning lyckades",
    },
    SavingPowerline: {
      EN: "Saving Powerline",
      NO: "Lagrer kraftledning",
      SE: "Sparar ledning",
    },
    ProcessingFile: {
      EN: "Processing File",
      NO: "Behandler fil",
      SE: "Bearbetar Filen",
    },
    PowerlineIsSaved: {
      EN: "Powerline is saved",
      NO: "Kraftledning er lagret",
      SE: "Ledningen har sparats",
    },
    ProcessingCompleted: {
      EN: "Processing Completed",
      NO: "Behandling fullført",
      SE: "Bearbetningen Klar",
    },
    FailedSavingPowerline: {
      EN: "Failed saving powerline",
      NO: "Kunne ikke lagre kraftledning",
      SE: "Misslyckades med att spara ledningen",
    },
    ProcessingFailed: {
      EN: "Processing Failed",
      NO: "Behandling mislyktes",
      SE: "Bearbetningen Misslyckad",
    },
  },
  ListView: {
    EN: "List View",
    NO: "Listevisning",
    SE: "List Vy",
    ActivateFilter: {
      EN: "Select a filter to activate list view toggle",
      NO: "Velg et filter for å aktivere listevisning",
      SE: "Välj ett filter för att aktivera listvy knappen",
    },
    NumberOfProcessedDefects: {
      EN: "Number of processed defects",
      NO: "Antall behandlede avvik",
      SE: "Antal bearbetade defekter",
    },
    NumberOfDefects: {
      EN: "Number of defects",
      NO: "Antall avvik",
      SE: "Antal defekter",
    },
    CollapseAll: {
      EN: "Collapse all",
      NO: "Skjul alle",
      SE: "Fäll ihop alla",
    },
    ExpandAll: {
      EN: "Expand all",
      NO: "Utvid alle",
      SE: "Fäll ut alla",
    },
    GoToNextImage: {
      EN: "Go to next image",
      NO: "Gå til neste bilde",
      SE: "Gå till nästa bild",
    },
    GoToPreviousImage: {
      EN: "Go to previous image",
      NO: "Gå til forrige bilde",
      SE: "Gå till föregående bild",
    },
  },
  PreviousDefectsListView: {
    EN: "Previous Defects List View",
    NO: "Listevisning over tidligere avvik",
    SE: "Tidigare Defekter Listvy",
    EditInNewTab: {
      EN: "Edit in new tab",
      NO: "Rediger i ny fane",
      SE: "Redigera i ny flik",
    },
  },
  MapInfo: {
    EN: "Color guide",
    NO: "Fargeguide",
    SE: "Färgguide",
    ShowImage: {
      EN: "Show image",
      NO: "Vis bilde",
      SE: "Visa bild",
    },
    Glossary: {
      EN: "Glossary",
      NO: "Ordliste",
      SE: "Ordlista",
      Detection: {
        EN: "Detection",
        NO: "Oppdagelse",
        SE: "Detektion",
        Explanation: {
          EN: "Detections are all things marked on images. They can be either Objects, Defects or Remarks",
          NO: "Deteksjoner er alle ting merket på bilder. De kan enten være objekter, avvikelser eller bemerkninger",
          SE: "Detektioner är alla saker som markeras på bilder. De kan vara antingen Objekt, Skador eller Upplysningar",
        },
      },
      Object: {
        EN: "Object",
        NO: "Gjenstand",
        SE: "Objekt",
        Explanation: {
          EN: "Things found on images, for example a pole or an insulator",
          NO: "Ting som finnes på bilder, for eksempel en mast eller en isolator",
          SE: "Saker som hittats på bilder, exempelvis en stolpe eller en isolator",
        },
      },
      Defect: {
        EN: "Defect",
        NO: "Avvik",
        SE: "Skada",
        Explanation: {
          EN: "Something found on an image that is a critical defect or an issue, for example a tree on wire or missing insulator",
          NO: "Noe funnet på et bilde som er en kritisk defekt eller et problem, for eksempel et tre på ledning eller manglende isolator",
          SE: "Någonting som hittats på en bild som är en Kritisk Skada eller Anmärkning, till exempel träd på lina eller en isolator som saknas",
        },
      },
      Remark: {
        EN: "Remark",
        NO: "Bemerke",
        SE: "Upplysning",
        Explanation: {
          EN: "Something found on an image that is noteworthy. For example a loose wire tie or a slightly leaning wooden pole",
          NO: "Noe funnet på et bilde som er bemerkelsesverdig. For eksempel et løst stålbånd eller en litt skjev trestang",
          SE: "Någonting som hittats på en bild som är en intressant upplysning. Till exempel en lös najning eller en stolpe som lutar något",
        },
      },
    },
    WhenNoFiltersAreApplied: {
      EN: "When no filters are applied",
      NO: "Når ingen filtre er brukt",
      SE: "När inget filter är aktivt",
      NoDetections: {
        EN: "Image that has no detections",
        NO: "Bilde som ikke har noen gjenkjenninger",
        SE: "Bild utan några detektioner",
      },
      FewerThanSix: {
        EN: "Image that has fewer than 6 detections",
        NO: "Bilde som har færre enn 6 deteksjoner",
        SE: "Bild med färre än 6 detektioner",
      },
      MoreThanSix: {
        EN: "Image that has 6 or more detections",
        NO: "Bilde som har 6 eller flere deteksjoner",
        SE: "Bild som har 6 eller fler detektioner",
      },
      Thermal: {
        EN: "Thermal image",
        NO: "Termisk bilde",
        SE: "Värmebild",
      },
      ImageWithDefect: {
        EN: "Image with defect(s)",
        NO: "Bilde med avvik",
        SE: "Bild med skador",
      },
    },
    WhenTheFilterForRemarksIsApplied: {
      EN: "When the filter for 'Remarks' is applied",
      NO: "Når filteret for 'Bemerkninger' brukes",
      SE: "När filtret för 'Upplysningar' är aktivt",
      Explanation: {
        EN: "All colours will be the same when filters are applied, except when the filter for 'Remarks' has been applied. 'Remarks' will. after filtering for them, show up in a soft red that is easily distinguished from the more severe red colour used for 'defects'",
        NO: "Alle farger vil være de samme når filtre brukes, bortsett fra når filteret for 'Bemerkninger' er brukt. 'Bemerkninger' vil. etter filtrering for dem, vises i en myk rød farge som lett kan skilles fra den mer alvorlige røde fargen som brukes for 'avvik'",
        SE: "Alla färger förblir desamma med filter, förutom när filtret för 'Upplysningar' används. 'Upplysningar' kommer, vid filtrering, få en mjukt röd färg som är enkel att skilja från den mer skarpa röda färgen som används för 'skador'",
      },
      Remark: {
        EN: "Remark(s)",
        NO: "Merknad(er)",
        SE: "Upplysning(ar)",
      },
    },
    ColorGuidePowerlines: {
      EN: "Powerlines",
      NO: "Kraftledninger",
      SE: "Ledningar",
      HasNoImages: {
        EN: "Has no images",
        NO: "Har ingen bilder",
        SE: "Har inga bilder",
      },
      IsBuried: {
        EN: "Is buried",
        NO: "Er gravlagt",
        SE: "Är nedgrävd",
      },
      HasImages: {
        EN: "Has images",
        NO: "Har bilder",
        SE: "Har bilder",
      },
      NeedsReflight: {
        EN: "Needs Reflight",
        NO: "Trenger Reflight",
        SE: "Behöver omflygning",
      },
      HasImagesAnalysisDone: {
        EN: "Has images, analysis done",
        NO: "Har bilder, analyse utført",
        SE: "Har bilder, analys klar",
      },
    },
    ColorGuidePowerlinesInspectorMode: {
      EN: "Powerlines - Inspector mode",
      NO: "Powerlines - Inspektørmodus",
      SE: "Powerlines - Inspector mode",
      HasNoImages: {
        EN: "Has no images",
        NO: "Har ingen bilder",
        SE: "Har inga bilder",
      },
      IsBuried: {
        EN: "Is buried",
        NO: "Er gravlagt",
        SE: "Är nedgrävd",
      },
      HasImages: {
        EN: "Has images",
        NO: "Har bilder",
        SE: "Har bilder",
      },
      NeedsReflight: {
        EN: "Needs Reflight",
        NO: "Trenger Reflight",
        SE: "Behöver omflygning",
      },
      SuperUserAnalysed: {
        EN: "Superuser analysed",
        NO: "Superbruker analysert",
        SE: "Superuser analysed",
      },
      SupervisorAnalysed: {
        EN: "Supervisor analysed",
        NO: "Veileder analysert",
        SE: "Supervisor analysed",
      },
      AnnotatorAnalysed: {
        EN: "Annotator analysed",
        NO: "Annotator analysert",
        SE: "Annotator analysed",
      },
      CriticalAnalysisDone: {
        EN: "Critical analysis done",
        NO: "Kritisk analyse utført",
        SE: "Kritisk analysis klar",
      },
    },
  },
  ClearanceAnnotation: {
    Markers: {
      EN: "Clearance markers",
      NO: "Klareringsmarkører",
      SE: "Regelöverträdelser",
    },
    SingleViolation: {
      EN: "clearance violation",
      NO: "brudd på klarering",
      SE: "regelöverträdelse",
    },
    MultipleViolation: {
      EN: "clearance violations",
      NO: "brudd på klarering",
      SE: "regelöverträdelser",
    },
  },
  MissionLanding: {
    Description: {
      EN: "You can find quick access to valuable information about your project on this page.",
      NO: "Du kan finne rask tilgang til verdifull informasjon om prosjektet ditt på denne siden.",
      SE: "Här har du snabb tillgång till värdefull information angående detta projekt.",
    },
    CreatingNewMission: {
      EN: "You are in the process of creating a new project",
      NO: "Du er i ferd med å lage et nytt prosjekt",
      SE: "Du håller på att skapa ett nytt projekt",
    },
    UserProgress: {
      EN: "User progress",
      NO: "Brukerfremgang",
      SE: "Användares status",
      Description: {
        EN: "See all assignments for inspectors on this project",
        NO: "Se alle oppdrag for inspektører på dette prosjektet",
        SE: "Se alla tilldelningar för inspektörer på detta projekt",
      },
    },
    ProjectId: {
      EN: "Project ID",
      NO: "Prosjekt-ID",
      SE: "Projekt ID",
    },
    MyAssignments: {
      EN: "My assignments",
      NO: "Mine oppdrag",
      SE: "Mina tilldelningar",
      Description: {
        EN: "See all assignments given to me",
        NO: "Se alle oppgavene jeg har fått",
        SE: "Se alla tilldelningar till mig",
      },
    },
    UploadImages: {
      EN: "Upload images",
      NO: "Last opp bilder",
      SE: "Ladda upp bilder",
    },
    OrderProgress: {
      EN: "Project overview",
      NO: "Prosjektoversikt",
      SE: "Överblick av projektet",
      YouWillBeNotified: {
        EN: "You will be notified by email whenever there is a status update.",
        NO: "Du vil bli varslet på e-post hver gang det er en statusoppdatering.",
        SE: "Du kommer notifieras via email när projektets status uppdateras.",
      },
      PlanningPhase: {
        EN: "Planning Phase",
        NO: "Planleggingsfasen",
        SE: "Planering",
        EmailList: {
          EN: "Critical Defect Email List",
          NO: "E-postliste med kritiske avvik",
          SE: "Kritiska skador mail-lista",
          Description: {
            EN: "Arkion reports critical observations as soon as they are found during the analysis. Add the users and other email addresses that will get emails regarding critical observations found during the analysis of this project.",
            NO: "Arkion rapporterer kritiske observasjoner så snart de blir funnet under analysen. Legg til brukerne og andre e-postadresser som vil motta e-poster angående kritiske observasjoner funnet under analysen av dette prosjektet.",
            SE: "Arkion rapporterar kritiska observationer direkt när de hittas. Lägg till användare och andra emailadresser i den här listan för att de ska få mail med kritiska observationer för det här projektet.",
          },
          AddNewEmail: {
            EN: "Add a new email",
            NO: "Legg til en ny e-post",
            SE: "Lägg till ny e-post",
          },
          EmailDialogDescription: {
            EN: "If you are missing any email addresses in the list, please add them here.",
            NO: "Hvis du mangler noen e-postadresser i listen, vennligst legg dem til her.",
            SE: "Om du saknar någon e-postadress i listan, lägg till den här.",
          },
          CopySelectedEmails: {
            EN: "Copy selected emails",
            NO: "Kopier valgte e-poster",
            SE: "Kopiera valda e-postadresser",
          },
          EmailAddressesCopied: {
            EN: "Email addresses copied!",
            NO: "E-postadresser er kopiert!",
            SE: "E-postadresser kopierade!",
          },
          FailedToCopyEmails: {
            EN: "Failed to copy email addresses",
            NO: "Kunne ikke kopiere e-postadresser",
            SE: "Misslyckades med att kopiera e-postadresser",
          },
          PleaseChooseACustomer: {
            EN: "Please choose a customer first",
            NO: "Velg en kunde først",
            SE: "Vänligen välj en kund först",
          },
          EmailAddedSuccessfully: {
            EN: "Email added successfully",
            NO: "E-post ble lagt til",
            SE: "E-postadress tillagd",
          },
          FailedToAddEmail: {
            EN: "Failed to add email",
            NO: "Kunne ikke legge til e-post",
            SE: "Misslyckades med att lägga till e-postadress",
          },
          FailedToFetchEmails: {
            EN: "Failed to fetch emails",
            NO: "Kunne ikke hente e-poster",
            SE: "Misslyckades med att hämta e-postadresser",
          },
        },
        UploaderList: {
          EN: "Uploader list",
          NO: "Opplastingsliste",
          SE: "Uppladdarlista",
          Description: {
            EN: "Add the users who will be uploading images to the project",
            NO: "Legg til brukerne som skal laste opp bilder til prosjektet",
            SE: "Lägg till användare som skall ladda upp bilder på projektet",
          },
          Footnote: {
            EN: "You can change this at a later point if needed. The users needs to exist on our platform, and you should must share a project or customer. If you are unable to find a person you want in the uploader list, please contact your Arkion contact.",
            NO: "Du kan endre dette på et senere tidspunkt om nødvendig. Brukerne må eksistere på vår plattform, og du bør dele et prosjekt eller en kunde. Hvis du ikke finner en person du ønsker i opplastingslisten, vennligst kontakt din Arkion-kontakt.",
            SE: "Du kan ändra denna lista senare. Endast användare som finns på vår plattform dyker upp, och ni måste dela på ett projekt eller en kund. Om du inte kan hitta en person du vill ha med, kontakt din Arkion kontakt",
          },
        },
        InvoiceReference: {
          EN: "Invoice reference",
          NO: "Fakturareferanse",
          SE: "Fakturareferens / beställningsnummer",
          Description: {
            EN: "Enter the invoice reference in your organization that Arkion should label the invoice with.",
            NO: "Skriv inn fakturareferansen i din organisasjon som Arkion skal merke fakturaen med.",
            SE: "Ange den fakturareferens / beställningsnummer som din organisation önskar att Arkion märker fakturan med.",
          },
          Footnote: {
            EN: "According to the orderer's (customer's) internal instructions for marking invoices. If you do not specify an invoice reference in connection with the order, we would like to receive this at the latest with the final report of the assignment.",
            NO: "I henhold til bestillerens (kundens) interne instrukser for merking av fakturaer. Dersom du ikke oppgir fakturareferanse i forbindelse med bestillingen, ønsker vi å motta dette senest sammen med sluttrapporten av oppdraget.",
            SE: "Enligt beställarens (kunds) interna anvisningar för märkning av faktura. Om du ej anger fakturareferens i samband med beställning önskar vi motta denna senast vid slutrapporten av projektet.",
          },
        },
        PlanningDone: {
          EN: "Planning done",
          NO: "Planlegging gjort",
          SE: "Planering slutfört",
        },
        Button: {
          EN: "Submit",
          NO: "Send inn",
          SE: "Skicka",
        },
      },
      FlyingPhase: {
        EN: "Flying",
        NO: "Flying",
        SE: "Flygning",
        WhenComplete: {
          EN: "When flying is complete, please press this button, and the analysis phase will start",
          NO: "Når flyingen er fullført, trykk på denne knappen, og analysefasen starter",
          SE: "När flygningen är klar, klicka på knappen så kommer analysfasen påbörjas",
        },
        Button: {
          EN: "Mark flying phase as complete",
          NO: "Merk flygefasen som fullført",
          SE: "Markera flygning som klar",
        },
        TakesPlace: {
          EN: "Flight takes place on",
          NO: "Flyvningen foregår på",
          SE: "Flygningen sker",
        },
      },
      FinalReport: {
        EN: "Final report",
        NO: "Sluttrapport",
        SE: "Slutrapport",
        HasBeenDelivered: {
          EN: "has been delivered",
          NO: "har blitt levert",
          SE: "har levererats",
        },
        WillBeDelivered: {
          EN: "will be delivered",
          NO: "vil bli levert",
          SE: "kommer levereras",
        },
        Description: {
          EN: "When the final report is done, the project is classified as completed.",
          NO: "Når sluttrapporten er ferdig, klassifiseres prosjektet som fullført.",
          SE: "När slutrapporten är klar klassificeras projektet som slutfört.",
        },
        Button: {
          EN: "Mark final report as done",
          NO: "Merk sluttrapporten som ferdig",
          SE: "Markera slutrapport som klar",
        },
      },
      OrderRecieved: {
        EN: "Confirm order",
        NO: "Bekreft bestillingen",
        SE: "Godkännande av beställning",
        OrderPlaced: {
          EN: "This order was placed on",
          NO: "Denne bestillingen ble lagt inn",
          SE: "Denna order placerades den",
        },
        OrderAccepted: {
          EN: "Order accepted on",
          NO: "Bestilling akseptert den",
          SE: "Beställningen godkändes",
        },
      },
      OrderMission: {
        Year: {
          Footnote: {
            EN: "The year where the project will take place.",
            NO: "Året hvor prosjektet skal finne sted.",
            SE: "Året projektet utförs.",
          },
        },
        RequiredFields: {
          EN: "Required fields",
          NO: "Obligatoriske felter",
          SE: "Obligatoriska fält",
        },
        Comment: {
          Footnote: {
            EN: "Please add a comment to Arkion if you have any questions or if there is something we should know.",
            NO: "Legg gjerne til en kommentar til Arkion dersom du har spørsmål eller om det er noe vi bør vite.",
            SE: "Ange gärna en kommentar till Arkion om du har några frågor eller om det är någonting annat vi bör veta.",
          },
        },
        MissionName: {
          FootNote: {
            EN: "Please refer from using customer name, region name, year/date or project type in the project name. This is already saved.",
            NO: "Vennligst referer fra å bruke kundenavn, regionnavn, år/dato eller prosjekttype i prosjektnavnet. Dette er allerede lagret.",
            SE: "Vänligen använd ej kundnamn, region, årtal/datum eller projektstyp i namnet. De fälten sparas redan separat.",
          },
        },
        Analysis: {
          Description: {
            EN: "Choose the type of analysis you would like Arkion to perform on the data gathered during the flight",
            NO: "Velg hvilken type analyse du vil at Arkion skal utføre på dataene samlet under flyturen",
            SE: "Välj den typ av analys du vill att Arkion skall genomföra på datan insamlad från flygningen",
          },
          ImageAnalysis: {
            EN: "Image Analysis",
            NO: "Bildeanalyse",
            SE: "Bildanalys",
            Tooltip: {
              EN: "Normal images will be analysed. Object detections and defect detections will be marked on these images.",
              NO: "Vanlige bilder vil bli analysert. Objektdeteksjoner og defektdeteksjoner vil merkes på disse bildene.",
              SE: "Vanliga bilder kommer analyseras. Objektdetektering och identifiering av skador kommer markeras på bilder.",
            },
          },
          ThermalAnalysis: {
            EN: "Thermal Image Analysis",
            NO: "Termisk bildeanalyse",
            SE: "Värmeanalys",
            Tooltip: {
              EN: "Thermal images will be analysed for hot spots. Requires thermal images.",
              NO: "Termiske bilder vil bli analysert for hot spots. Krever termiske bilder.",
              SE: "Värmebilder kommer analyseras för att hitta värmgångar. Kräver att värmebilderna laddas upp.",
            },
          },
          LidarAnalysis: {
            EN: "3D analysis",
            NO: "3D-analyse",
            SE: "3D Analys",
            Tooltip: {
              EN: "3D (point cloud data) will be processed and objects that are too close to the wires will be marked with a red color in the 3D map.",
              NO: "3D (punktskydata) vil bli behandlet og objekter som er for nærme ledningene vil merkes med rød farge i 3D-kartet.",
              SE: "3D (punktmolnsdata) kommer analyseras och objekt som är för nära ledningarna kommer markeras och rapporteras.",
            },
          },
          CorrosionAnalysis: {
            EN: "Corrosion analysis",
            NO: "Korrosjonsanalyse",
            SE: "Rostanalys",
            Tooltip: {
              EN: "Based on images, the asset health of lattice towers will be analyzed and reported. A downloadable report where each pole has it's own row is included.",
              NO: "Basert på bilder vil helsetilstanden til gittertårn bli analysert og rapportert. En nedlastbar rapport der hver stang har sin egen rad er inkludert.",
              SE: "Utifrån bilder rapporterar Arkion hur mycket visuall rost (corrosion) som finns på fackverksstolpar. En nedladdningsbar rapport med en rad per stolpe blir tillgänglig.",
            },
          },
          Footnote: {
            EN: "If you are unsure or would like to add/change this later, please discuss with your Arkion contact",
            NO: "Hvis du er usikker eller ønsker å legge til/endre dette senere, vennligst ta en prat med din Arkion-kontakt",
            SE: "Om du är osäker eller vill ändra detta vid ett senare skede, kontakta din kontakt hos Arkion.",
          },
        },
        DroneOperator: {
          Description: {
            EN: "Provide the name of the drone partner that will perform the flight and upload the data to Arkion",
            NO: "Oppgi navnet på dronepartneren som skal utføre flyvningen og last opp dataene til Arkion",
            SE: "Ange namnet på drönarpartner som kommer flyga och ladda upp datan till Arkion",
          },
          Footnote: {
            EN: "Text field, for Arkion to know who will be the data collector. You can change supplier later if neccesary. Make sure to communicate any change in drone operator to Arkion.",
            NO: "Tekstfelt, for at Arkion skal vite hvem som skal være datainnsamler. Du kan bytte leverandør senere hvis det er nødvendig. Sørg for å kommunisere enhver endring i droneoperatør til Arkion.",
            SE: "Fritextfält så att Arkion förstår vilken leverantör som kommer leverera sensordata. Du kan ändra leverantör senare. Vänligen kommunicera byte till Arkion",
          },
        },
        Region: {
          Description: {
            EN: "What region does the new project belong to?",
            NO: "Hvilken region tilhører det nye prosjektet?",
            SE: "Vilken region tillhör projektet?",
          },
          NoRegions: {
            EN: "There are no regions available. You can still create an order without a region.",
            NO: "Det er ingen tilgjengelige regioner. Du kan fortsatt opprette en ordre uten region.",
            SE: "Det finns inga regioner tillgängliga. Du kan fortfarande skicka en order utan en region",
          },
          Footnote: {
            EN: "The region is associated to the geographical location of the grid. All regions for your grid should be listed here. If a region is project, contact Arkion.",
            NO: "Regionen er knyttet til den geografiske plasseringen av nettet. Alle regioner for rutenettet ditt skal være oppført her. Dersom en region er prosjekt, kontakt Arkion.",
            SE: "Regionen associeras till den geografiska positionen av nätet. Alla regioner för ert nät ska ligga här i listan. Saknar ni någon region kontakta Arkion.",
          },
          NoAccess: {
            EN: "You do not have access to any regions",
            NO: "Du har ikke tilgang til noen regioner",
            SE: "Du har inte tillgång till några regioner",
          },
        },
        DeleteDraft: {
          EN: "Are you sure you want to delete this draft?",
          NO: "Er du sikker på at du vil slette dette utkastet?",
          SE: "Är du säker att du vill radera utkastet?",
        },
        OrderPlaced: {
          EN: "Order placed on",
          NO: "Bestilling lagt på",
          SE: "Order placerades",
        },
        MissionType: {
          EN: "Project type",
          NO: "Prosjekttype",
          SE: "Projektstyp",
          Description: {
            EN: "Please select the type of project you want to order",
            NO: "Velg type prosjekt du ønsker å bestille",
            SE: "Välj vilken typ av projekt vill du beställa",
          },
          Maintenance: {
            EN: "Maintenance",
            NO: "Vedlikehold",
            SE: "Driftbesiktning",
            Tooltip: {
              EN: "A maintenance project is where you fly over your grid on a regular basis to spot potential issues. If you only want to see the status of your grid, pick this option.",
              NO: "Et vedlikeholdsprosjekt er der du flyr over nettet ditt med jevne mellomrom for å oppdage potensielle problemer. Hvis du bare vil se statusen til nettet ditt, velg dette alternativet.",
              SE: "En driftbesiktning är när du flyger över ditt nät minst 1 ggr per år för att hitta potentiella skador. Om du vill se statusen för alla skador för ditt nät, välj detta alternativ.",
            },
          },
          Troubleshoot: {
            EN: "Troubleshoot",
            NO: "Feilsøking",
            SE: "Felsökning",
            Tooltip: {
              EN: "A troubleshooting project is where you know there is something wrong with a specific stretch of grid, and you want to identify the issues. This is best coupled with extra analysis like thermal and lidar.",
              NO: "Et feilsøkingsprosjekt er der du vet at det er noe galt med en bestemt strekning av nettet, og du vil identifisere problemene. Dette er best kombinert med ekstra analyse som termisk og lidar.",
              SE: "Ett felsökningsprojekt är när du vet att någonting är fel med nätet, och du vill identifiera grundorsaken. Detta är bäst kombinerat med extra analys av värmebilder och 3D lidar-data-analys.",
            },
          },
          Trial: {
            EN: "Trial / POC",
            NO: "Prøve / POC",
            SE: "Test / POC",
            Tooltip: {
              EN: "If you want to test and see how our platform behaves, then pick this option.",
              NO: "Hvis du vil teste og se hvordan plattformen vår oppfører seg, velg dette alternativet.",
              SE: "Om du vill se hur vår plattform fungerar, välj detta alternativet.",
            },
          },
          Storm: {
            EN: "After Storm",
            NO: "Etter Storm",
            SE: "Störning / Storm",
            Tooltip: {
              EN: "Inspect a stretch of your grid after a storm. Only analysis of overview images.",
              NO: "Inspiser en strekning av nettet ditt etter en storm. Kun analyse av oversiktsbilder.",
              SE: "Inspektera en sträcka av ert nät efter en extern störning som t.ex. en storm. Analys av endast översiktsbilder för att snabbt hitta stora skador.",
            },
          },
          Projecting: {
            EN: "Project Planning",
            NO: "Prosjektplanlegging",
            SE: "Projektering",
            Tooltip: {
              EN: "Upload images for a strech that will be built or rebuilt. No analysis by Arkion.",
              NO: "Last opp bilder for en strekning som skal bygges eller gjenoppbygges. Ingen analyse fra Arkion.",
              SE: "Ladda upp övsiktsbilder på en sträcka som ska byggas eller renoveras. Ingen analys av Arkion.",
            },
          },
          Footnote: {
            EN: "The project type determines how we will look for damages on the grid. Please hover the information icons above to see more details.",
            NO: "Prosjekttypen avgjør hvordan vi vil se etter avvik på nettet. Hold musepekeren over informasjonsikonene ovenfor for å se flere detaljer.",
            SE: "Projektstypen bestämmer hur vi analyserar skador på nätet. Du kan hålla musen över informationsikonen för att få mer detaljer.",
          },
        },
        NoGroups: {
          EN: "You do not have access to any customer. Please contact Arkion or your company admin.",
          NO: "Du har ikke tilgang til noen kunde. Ta kontakt med Arkion eller din bedriftsadministrator.",
          SE: "Du har inte tillgång till några kunder. Kontakta Arkion",
        },
        GroupDescription: {
          EN: "What customer does the new project belong to?",
          NO: "Hvilken kunde tilhører det nye prosjektet?",
          SE: "Vilken kund tillhör projektet?",
        },
        GroupFootnote: {
          EN: "",
          NO: "None",
          SE: "",
        },
        PowerlineUpload: {
          EN: "Powerline upload",
          NO: "Kraftledninger opplasting",
          SE: "Ladda upp elnät",
          MultiFile: {
            EN: "You can select one or multiple files. You don't need to upload all files now.",
            NO: "Du kan velge én eller flere filer. Du trenger ikke laste opp alle filene nå.",
            SE: "Du kan välja en eller flera filer. Du måste inte ladda upp alla filer direkt.",
          },
          Description: {
            EN: "Upload SHP (.zip) or KML file or powerlines",
            NO: "Last opp SHP (.zip)- eller KML-fil som inneholder kraftledninger",
            SE: "Ladda upp KML-fil eller Shapefil (.zip) som innehåller ledningar",
          },
          HasLength: {
            EN: "has length",
            NO: "har lengde",
            SE: "har längd",
          },
          Remove: {
            EN: "Remove",
            NO: "Fjerne",
            SE: "Ta bort",
          },
          Upload: {
            EN: "Upload",
            NO: "Last opp",
            SE: "Ladda upp",
          },
          Highlight: {
            EN: "Highlight",
            NO: "Uthev",
            SE: "Markera",
          },
          UnHighlight: {
            EN: "Unhighlight",
            NO: "Fjern fremheving",
            SE: "Avmarkera",
          },
          Footnote: {
            EN: "The grid will be vizialized on the map when you upload the file/files.",
            NO: "Nettet vil bli visialisert på kartet når du laster opp filen/filene.",
            SE: "Nätet kommer visualiseras på kartan när du laddar upp filen/filerna.",
          },
          Error: {
            FailedUpload: {
              EN: "Failed to upload file",
              NO: "Kunne ikke laste opp fil",
              SV: "Uppladdning misslyckades",
            },
            SHAPE_FILE_MISSING_CRS: {
              EN: "Shape file is missing cartographic reference system (CRS).",
              NO: "Shape-filen mangler kartografisk referansesystem (CRS).",
              SV: "Shape fil saknar koordinat system (CRS).",
            },
          },
        },
        PreliminaryFlightDate: {
          EN: "Preliminary flight date",
          NO: "Foreløpig flydato",
          SE: "Preliminärt flygdatum",
          Description: {
            EN: "Please pick a preliminary date when the flight will take place. If the flight duration spans across multiple days, pick the first day of flying. If you are uploading historical data, please pick a date going back in time to when the sensor data was collected.",
            NO: "Vennligst velg en foreløpig dato når flyvningen skal finne sted. Hvis flyvarigheten spenner over flere dager, velg den første flydagen. Hvis du laster opp historiske data, vennligst velg en dato som går tilbake i tid til da sensordataene ble samlet inn.",
            SE: "Välj ett preliminärt flygdatum. Om flygningen löper över ett flertal dagar, välj den första dagen av flygningen. Om det är historisk data, välj ett datum bakåt i tiden.",
          },
          Footnote: {
            EN: "The preliminary flight date is an estimate of when the flight is planned to start. You can change this date closer to the flight start, as long as it is communicated to Arkion and your drone partner.",
            NO: "Den foreløpige flydatoen er et estimat på når flyet er planlagt å starte. Du kan endre denne datoen nærmere flystarten, så lenge den er kommunisert til Arkion og din dronepartner.",
            SE: "Det preliminära flygdatumet är en uppskattning om när flygningen kommer ta plats. Du kan ändra detta datum närmare flygdatumet, så länge det kommuniceras till Arkion och din drönarpartner.",
          },
        },
        Reset: {
          EN: "Reset",
          NO: "Tilbakestill",
          SE: "Återställ",
        },
        SaveDraft: {
          EN: "Save draft",
          NO: "Lagre utkast",
          SE: "Spara utkast",
        },
        SendOrder: {
          EN: "Send order",
          NO: "Send ordre",
          SE: "Skicka order",
        },
      },
      AnalysisPhase: {
        EN: "Analysis",
        NO: "Analyse",
        SE: "Analys",
        AnalysisDone: {
          EN: "The analysis of this project has been completed",
          NO: "Analysen av dette prosjektet er fullført",
          SE: "Analysen för detta projekt har slutförts.",
        },
        AnalysisPending: {
          EN: "The analysis of this project is yet to be completed",
          NO: "Analysen av dette prosjektet er ennå ikke fullført",
          SE: "Analysen för detta projekt har inte slutförts ännu",
        },
        ButtonHelpText: {
          EN: "When analysis is complete, please press this button, and the next phase will start",
          NO: "Når analysen er fullført, trykk på denne knappen, og neste fase starter",
          SE: "När analysen är klar, klicka på denna knappen för att gå vidare till nästa fas. ",
        },
        ButtonText: {
          EN: "Mark analysis as complete",
          NO: "Merk analysen som fullført",
          SE: "Markera analys som slutförd",
        },
      },
    },
  },
  Landing: {
    EN: "Landing Page",
    NO: "Landingsside",
    SE: "Landningssida",
    NewDefects: {
      EN: "New defects",
      NO: "Nye avvik",
      SE: "Nya defekter",
      MarkAllDefectsAsViewed: {
        EN: "Mark all defects as viewed",
        NO: "Merk alle avvik som vist",
        SE: "Markera alla defekter som sedda",
      },
      RefreshData: {
        EN: "Refresh data",
        NO: "Oppdater data",
        SE: "Uppdatera data",
      },
      Description: {
        EN: "This is a list of all new defects that have been found in the project. Only defects you have not seen before are shown here.",
        NO: "Dette er en liste over alle nye avvik som er funnet i prosjektet. Her vises kun avvik du ikke har sett før.",
        SE: "Detta är en lista över alla nya defekter som har hittats i projektet. Endast defekter du inte har sett tidigare visas här.",
      },
      TooltipOne: {
        EN: "These numbers are updated every 5 minutes. You can force refresh the data by clicking the refresh button in the options menu.",
        NO: "Disse tallene oppdateres hvert 5. minutt. Du kan tvinge oppdatering av dataene ved å klikke på oppdateringsknappen i alternativmenyen.",
        SE: "Dessa nummer uppdateras var 5:e minut. Du kan tvinga en uppdatering genom att klicka på uppdateringsknappen i alternativmenyn.",
      },
      TooltipTwo: {
        EN: "You can manually mark all defects as viewed by clicking the menu and mark all defects as viewed button in the top right corner. This action can also be done on a project level by clicking the options menu.",
        NO: "Du kan manuelt merke alle avvik som vist ved å klikke på menyen og merke alle avvik som vist-knappen øverst til høyre. Denne handlingen kan også gjøres på prosjektnivå ved å klikke på alternativmenyen.",
        SE: "Du kan manuellt markera alla defekter som sedda genom att klicka på menyn och markera alla defekter som sedda knappen i det övre högra hörnet. Denna åtgärd kan också göras på projektnivå genom att klicka på alternativmenyn.",
      },
    },
    Assignments: {
      EN: "Assignments",
      NO: "Oppdrag",
      SE: "Tilldelningar",
      NotAssigned: {
        EN: "Not assigned",
        NO: "Ikke tildelt",
        SE: "Inte tilldelad",
      },
      Description: {
        EN: "See what areas you have been assigned to analyze",
        NO: "Se hvilke områder du har fått tildelt å analysere",
        SE: "Se vilka områden du har blivit tilldelad att analysera.",
      },
      Analysis: {
        EN: "Analysis",
        NO: "Analyse",
        SE: "Analys",
      },
      CriticalAnalysis: {
        EN: "Critical Analysis",
        NO: "Kritisk analyse",
        SE: "Kritisk Analys",
      },
      AssignmentOverview: {
        EN: "Assignment overview",
        NO: "Oppdragsoversikt",
        SE: "Översikt tilldelningar",
        Description: {
          EN: "See all assignments that have been given out",
          NO: "Se alle oppdrag som er gitt ut",
          SE: "Se alla projekt som blivit tilldelade",
        },
      },
      SeeUsersAssignment: {
        EN: "See assignments for all users",
        NO: "Se oppdrag for alle brukere",
        SE: "Se tilldelningar för alla användare",
      },
      ActiveMissions: {
        EN: "Active projects",
        NO: "Aktive prosjekter",
        SE: "Aktiva projekt",
        Description: {
          EN: "See assignments for all active projects",
          NO: "Se oppgaver for alle aktive prosjekter",
          SE: "See tilldelningar för alla aktiva projekt",
        },
      },
      InactiveMissions: {
        EN: "Inactive projects",
        NO: "Inaktive prosjekter",
        SE: "Inaktiva projekt",
        Description: {
          EN: "See assignments for all inactive projects",
          NO: "Se oppgaver for alle inaktive prosjekter",
          SE: "See tilldelningar för alla inaktiva projekt",
        },
      },
      AssignedImages: {
        EN: "Assigned images",
        NO: "Tildelte bilder",
        SE: "Tilldelade bilder",
      },
      FlaggedImages: {
        EN: "Flagged images",
        NO: "Flaggede bilder",
        SE: "Flaggade bilder",
        Description: {
          EN: "See where you have flagged images",
          NO: "Se hvor du har flagget bilder",
          SE: "Se vart det finns flaggade bilder",
        },
      },
    },
    ResponsibleAnnotator: {
      EN: "Responsible annotator",
      NO: "Ansvarlig kommentator",
      SE: "Ansvarig annotarere",
    },
    SeeMap: {
      EN: "See map",
      NO: "Se kart",
      SE: "Se karta",
    },
    SeeMission: {
      EN: "See project",
      NO: "Se prosjekt",
      SE: "Se projekt",
    },
    Description: {
      EN: "This is your landing page. This will give you an overview of your projects, uploads and more.",
      NO: "Dette er landingssiden din. Dette vil gi deg en oversikt over dine prosjekter, opplastinger og mer.",
      SE: "Detta är din landningssida. Här kan du se en överblick över dina projekt, uppladdningar och mer.",
    },
    PlannedMissions: {
      EN: "Planned projects",
      NO: "Planlagte prosjekter",
      SE: "Planerade projekt",
      Description: {
        EN: "Click to see projects that are being planned",
        NO: "Klikk for å se prosjekter som planlegges",
        SE: "Klicka för att se projekt som är planerade",
      },
    },
    OrderedMissions: {
      EN: "Ordered projects",
      NO: "Bestilte prosjekter",
      SE: "Beställda projekt",
      Description: {
        EN: "Click to see missions that have been ordered",
        NO: "Klikk for å se oppdrag som er bestilt",
        SE: "Klicka för att se projekt som har beställts",
      },
    },
    LastUploads: {
      EN: "Previous uploads",
      NO: "Tidligere opplastinger",
      SE: "Tidigare uppladdningar",
      Description: {
        EN: "See the previous uploads for your projects",
        NO: "Se de tidligere opplastingene for prosjektene dine",
        SE: "Se tidigare uppladdningar för dina projekt",
      },
    },
    PreviousLogins: {
      EN: "My previous logins",
      NO: "Mine tidligere pålogginger",
      SE: "Mina tidigare inloggningar",
      Description: {
        EN: "See previous logins on your account",
        NO: "Se tidligere pålogginger på kontoen din",
        SE: "Se tidigare inloggningar på ditt konto",
      },
    },
    DoneMissions: {
      EN: "Completed Projects",
      NO: "Gjennomførte prosjekter",
      SE: "Avklarade projekt",
      Description: {
        EN: "Click to see projects that are completed",
        NO: "Klikk for å se prosjekter som er fullført",
        SE: "Klicka för att se vilka projekt som är klara",
      },
    },
    ActiveMissions: {
      EN: "Active Projects",
      NO: "Aktive prosjekter",
      SE: "Aktiva projekt",
      Description: {
        EN: "Click to see projects that are active",
        NO: "Klikk for å se prosjekter som er aktive",
        SE: "Klicka för att se aktiva projekt",
      },
    },
    ImageFeedback: {
      EN: "Image feedback",
      NO: "Tilbakemelding på bilde",
      SE: "Rapporterade bilder",
      SeeAllImageFeedbacks: {
        EN: "See all image feedbacks",
        NO: "Se alle bildetilbakemeldinger",
        SE: "Se alla rapporterade bilder",
      },
      clientId: {
        EN: "Client ID",
        NO: "Klient-ID",
        SE: "Klient ID",
      },
      imageId: {
        EN: "Image ID",
        NO: "Bilde-ID",
        SE: "Bild ID",
      },
      type: {
        EN: "type",
        NO: "type",
        SE: "typ",
      },
      comment: {
        EN: "comment",
        NO: "kommentar",
        SE: "kommentar",
      },
      resolved: {
        EN: "resolved",
        NO: "løst",
        SE: "avklarad",
      },
    },
    ClientAlteredTypes: {
      EN: "Client altered severities",
      NO: "Klienten endret alvorlighetsgrad",
      SE: "Klient ändrade allvarlighetsgrader",
      SeeAllClientAlteredTypes: {
        EN: "See all client altered types",
        NO: "Se alle klientendrede typer",
        SE: "Se alla klient ändrade objekttyper",
      },
      SeeMostRecentAlteredTypes: {
        EN: "See most recent client altered types",
        NO: "Se de siste klientendrede typene",
        SE: "Se senaste klient ändrade objekttyper",
      },
      Old: {
        EN: "Old",
        NO: "Gammel",
        SE: "Före",
      },
      New: {
        EN: "New",
        NO: "Ny",
        SE: "Efter",
      },
    },
  },
  DefectReport: {
    EN: "Arkion Defect Report",
    NO: "Arkion avvikrapport",
    SE: "Arkion Skaderapport",
    FilterExplanation: {
      EN: "Add filters to the report by opening the filter tab on the left side of the screen. When you have chosen a filter, the numbers above will update.",
      NO: "Legg til filtre i rapporten ved å åpne filterfanen på venstre side av skjermen. Når du har valgt et filter, vil tallene ovenfor oppdateres.",
      SE: "Lägg till filter i rapporten genom att öppna filterfliken till vänster. När du har valt ett filter kommer siffrorna ovan att uppdateras.",
    },
    Excel: {
      Description: {
        EN: "Export the defects to an excel format. The excel format is used for Microsoft Excel, but is also compatible with other spreadsheet software.",
        NO: "Eksporter avvik til et excel-format. Excel-formatet brukes for Microsoft Excel, men er også kompatibelt med annen regnearkprogramvare.",
        SE: "Exportera defekterna till ett excelformat. Excelformatet används i Microsoft Excel, men är även kompatibelt med andra kalkylbladsprogram.",
      },
    },
    CSV: {
      Description: {
        EN: "Export the defects to a CSV format. The CSV format is a general-purpose format to export and import data. The CSV file exported can also be viewed in any spreadsheet software.",
        NO: "Eksporter avvik til et CSV-format. CSV-formatet er et generellt format for å eksportere og importere data. Den eksporterte CSV-filen kan også vises i hvilken som helst regnearkprogramvare.",
        SE: "Expoetera defekterna till ett CSV-format. CSV-formatet är ett generellt format som ofta används för att exportera data. CSV filen kan även öppnas i alla kalkylbladsprogram.",
      },
    },
    DPPower: {
      Description: {
        EN: "Export the defects found in your project to DPPower. This file is a binary file specifically designed to integrate with DPPowers systems. If you do not have DPPower as an inventory management system, then this feature is not for you.",
        NO: "Eksporter avvik som er funnet i prosjektet til DPPower. Denne filen er en binær fil som er spesielt utviklet for å integreres med DPPowers-systemer. Hvis du ikke har DPPower som et lagerstyringssystem, er ikke denne funksjonen for deg.",
        SE: "Exportera defekterna i detta projekt till DPPower. Denna fil är en binar fil speciellt designad för att integrera med DPPowers system. Om ni inte använder DPPower för att hantera ert elnät, så är inte denna funktion för er.",
      },
    },
    MissingOID: {
      EN: "Missing OID on some defects",
      NO: "Mangler OID på noen avvik",
      SE: "Vissa defekter saknar OID",
    },
    SomePolesAreMission: {
      EN: "Some poles are missing for the defects that you are about to download. Please choose an option for your download.",
      NO: "Noen master mangler for avvik du er i ferd med å laste ned. Velg et alternativ for nedlastingen.",
      SE: "Vissa stolpar saknas för defekterna som du håller på att ladda ner. Var god och välj ett av alternativen för din nedladdning.",
    },
    AllDefects: {
      EN: "All defects",
      NO: "Alle avvik",
      SE: "Alla defekter",
    },
    AllDefectsWithoutOID: {
      EN: "All defects without OID",
      NO: "Alle avvik uten OID",
      SE: "Alla defekter utan OID",
    },
    DefectsWithoutOID: {
      EN: "Defects without OID",
      NO: "Avvik uten OID",
      SE: "Defekter utan OID",
    },
    DefectsWithoutPoles: {
      EN: "Defects without poles",
      NO: "Avvik uten master",
      SE: "Defekter utan stolpar",
    },
    Numbers: {
      EN: "We found the defects as described in the table below. To change what defects you want in the report, open the filter in the menu to the left.",
      NO: "Vi fant avvik som beskrevet i tabellen nedenfor. For å endre hvilke avvik du ønsker i rapporten, åpne filteret i menyen til venstre.",
      SE: "Vi hittade defekter enligt beskrivning i tabellen nedan. För att ändra vilka defekter ni vill ha i rapporten, öppna filtret i menyn till vänster.",
    },
    Guide: {
      EN: "Welcome to the Arkion Report Editor",
      NO: "Velkommen til Arkion Report Editor",
      SE: "Välkommen till Arkions rapportverktyg",
      WhatIsIt: {
        EN: "What is it?",
        NO: "Hva er det?",
        SE: "Vad är detta?",
        Content: {
          EN: "You can generate a report and edit it directly here in the browser. Thus, you will be able to see exactly what the report will look like when you export it.",
          NO: "Du kan generere en rapport og redigere den direkte her i nettleseren. Dermed vil du kunne se nøyaktig hvordan rapporten vil se ut når du eksporterer den.",
          SE: "Du kan generera en rapport och ändra den direkt i webbläsaren. Därmed kan du se exakt hur rapporten kommer se ut när du exporterar den.",
        },
      },
      WhatToDo: {
        EN: "What do I do?",
        NO: "Hva gjør jeg?",
        SE: "Vad ska jag göra?",
        Content: {
          EN: "Activate a filter in the menu to the left. Then press 'Load report' and a report will be displayed below.",
          NO: "Aktiver et filter i menyen til venstre. Trykk deretter 'Last rapport' og en rapport vil vises nedenfor.",
          SE: "Aktivera ett filter i menyn till vänster. Tryck därefter 'Ladda rapport' och en rapport kommer visas nedan.",
        },
      },
      HowToDownload: {
        EN: "How do I download the report?",
        NO: "Hvordan laster jeg ned rapporten?",
        SE: "Hur laddar jag ned rapporten?",
        Content: {
          EN: "When a report has been loaded, make sure all images have been downloaded (that you can see an image on each page below). Then press 'Export'",
          NO: "Når en rapport er lastet inn, sørg for at alle bildene er lastet ned (at du kan se et bilde på hver side nedenfor). Trykk deretter 'Eksporter'",
          SE: "När en rapport har laddats bör du se till att alla bilder har laddats ned. Du kan se detta genom att skrolla igenom rapporten. Därefter trycker du på 'Exportera'",
        },
      },
      WhatIsSpecial: {
        EN: "What's so special about this?",
        NO: "Hva er så spesielt med dette?",
        SE: "Vad är så speciellt med rapporten?",
        Content: {
          EN: "You can edit anything and everything in your report (except for images and QR codes). This can be used to add information such as:",
          NO: "Du kan redigere alt og alt i rapporten (bortsett fra bilder og QR-koder). Dette kan brukes til å legge til informasjon som:",
          SE: "Du kan editera allt i rapporten (förrutom bilder och QR koder). Detta kan användas för att lägga till informatino som:",
        },
        Table: {
          Grid: {
            EN: "Grid owner",
            NO: "Netteier",
            SE: "Nätägare",
          },
          Pole: {
            EN: "Pole type",
            NO: "Mast type",
            SE: "Stolptyp",
          },
          Replacement: {
            EN: "Replacement parts",
            NO: "Reservedeler",
            SE: "Reservdel",
          },
          More: {
            EN: "And much more",
            NO: "Og mye mer",
            SE: "Och mycket mer",
          },
        },
      },
      MoreQuestions: {
        EN: "Do you have more questions?",
        NO: "Har du flere spørsmål?",
        SE: "Har du fler frågor?",
        Content: {
          EN: "Feel free to contact Arkion if you want more information about this tool.",
          NO: "Ta gjerne kontakt med Arkion dersom du ønsker mer informasjon om dette verktøyet.",
          SE: "Kontakta gärna Arkion om du vill ha mer information om detta verktyg.",
        },
      },
    },
    ImageName: {
      EN: "Image name",
      NO: "Bildenavn",
      SE: "Bildnamn",
    },
    Export: {
      EN: "Export",
      NO: "Eksport",
      SE: "Exportera",
    },
    ReloadReport: {
      EN: "Reload report",
      NO: "Last inn rapporten på nytt",
      SE: "Ladda om rapport",
    },
    LoadReport: {
      EN: "Load report",
      NO: "Last inn rapport",
      SE: "Ladda rapport",
    },
    Clear: {
      EN: "Clear",
      NO: "Klar",
      SE: "Rensa",
    },
    Count: {
      EN: "Count",
      NO: "Telle",
      SE: "Antal",
    },
    Defect: {
      EN: "Defect",
      NO: "Avvik",
      SE: "Skada",
    },
    FailedToLoadData: {
      EN: "Failed to load report",
      NO: "Kunne ikke laste inn rapporten",
      SE: "Misslyckades att ladda rapporten",
    },
    Severity: {
      EN: "Severity",
      NO: "Alvorlighetsgrad",
      SE: "Allvarlighetsgrad",
    },
    Link: {
      EN: "Link",
      NO: "Link",
      SE: "Länk",
    },
    Lat: {
      EN: "Lat",
      NO: "Lat",
      SE: "Lat",
    },
    Lng: {
      EN: "Lng",
      NO: "Lng",
      SE: "Lng",
    },
    Comment: {
      EN: "Comment",
      NO: "Kommentar",
      SE: "Kommentar",
    },
    CommentedBy: {
      EN: "Commented by",
      NO: "Kommentert av",
      SE: "Kommenterad av",
    },
    TotalImages: {
      EN: "Total images",
      NO: "Totalt bilder",
      SE: "Antal bilder",
    },
    TotalDefects: {
      EN: "Total defects",
      NO: "Totale avvik",
      SE: "Antal skador",
    },
    NoDefectsFound: {
      EN: "No defects found. Try to change the filter in the menu on your left-hand side.",
      NO: "Ingen avvik funnet. Prøv å endre filteret i menyen på venstre side.",
      SE: "Inga skador hittades. Testa att ändra filtret i menyn till vänster.",
    },
    ScanQR: {
      EN: "Scan the QR code to view the location of the defect in google maps.",
      NO: "Skann QR-koden for å se plasseringen av avvik i google maps.",
      SE: "Skanna QR koden för att se skadans position i google maps.",
    },
  },
  Inbox: {
    Reports: {
      ReportContent: {
        EN: "Report content",
        NO: "Rapporter innhold",
        SE: "Rapportens innehåll",
      },
      Language: {
        EN: "Language",
        NO: "Språk",
        SE: "Språk",
      },
      SummaryPage: {
        EN: "Summary page",
        NO: "Sammendragsside",
        SE: "Sammanfattning",
      },
      ImagePage: {
        EN: "Image page",
        NO: "Bildeside",
        SE: "Exempel",
      },
      IncludeFixed: {
        EN: "Include fixed defects",
        NO: "Inkluder faste avvik",
        SE: "Inkludera lagade defekter",
      },
      Title: {
        EN: "My reports",
        NO: "Mine rapporter",
        SE: "Mina rapporter",
      },
      AllProjects: {
        EN: "All projects",
        NO: "Alle prosjekter",
        SE: "Alla projekt",
      },
      Deleted: {
        EN: "Report deleted",
        NO: "Rapport slettet",
        SE: "Rapport raderad",
      },
      FailedToLoad: {
        EN: "Failed to load",
        NO: "Kunne ikke laste inn",
        SE: "Misslyckades att ladda",
      },
      Loading: {
        EN: "The report is being created. Please wait. The page will update automatically",
        NO: "Rapporten blir opprettet. Vennligst vent. Siden oppdateres automatisk",
        SE: "Rapporten laddar. Var god vänta. Sidan kommer uppdateras automatiskt",
      },
      ApplyOnMap: {
        EN: "Apply filter and view the map",
        NO: "Bruk filter og se kartet",
        SE: "Applicera filter och se på en karta",
      },
      DownloadPDF: {
        EN: "Download PDF",
        NO: "Last ned PDF",
        SE: "Ladda ned PDF",
      },
      Share: {
        EN: "Share this with another user",
        NO: "Del dette med en annen bruker",
        SE: "Dela med en annan användare",
      },
      DeletePDF: {
        EN: "Delete this PDF",
        NO: "Slett denne PDF-en",
        SE: "Radera denna PDF",
      },
      DeleteConfirm: {
        EN: "Are you sure you want to delete this report?",
        NO: "Er du sikker på at du vil slette denne rapporten?",
        SE: "Är du säker att du vill radera denna rapport?",
      },
      RenameReport: {
        EN: "Rename this report",
        NO: "Gi nytt navn til denne rapporten",
        SE: "Byt namn på denna rapport",
      },
      Created: {
        EN: "Created",
        NO: "Opprettet",
        SE: "Skapad",
      },
      Creator: {
        EN: "Creator",
        NO: "Skaper",
        SE: "Skapare",
      },
      PdfSize: {
        EN: "PDF Size",
        NO: "PDF-størrelse",
        SE: "Storlek",
      },
      Images: {
        EN: "Images",
        NO: "Bilder",
        SE: "Antal bilder",
      },
      Defects: {
        EN: "Defects",
        NO: "Avvik",
        SE: "Antal Skador",
      },
      FilterDetails: {
        EN: "Filter details",
        NO: "Filtrer detaljer",
        SE: "Filterdetaljer",
      },
      NoGroup: {
        EN: "No customer",
        NO: "Ingen kunde",
        SE: "Ingen kund",
      },
      CreateNewReport: {
        EN: "Create new report",
        NO: "Opprett ny rapport",
        SE: "Skapa ny rapport",
      },
      SelectProject: {
        EN: "Select project to create a report for",
        NO: "Velg prosjekt for å lage en rapport for",
        SE: "Välj ett projekt att skapa en rapport för",
      },
      SelectSeverity: {
        EN: "Select which severity levels you want to include in the report",
        NO: "Velg hvilke alvorlighetsnivåer du vil inkludere i rapporten",
        SE: "Välj en de allvarlighetsgrader du vill ha med i rapporten",
      },
      DefectType: {
        EN: "Defect Type",
        NO: "Avvikstype",
        SE: "Skadetyp",
      },
      SelectDefect: {
        EN: "What defects do you want to have in the report?",
        NO: "Hvilke avvik ønsker du å ha i rapporten?",
        SE: "Vilka defekter vill du ha med i rapporten?",
      },
      ExplainFilters: {
        EN: "Selecting nothing is the same as selecting everything. If you select certain defect types, only defects of those types will show up in the report.",
        NO: "Å velge ingenting er det samme som å velge alt. Hvis du velger visse avvikstyper, vil kun avvik av disse typene vises i rapporten.",
        SE: "Att inte välja något är samma sak som att välja allt. Om du väljer vissa skadetyper, kommer endast defekter av dessa typer att visas i rapporten.",
      },
      SelectImageLists: {
        EN: "What image lists do you want to use?",
        NO: "Hvilke bildelister vil du bruke?",
        SE: "Vilken bildlista vill du använda?",
      },
      SelectImageTypes: {
        EN: "What types of images do you want?",
        NO: "Hvilke typer bilder vil du ha?",
        SE: "Vilka typer av bilder vill du ha med?",
      },
      AdditionalDetails: {
        EN: "Additional Details",
        NO: "Ytterligere detaljer",
        SE: "Extra detaljer",
      },
      AdvancedFilters: {
        EN: "Advanced filters",
        NO: "Avanserte filtre",
        SE: "Avancerade filter",
      },
      Preview: {
        EN: "Preview",
        NO: "Forhåndsvisning",
        SE: "Förhandsvisning",
        SummaryPage: {
          StatisticsSummary: {
            EN: "Report statistics summary",
            NO: "Rapport statistikk sammendrag",
            SE: "Rapportens statistiska sammanfattning",
          },
          ofTotal: {
            EN: "of total",
            NO: "av totalt",
            SE: "av totalt",
          },
          defects: {
            EN: "defects",
            NO: "avvik",
            SE: "defekter",
          },
          imagesWithDefects: {
            EN: "images with defects",
            NO: "bilder med avvik",
            SE: "bilder med defekter",
          },
          Other: {
            EN: "Other",
            NO: "Annen",
            SE: "Annat",
          },
        },
        PageTitle: {
          EN: "Arkion Defect Report",
          NO: "Arkion Avvikrapport",
          SE: "Arkion Skaderapport",
        },
        QRCode: {
          EN: "QR CODE",
          NO: "QR-KODE",
          SE: "QR KOD",
        },
        Property: {
          EN: "Property",
          NO: "Eiendom",
          SE: "Egenskap",
        },
        Value: {
          EN: "Value",
          NO: "Verdi",
          SE: "Värde",
        },
        ImageName: {
          EN: "Image name",
          NO: "Bildenavn",
          SE: "Bildnamn",
        },
        TimeTaken: {
          EN: "Time taken",
          NO: "Tiden tatt",
          SE: "Tid av bild",
        },
        SkyqraftLink: {
          EN: "Arkion link",
          NO: "Arkion link",
          SE: "Arkion länk",
        },
        Location: {
          EN: "Location",
          NO: "Sted",
          SE: "Plats",
        },
      },
    },
  },
  UserAdmin: {
    EN: "User Administration",
    NO: "Brukeradministrasjon",
    SE: "Användarhantering",
    InviteNewUser: {
      EN: "Invite new user",
      NO: "Inviter ny bruker",
      SE: "Bjud in ny användare",
    },
    SearchForUser: {
      EN: "Search for a user",
      NO: "Søk etter en bruker",
      SE: "Sök efter en användare",
    },
    SearchForMissionOrGroup: {
      EN: "Search for a project or a customer",
      NO: "Søk etter et prosjekt eller en kunde",
      SE: "Sök efter ett projekt eller en kund",
    },
    NoUsersFound: {
      EN: "No users found",
      NO: "Ingen brukere funnet",
      SE: "Inga användare hittades",
    },
    LoadingUser: {
      EN: "Loading user",
      NO: "Laster bruker",
      SE: "Laddar användare",
    },
    UserNotFound: {
      EN: "User not found",
      NO: "Bruker ikke funnet",
      SE: "Användare hittades inte",
    },
    FailedToUpdateUser: {
      EN: "Failed to update user",
      NO: "Kunne ikke oppdatere brukeren",
      SE: "Misslyckades att uppdatera användare",
    },
    GiveAccess: {
      EN: "Give access",
      NO: "Gi tilgang",
      SE: "Ge tillgång",
    },
    ExplicitProjectPermissions: {
      EN: "Explicit project permissions for this user",
      NO: "Eksplisitte prosjekttillatelser for denne brukeren",
      SE: "Explicita projekträttigheter för denna användare",
    },
    UnableToEditEmail: {
      EN: "You are not able to update email of users",
      NO: "Du kan ikke oppdatere brukernes e-post",
      SE: "Du har inte behörighet att uppdatera användares email",
    },
    LeaveEditMode: {
      EN: "Leave edit mode",
      NO: "Gå ut av redigeringsmodus",
      SE: "Lämna redigerarläge",
    },
    SaveChanges: {
      EN: "Save changes",
      NO: "Lagre endringer",
      SE: "Spara ändringar",
    },
    UpdateUserProperties: {
      EN: "Update properties of the user",
      NO: "Oppdater egenskapene til brukeren",
      SE: "Uppdatera användarens egenskaper",
    },
    FailedToInvite: {
      EN: "Failed to invite",
      NO: "Kunne ikke invitere",
      SE: "Misslyckades att bjuda in",
    },
    SelectMissions: {
      EN: "Select which projects you want to give",
      NO: "Velg hvilke prosjekter du vil gi",
      SE: "Välj vilka projekt du vill ge",
    },
    AccessTo: {
      EN: "access to",
      NO: "tilgang til",
      SE: "tillgång till",
    },
    GroupProject: {
      EN: "Customer-wide access",
      NO: "Kundeomfattende tilgang",
      SE: "Tillgång till hela kunden",
    },
    AccessTarget: {
      EN: "Where should they have access?",
      NO: "Hvor skal de ha tilgang?",
      SE: "Vart ska de ha tillgång?",
    },
    NoGroup: {
      EN: "No customer",
      NO: "Ingen kunde",
      SE: "Ingen kund",
    },
    AddAccessLevel: {
      EN: "Add new access level",
      NO: "Legg til nytt tilgangsnivå",
      SE: "Lägg till behörighet",
    },
    EmailInvalid: {
      EN: "Email is invalid",
      NO: "E-posten er ugyldig",
      SE: "Mailadressen är inte korrekt",
    },
    NameInvalid: {
      EN: "Name is too short",
      NO: "Navnet er for kort",
      SE: "Namnet är för kort",
    },
    InviteDescription: {
      EN: "Add a users email below to invite them to the Arkion Platform. You can also give them access to a project or an entire customer.",
      NO: "Legg til en brukers e-post nedenfor for å invitere dem til Arkion-plattformen. Du kan også gi dem tilgang til et prosjekt eller en hel kunde.",
      SE: "Lägg till en användares mailadress nedan för att bjuda in dem till Arkion plattform. Du kan även ge dem tillgång till ett projekt eller en kund.",
    },
    SendInvite: {
      EN: "Send invite",
      NO: "Send invitasjon",
      SE: "Skicka inbjudan",
    },
    EmailWillBeSent: {
      EN: "An email will be sent to",
      NO: "E-post vil bli sendt til",
      SE: "Ett mail kommer skickas till",
    },
    PasswordWillBeSent: {
      EN: "with an autogenerated password if they do not already have an account. If they have an account, they will be notified that they have been given new access.",
      NO: "med et autogenerert passord hvis de ikke allerede har en konto. Hvis de har en konto, vil de få beskjed om at de har fått ny tilgang.",
      SE: "med ett autogenererat lösenord om de redan har har ett konto. Om de har ett konto kommer de notifieras om den nya access.",
    },
    UpdatePermissionsFor: {
      EN: "Update permissions for",
      NO: "Oppdater tillatelser for",
      SE: "Uppdatering av rättigheter för",
    },
    RemoveAccessConfirm: {
      EN: "Are you sure you want to remove access for",
      NO: "Er du sikker på at du vil fjerne tilgangen for",
      SE: "Är du säker att du vill ta bort berörighet för",
    },
    RemoveAccessFailed: {
      EN: "Removing access failed for",
      NO: "Kunne ikke fjerne tilgangen for",
      SE: "Borttagning av behörighet misslyckades för",
    },
    TimeBasedAccess: {
      EN: "Time-based access",
      NO: "Tidsbasert tilgang",
      SE: "Tidsbaserad åtkomst",
    },
    RemoveAccess: {
      EN: "Remove access",
      NO: "Fjern tilgang",
      SE: "Ta bort åtkomst",
    },
  },

  words: {
    on: {
      EN: "on",
      NO: "på",
      SE: "på",
    },
    ShowMore: {
      EN: "Show more",
      NO: "Vis mer",
      SE: "Visa mer",
    },
    ShowLess: {
      EN: "Show less",
      NO: "Vis mindre",
      SE: "Visa mindre",
    },
    ShowAll: {
      EN: "Show all",
      NO: "Vis alle",
      SE: "Visa alla",
    },
    ProjectName: {
      EN: "Project name",
      NO: "Prosjektnavn",
      SE: "Projektnamn",
    },
    Severity1: {
      EN: "Critical",
      NO: "Kritisk",
      SE: "Kritisk",
    },
    Severity2: {
      EN: "Issue",
      NO: "Bemerkning",
      SE: "Anmärkning",
    },
    Severity3: {
      EN: "Should be fixed",
      NO: "Bør fikses",
      SE: "Bör åtgärdas",
    },
    Severity4: {
      EN: "Good to know",
      NO: "Godt å vite",
      SE: "För info",
    },
    Start: {
      EN: "Start date",
      NO: "Startdato",
      SE: "Startdatum",
    },
    End: {
      EN: "End date",
      NO: "Sluttdato",
      SE: "Slutdatum",
    },
    Severity: {
      EN: "Severity",
      NO: "Alvorlighetsgrad",
      SE: "Allvarlighetsgrad",
    },
    Confirm: {
      EN: "Confirm",
      NO: "Bekrefte",
      SE: "Bekräfta",
    },
    Clear: {
      EN: "Clear",
      NO: "Klar",
      SE: "Rensa",
    },
    Of: {
      EN: "of",
      NO: "av",
      SE: "av",
    },
    Failed: {
      EN: "failed",
      NO: "mislyktes",
      SE: "misslyckades",
    },
    Note: {
      EN: "Note",
      NO: "Note",
      SE: "Anteckning",
    },
    By: {
      EN: "by",
      NO: "ved",
      SE: "av",
    },
    Has: {
      EN: "has",
      NO: "har",
      SE: "har",
    },
    Customer: {
      EN: "Customer",
      NO: "Kunde",
      SE: "Kund",
    },
    Region: {
      EN: "Region",
      NO: "Region",
      SE: "Region",
    },
    Mission: {
      EN: "Project",
      NO: "Prosjekt",
      SE: "Projekt",
    },
    Deleted: {
      EN: "Deleted",
      NO: "Slettet",
      SE: "Raderad",
    },
    Welcome: {
      EN: "Welcome",
      NO: "Velkomst",
      SE: "Välkommen",
    },
    ID: {
      EN: "ID",
      NO: "ID",
      SE: "ID",
    },
    Folder: {
      EN: "Folder",
      NO: "Mappe",
      SE: "Mapp",
    },
    Timestamp: {
      EN: "Timestamp",
      NO: "Tidsstempel",
      SE: "Tidsstämpel",
    },
    IP: {
      EN: "IP",
      NO: "IP",
      SE: "IP",
    },
    None: {
      EN: "None",
      NO: "Ingen",
      SE: "Ingen",
    },
    Type: {
      EN: "Type",
      NO: "Type",
      SE: "Typ",
    },
    You: {
      EN: "Client",
      NO: "Klient",
      SE: "Klient",
    },
    Name: {
      EN: "Name",
      NO: "Navn",
      SE: "Namn",
    },
    DroneOperator: {
      EN: "Drone operator / Helicopter operator / Data collector",
      NO: "Droneoperatør / Helikopteroperatør / Datainnsamler",
      SE: "Drönaroperatör / Helikopteroperatör / Datainsamlare",
    },
    Analysis: {
      EN: "Analysis",
      NO: "Analyse",
      SE: "Analys",
    },
    Comment: {
      EN: "Comment",
      NO: "Kommentar",
      SE: "Kommentar",
    },
    OpenInNewTab: {
      EN: "Open in new tab",
      NO: "Åpne i ny fane",
      SE: "Öppna i ny flik",
    },
    EditInNewTab: {
      EN: "Edit in new tab",
      NO: "Rediger i ny fane",
      SE: "Redigera i ny flik",
    },
    Email: {
      EN: "Email",
      NO: "E-post",
      SE: "Epost",
    },
    PhoneNumber: {
      EN: "Phone number",
      NO: "Telefonnummer",
      SE: "Telefonnummer",
    },
    Edit: {
      EN: "Edit",
      NO: "Redigere",
      SE: "Ändra",
    },
    Year: {
      EN: "Year",
      NO: "År",
      SE: "Årtal",
    },
    Poles: {
      EN: "poles",
      NO: "stolper",
      SE: "stolpar",
    },
    Image: {
      EN: "Image",
      NO: "Bilde",
      SE: "Bild",
    },
    Object: {
      EN: "Object",
      NO: "Gjenstand",
      SE: "Objekt",
    },
    Users: {
      EN: "Users",
      NO: "Brukere",
      SE: "Användare",
    },
    User: {
      EN: "User",
      NO: "Bruker",
      SE: "Användare",
    },
    Assignment: {
      EN: "Assignment",
      NO: "Tildeling",
      SE: "Tilldelning",
    },
    Fixed: {
      EN: "Defect fixed",
      NO: "Avvik fikset",
      SE: "Skada lagad",
    },
    Yes: {
      EN: "Yes",
      NO: "Ja",
      SE: "Ja",
    },
    No: {
      EN: "No",
      NO: "Ingen",
      SE: "Nej",
    },
    AccessLevel: {
      EN: "Access level",
      NO: "Tilgangsnivå",
      SE: "Behörighetsnivå",
    },
    TheUser: {
      EN: "the user",
      NO: "brukeren",
      SE: "användaren",
    },
    GoToNewTool: {
      EN: "Go to the new tool",
      NO: "Gå til det nye verktøyet",
      SE: "Gå till det nya verktyget",
    },
    ThisIsYou: {
      EN: "This is you",
      NO: "Dette er deg",
      SE: "Detta är du",
    },
    Placed: {
      EN: "placed",
      NO: "plassert",
      SE: "placerad",
    },
    For: {
      EN: "for",
      NO: "til",
      SE: "för",
    },
  },
  ClientSetting: {
    CustomizeDefectTypes: {
      EN: "Customize defect types",
      NO: "Tilpass avvikstyper",
      SE: "Anpassa skadekategorier",
    },
    ClientSeverityTypes: {
      EN: "Client severity types",
      NO: "Klientens alvorlighetsgrad",
      SE: "Klient allvarlighetsgrad typer",
    },
    ClientDefectType: {
      EN: "Client defect type",
      NO: "Klient avvikstype",
      SE: "Klient skadetyper",
    },
    AddANewClientDefectType: {
      EN: "Add a new client defect type",
      NO: "Legg til en ny klient avvikstype",
      SE: "Lägg till en ny klient skadetyp",
    },
    AddANewClientSeverityType: {
      EN: "Add a new client severity type",
      NO: "Legg til en ny klientalvorlighetstype",
      SE: "Lägg till en ny klient allvarlighetsgradstyp",
    },
    DidYouMissAnyTypes: {
      EN: "Did you miss any types in the list? Please, add it",
      NO: "Gikk du glipp av noen typer i listen? Vær så snill, legg den til",
      SE: "Saknade du några typer i listan? Lägg gärna till den",
    },
    TypeName: {
      EN: "type name",
      NO: "skriv navn",
      SE: "typ namn",
    },
    Color: {
      EN: "color",
      NO: "farge",
      SE: "färg",
    },
    ClientInternalId: {
      EN: "client internal id",
      NO: "klientens interne ID",
      SE: "klient id internt bruk",
    },
    SeverityName: {
      EN: "severity name",
      NO: "alvorlighetsgradsnavn",
      SE: "allvarlighetsgrad namn",
    },
    EditClientSeverities: {
      EN: "Edit client severities",
      NO: "Rediger klientens alvorlighetsgrad",
      SE: "Redigera klient allvarlighetsgrader",
    },
    AddSeverity: {
      EN: "Select a severity or type in a new one",
      NO: "Velg en alvorlighetsgrad eller skriv inn en ny",
      SE: "Välj en allvarlighetsgrad eller skriv in en ny",
    },
    ClientObjectTypeCategory: {
      EN: "Client object type category",
      NO: "Klientobjekttypekategori",
      SE: "Klient objekttyp kategori",
    },
    AddANewClientObjectTypeCategory: {
      EN: "Add a new client object type category",
      NO: "Legg til en ny klientobjekttypekategori",
      SE: "Lägg till en ny klient objekttyps kategori",
    },
    DidYouMissAnyCategories: {
      EN: "Did you miss any categories in the list? Please, add it",
      NO: "Gikk du glipp av noen kategorier i listen? Vær så snill, legg den til",
      SE: "Saknade du några kategorier i listan? Lägg gärna till den",
    },
    CategoryName: {
      EN: "category name",
      NO: "kategorinavn",
      SE: "kategori namn",
    },
    ClientInternalCategoryId: {
      EN: "client internal category id",
      NO: "klientens interne kategori-ID",
      SE: "klient kategori id internt bruk",
    },
    EditClientIssueType: {
      EN: "Edit client defect type for id",
      NO: "Rediger klientavvikstype for id",
      SE: "Redigera klient skadetype för id",
    },
    AddClientDefectType: {
      EN: "Select a defect type or type in a new one",
      NO: "Velg en avvikstype eller skriv inn en ny",
      SE: "Välj en skadetyp eller skriv in en ny",
    },
    ClientInternaldefectCode: {
      EN: "Client internal defect code that can be included in all excel reports",
      NO: "Klientens interne avvikskode som kan inkluderes i alle excel-rapporter",
      SE: "Klient internt id för skadetyp som kan inkluderas i excelrapporter",
    },
  },
  Markers: {
    LidarMarkers: {
      Update: {
        EN: "Update",
        NO: "Oppdater",
        SE: "Uppdatera",
        Title: {
          EN: "Update clearance marker",
          NO: "Oppdater klaringsmarkør",
          SE: "Uppdatera rensningsmarkör",
        },
        Description: {
          EN: "Updating the clearance marker will change the severity level of the lidar marker.",
          NO: "Oppdatering av klaringsmarkøren vil endre alvorlighetsgraden til lidarmarkøren.",
          SE: "Uppdatering av rensningsmarkörer kommer att ändra allvarlighetsgraden för lidarmarkören.",
        },
      },
      Cancel: {
        EN: "Cancel",
        NO: "Kansellere",
        SE: "Avbryt",
      },
      Reset: {
        EN: "Reset",
        NO: "Tilbakestill",
        SE: "Återställ",
      },
    },
    ClearanceMarkers: {
      Verify: {
        EN: "Verify",
        NO: "Verifisere",
        SE: "Verifiera",
        Title: {
          EN: "Verify clearance marker",
          NO: "Bekreft klaringsmarkøren",
          SE: "Verifiera rensningsmarkör",
        },
        Description: {
          EN: "Marking the clearance marker as verified will remove the clearance marker from the map, and be replaced by a lidar marker. The lidar marker is visible to customers.",
          NO: "Merking av klaringsmarkøren som verifisert vil fjerne klaringsmarkøren fra kartet og erstattes av en lidarmarkør. Lidarmarkøren er synlig for kundene.",
          SE: "Markering av rensningsmarkörer som verifierad kommer att ta bort rensningsmarkören från kartan och ersättas med en lidarmarkör. Lidarmarkören är synlig för kunder.",
        },
      },
      Cancel: {
        EN: "Cancel",
        NO: "Kansellere",
        SE: "Avbryt",
      },
      Reject: {
        EN: "Reject",
        NO: "Avvis",
        SE: "Avvisa",
      },
    },
  },
  Filter: {
    EN: "Filter",
    NO: "Filter",
    SE: "Filter",
    FindInsights: {
      EN: "Find insights in your grid",
      NO: "Finn innsikt i nettet ditt",
      SE: "Hitta insikter i ditt nät",
    },
    CreateReport: {
      EN: "Create report",
      NO: "Opprett rapport",
      SE: "Skapa rapport",
    },
    ApplyFilter: {
      EN: "Apply filter",
      NO: "Bruk filter",
      SE: "Applicera filter",
    },
    ClearFilter: {
      EN: "Clear filter",
      NO: "Tøm filter",
      SE: "Rensa filter",
    },
    DisableFilter: {
      EN: "Disable filter",
      NO: "Deaktiver filter",
      SE: "Inaktivera filter",
    },
    SaveCurrentFilter: {
      EN: "Save current filter",
      NO: "Lagre gjeldende filter",
      SE: "Spara nuvarande filter",
    },
    Severity: {
      EN: "Severity",
      NO: "Nivå",
      SE: "Nivå",
    },
    Electric: {
      EN: "Electric",
      NO: "Elektrisk",
      SE: "El",
    },
    Vegetation: {
      EN: "Vegetation",
      NO: "Vegetasjon",
      SE: "Vegetation",
    },
    Spatial: {
      EN: "Spatial",
      NO: "Romlig",
      SE: "Spatialt",
    },
    Saved: {
      EN: "Saved",
      NO: "Lagret",
      SE: "Sparat",
    },
    Close: {
      EN: "Close",
      NO: "Lukke",
      SE: "Stäng",
    },
    Defects: {
      EN: "Defects",
      NO: "Avvik",
      SE: "Defekter",
    },
    Objects: {
      EN: "Objects",
      NO: "Objekter",
      SE: "Objekt",
    },
    SavedFilters: {
      EN: "Your saved filters",
      NO: "Dine lagrede filtre",
      SE: "Dina sparade filter",
    },
    ListFilters: {
      EN: "Saved image lists",
      NO: "Lagrede bildelister",
      SE: "Sparde bildlistor",
    },
    AdvancedFilters: {
      EN: "Advanced filters",
      NO: "Avanserte filtre",
      SE: "Avancerade filter",
    },
    SkyqraftHidden: {
      EN: "Arkion hidden",
      NO: "Arkion skjult",
      SE: "Arkion dold",
    },
    EnterNameOfType: {
      EN: "Enter the name of an object type",
      NO: "Skriv inn navnet på en objekttype",
      SE: "Skriv in namnet på en objekttyp",
    },
    SearchForObjectType: {
      EN: "Search for object type",
      NO: "Søk etter objekttype",
      SE: "Sök efter objekttyp",
    },
    NoTypeMatches: {
      EN: "No object type matches",
      NO: "Ingen objekttype samsvarer",
      SE: "Ingen objekttyp matchar",
    },
    Loading: {
      EN: "Loading",
      NO: "Laster",
      SE: "Laddar",
    },
    ActorsFilter: {
      EN: "Actors filter",
      NO: "Skuespillere filter",
      SE: "Aktörsfilter",
    },
    ComingSoon: {
      EN: "Coming soon",
      NO: "Kommer snart",
      SE: "Kommer snart",
    },
    SelectArea: {
      EN: "Select Area",
      NO: "Velg Område",
      SE: "Välj Område",
    },
    UnSelectArea: {
      EN: "Unselect Area",
      NO: "Fjern markeringen av område",
      SE: "Avmarkera Område",
    },
    CleranceAxis: {
      EN: "Clerance Axis",
      NO: "Clerance-aksen",
      SE: "Rensningsaxel",
    },
    ClearanceRule: {
      EN: "Clearance Rule",
      NO: "Klareringsregel",
      SE: "Rensningsregel",
    },
    DateFilter: {
      EN: "Date filter",
      NO: "Datofilter",
      SE: "Datumfilter",
    },
    DateImageCaptured: {
      EN: "Date image captured",
      NO: "Datobilde tatt",
      SE: "Datum bild tagen",
    },
    DateImageUploaded: {
      EN: "Date image uploaded",
      NO: "Dato bildet ble lastet opp",
      SE: "Datum bild uppladdad",
    },
    DateDefectAdded: {
      EN: "Date defect added",
      NO: "Dato avvik lagt til",
      SE: "Datum skada tillagd",
    },
    DateDefectProcessed: {
      EN: "Date defect processed",
      NO: "Dato avvik behandlet",
      SE: "Datum skada behandlad",
    },
    DateDefectFixed: {
      EN: "Date defect fixed",
      NO: "Dato avvik fikset",
      SE: "Datum skada lagad",
    },
    DateDefectReported: {
      EN: "Date defect reported",
      NO: "Dato avvik rapportert",
      SE: "Datum skada rapporterad",
    },
    SelectDate: {
      EN: "Select date",
      NO: "Velg dato",
      SE: "Välj datum",
    },
    ShowDsoTso: {
      EN: "Show DSO and TSO structures",
      NO: "Vis DSO- og TSO-strukturer",
      SE: "Visa DSO och TSO strukturer",
    },
    DefectNotReported: {
      EN: "Show not reported defects",
      NO: "Vis ikke rapporterte mangler",
      SE: "Visa ej rapporterade defekter",
    },
    Flight: {
      EN: "Flight",
      NO: "Flyvning",
      SE: "Flygning",
    },
    SelectFlight: {
      EN: "Select flight",
      NO: "Velg flyreise",
      SE: "Välj flygning",
    },
    LidarMarkers: {
      EN: "3D (lidar) markers",
      NO: "3D (lidar) markører",
      SE: "3D (lidar) markörer",
    },
    MachineDetectedDefects: {
      EN: "Machine detected defects",
      NO: "Maskinen oppdaget avvik",
      SE: "Maskinidentifierade defekter",
    },
    NewDefects: {
      EN: "New defects",
      NO: "Nye avvik",
      SE: "Nya defekter",
    },
    EnterNameForFilter: {
      EN: "Enter name for filter",
      NO: "Skriv inn navn for filter",
      SE: "Skriv in namn för filter",
    },
    NoSavedFilters: {
      EN: "No saved filters",
      NO: "Ingen lagrede filtre",
      SE: "Inga sparade filter",
    },
    PressStarToSave: {
      EN: "Press the star to save the filter",
      NO: "Trykk på stjernen for å lagre filteret",
      SE: "Tryck på stjärnan för att spara filtret",
    },
    SelectFilterToSave: {
      EN: "Select a filter to create a saved filter",
      NO: "Velg et filter for å opprette et lagret filter",
      SE: "Välj ett filter för att skapa ett sparat filter",
    },
    Activate: {
      EN: "Activate",
      NO: "Aktiver",
      SE: "Aktivera",
    },
    DeleteConfirmationFilter: {
      EN: "Are you sure you want to delete this filter?",
      NO: "Er du sikker på at du vil slette dette filteret?",
      SE: "Är du säker att du vill radera detta filter?",
    },
    Defect: {
      EN: "Defect",
      NO: "Avvik",
      SE: "Kritisk",
    },
    Remarks: {
      EN: "Remarks",
      NO: "Merknader",
      SE: "Upplysningar",
    },
    Severity1: {
      EN: "Critical",
      NO: "Kritisk",
      SE: "Kritisk",
    },
    Severity2: {
      EN: "Issue",
      NO: "Bemerkning",
      SE: "Anmärkning",
    },
    Severity3: {
      EN: "Should be fixed",
      NO: "Bør fikses",
      SE: "Bör åtgärdas",
    },
    Severity4: {
      EN: "Good to know",
      NO: "Godt å vite",
      SE: "För info",
    },
    HideHidden: {
      EN: "Hide hidden objects",
      NO: "Skjul skjulte objekter",
      SE: "Dölj dolda objekt",
    },
    ShowAll: {
      EN: "Show all objects",
      NO: "Vis alle objekter",
      SE: "Visa alla objekt",
    },
    HiddenOnly: {
      EN: "Show only hidden objects",
      NO: "Vis kun skjulte objekter",
      SE: "Visa endast dolda objekt",
    },
    FeedBayFilter: {
      EN: "Feed bay filter",
      NO: "Feed bay filter",
      SE: "Fack filter",
    },
    NoFeedBaysFound: {
      EN: "No feed bays found",
      NO: "Fant ingen fôrplasser",
      SE: "Inga fack hittades",
    },
    FeedBaySearch: {
      EN: "Feed Bay Search",
      NO: "Feed Bay Search",
      SE: "Sök fack",
      Section1: {
        EN: "Search for a feed bay by name. You can then chose to hide all other powerlines, or highlight the selected feed bay.",
        NO: "Søk etter en feed bay etter navn. Du kan da velge å skjule alle andre kraftledninger, eller markere den valgte innmatingsbrønnen.",
        SE: "Sök efter ett fack efter namn. Du kan sedan välja att dölja alla andra kraftledningar, eller markera den valda fack.",
      },
      Section2: {
        EN: "You can select multiple feed bays to visualize on the map and find anything and evetything you need.",
        NO: "Du kan velge flere innmatingsfelt for å visualisere på kartet og finne alt og alt du trenger.",
        SE: "Du kan välja flera fack att visualisera på kartan och hitta allt du behöver.",
      },
    },
    GenerateColors: {
      EN: "Generate colors automatically",
      NO: "Generer farger automatisk",
      SE: "Generera färger automatiskt",
    },
    Show: {
      EN: "Show",
      NO: "Vise",
      SE: "Visa",
    },
    Cancel: {
      EN: "Cancel",
      NO: "Kansellere",
      SE: "Avbryt",
    },
    WhatIs3D: {
      EN: "What is 3D data? (LiDAR)",
      NO: "Hva er 3D-data? (LiDAR)",
      SE: "Vad är 3D data? (LiDAR)",
      Title: {
        EN: "3D data (LiDAR)",
        NO: "3D-data (LiDAR)",
        SE: "3D data (LiDAR)",
      },
      Section1: {
        EN: "Our platform support data analysis in both 2D (Images) and 3D (LiDAR). Images are excellent for detecting visual damages on your equipment. However, the images are not optimal for seeing the vegetation around your grid.",
        NO: "Plattformen vår støtter dataanalyse i både 2D (bilder) og 3D (LiDAR). Bilder er utmerket for å oppdage visuelle avvik på utstyret ditt. Bildene er imidlertid ikke optimale for å se vegetasjonen rundt rutenettet ditt.",
        SE: "Vår plattform stödjer dataanalys i både 2D (bilder) och 3D (LiDAR). Bilder är utmärkta för att upptäcka visuella skador på din utrustning. Dock är bilderna inte optimala för att se vegetationen runt ditt nät.",
      },
      Section2: {
        EN: "LiDAR data is one of the best methods to identify and analyze potential issues with vegetation. The 3D data can be coupled with the 2D images in our platform to give you a complete overview of your grid.",
        NO: "LiDAR-data er en av de beste metodene for å identifisere og analysere potensielle problemer med vegetasjon. 3D-dataene kan kobles sammen med 2D-bildene i plattformen vår for å gi deg en fullstendig oversikt over rutenettet ditt.",
        SE: "LiDAR data är en av de bästa metoderna för att identifiera och analysera potentiella problem med vegetation. 3D-data kan kopplas samman med 2D-bilder i vår plattform för att ge dig en komplett översikt över ditt nät.",
      },
    },
    WhatAreTheseFilters: {
      EN: "What are these filters?",
      NO: "Hva er disse filtrene?",
      SE: "Vad är dessa filter?",
      Title: {
        EN: "Spatial filters",
        NO: "Romlige filtre",
        SE: "Spatiala filter",
      },
      AreaFilter: {
        EN: "Area filter",
        NO: "Områdefilter",
        SE: "Områdesfilter",
        Description: {
          EN: "The area filter allows you to select a specific area on the map to filter for. To clear the filter, click the button again.",
          NO: "Områdefilteret lar deg velge et spesifikt område på kartet du vil filtrere etter. For å tømme filteret, klikk på knappen igjen.",
          SE: "Områdesfiltret låter dig välja ett specifikt område på kartan att filtrera för. För att rensa filtret, klicka på knappen igen.",
        },
      },
      FlightFilter: {
        EN: "Flight filter",
        NO: "Flyfilter",
        SE: "Flygfilter",
        Description: {
          EN: "Each upload of images is called a flight. The flight filter allows you to see how many images and what folder the upload is in. You can select multiple flights to filter for.",
          NO: "Hver opplasting av bilder kalles en flytur. Flyfilteret lar deg se hvor mange bilder og hvilken mappe opplastingen er i. Du kan velge flere flyreiser å filtrere etter.",
          SE: "Varje uppladdning av bilder kallas ett flyg. Flygfilteret låter dig se hur många bilder och vilken mapp uppladdningen är i. Du kan välja flera flyg för att filtrera för.",
        },
      },
      DateSelector: {
        EN: "Date selector",
        NO: "Datovelger",
        SE: "Datumväljare",
        Description: {
          EN: "The date selector allows you to search for items within specific date ranges. The date can either be one date (filtering out everything that happened after that date), or two dates (filtering out everyhing that happened between those dates).",
          NO: "Datovelgeren lar deg søke etter elementer innenfor bestemte datoperioder. Datoen kan enten være én dato (filtrerer ut alt som skjedde etter den datoen), eller to datoer (filtrerer ut alt som skjedde mellom disse datoene).",
          SE: "Datumväljaren låter dig söka efter objekt inom specifika datumintervall. Datumet kan antingen vara ett datum (filtrera ut allt som hände efter det datumet) eller två datum (filtrera ut allt som hände mellan de datumen).",
        },
      },
      FeedBay: {
        EN: "Feed bay",
        NO: "Feed bay",
        SE: "Fack",
        Description: {
          EN: "The feed bay allows you to visualize how each powerline is electrically connected. The feed bay is specified when uploading the powerline. If you want to add or modify the feed bay.",
          NO: "Matebrønnen lar deg visualisere hvordan hver kraftledning er elektrisk tilkoblet. Matebrønnen spesifiseres ved opplasting av kraftledningen. Hvis du vil legge til eller endre innmatingsfeltet.",
          SE: "Fack låter dig visualisera hur varje kraftledning är elektriskt ansluten. Fack är definerad vid uppladdningen av kraftledningen. Om du vill lägga till eller ändra fack.",
        },
      },
      NewDefects: {
        EN: "New defects",
        NO: "Nye avvik",
        SE: "Nya defekter",
        Description: {
          EN: "The filter shows you all defects that you have not seen before. If you open an image with defects, the defects will be marked as seen. More specifically, any defect that was created after you last viewed an image will be flagged as a new defect. (or if you have not viewed the image yet)",
          NO: "Filteret viser deg alle avvik du ikke har sett før. Hvis du åpner et bilde med avvik, vil avvikene merkes som sett. Mer spesifikt vil enhver avvik som ble opprettet etter at du sist så på et bilde, bli flagget som et nytt avvik. (eller hvis du ikke har sett bildet ennå)",
          SE: "Filtret visar dig alla defekter som du inte har sett tidigare. Om du öppnar en bild med defekter kommer defekterna att markeras som sedda. Mer specifikt kommer alla defekter som skapades efter att du senast såg en bild att flaggas som en ny defekt. (eller om du inte har sett bilden ännu)",
        },
      },
    },
    WhatAreTheseNumbers: {
      EN: "What are these numbers?",
      NO: "Hva er disse tallene?",
      SE: "Vad är dessa nummer?",
      Title: {
        EN: "Filter numbers",
        NO: "Filtrer tall",
        SE: "Filter nummer",
      },
      Section1: {
        EN: "The numbers presented represent the amount of objects (bounding boxes) found on images in this project. If there are 10 critical defects found, then the number 10 will be shown next to the 'Critical' filter.",
        NO: "Tallene som presenteres representerer mengden av objekter (avgrensingsbokser) funnet på bilder i dette prosjektet. Hvis det er 10 kritiske avvik, vil tallet 10 vises ved siden av 'Kritisk'-filteret.",
        SE: "De presenterade numren representerar antalet objekt (bounding boxes) som hittats på bilder i detta projekt. Om det finns 10 kritiska defekter hittade, kommer numret 10 att visas bredvid 'Kritisk' filtret.",
      },
      Section2: {
        EN: "If there are multiple filters selected, the number will represent the amount of objects that match all the selected filters.",
        NO: "Hvis det er valgt flere filtre, vil antallet representere mengden objekter som samsvarer med alle de valgte filtrene.",
        SE: "Om det finns flera filter valda, kommer numret att representera antalet objekt som matchar alla valda filter.",
      },
      Section3: {
        EN: "The numbers in the filters represent the number of defects that match the filter. The numbers are updated every 15 minutes.",
        NO: "Tallene i filtrene representerer antall avvik som samsvarer med filteret. Tallene oppdateres hvert 15. minutt.",
        SE: "Numren i filtren representerar antalet defekter som matchar filtret. Numren uppdateras var 15:e minut.",
      },
    },
    WhatIsDefect: {
      EN: "What is a Defect or Objects?",
      NO: "Hva er et avvik eller objekter?",
      SE: "Vad är en defekt eller objekt?",
      Title: {
        EN: "Defect vs Objects",
        NO: "Avvik vs objekter",
        SE: "Defekt vs Objekt",
      },
      Section1: {
        EN: "A defect is an identified problem found on an image. A defect always has a severity level accompanied with it. The meaning of each severity level depends on your internal guidelines.",
        NO: "Et avvik er et identifisert problem funnet på et bilde. Et avvik har alltid et alvorlighetsnivå. Betydningen av hvert alvorlighetsnivå avhenger av dine interne retningslinjer.",
        SE: "En defekt är ett identifierat problem som hittats på en bild. En defekt har alltid en allvarlighetsgrad kopplad till sig. Betydelsen av varje allvarlighetsgrad beror på dina interna riktlinjer.",
      },
      Section2: {
        EN: "An object is an element we have identified on an image, but it is not necessarily a defect. Detections are very useful for finding components in your grid. For example, you can use detections to find where all your transformers are located.",
        NO: "Et objekt er et element vi har identifisert på et bilde, men det er ikke nødvendigvis et avvik. Deteksjoner er svært nyttige for å finne komponenter i rutenettet ditt. Du kan for eksempel bruke deteksjoner for å finne hvor alle transformatorene dine er plassert.",
        SE: "Ett objekt är ett element som vi har identifierat på en bild, men det är inte nödvändigtvis en defekt. Detekteringar är mycket användbara för att hitta komponenter i ditt nät. Till exempel kan du använda detekteringar för att hitta var alla dina transformatorer är placerade.",
      },
      Section3: {
        EN: "You can rename all types using the",
        NO: "Du kan gi nytt navn til alle typer ved å bruke",
        SE: "Du kan döpa om alla typer med hjälp av",
      },
      ClientSettings: {
        EN: "client settings",
        NO: "klientinnstillinger",
        SE: "klientinställningar",
      },
    },
  },
  notifications: {
    noTopologyDataFound: {
      EN: "No topology data found",
      NO: "Fant ingen topologidata",
      SE: "Ingen topologidata hittades",
    },
  },
  electricityFlow: {
    GeneratingFlow: {
      EN: "Generating flow. This can take a few minutes.",
      NO: "Generer flyt. Dette kan ta noen minutter.",
      SE: "Genererar flöde. Detta kan ta några minuter.",
    },
    FlowGenerated: {
      EN: "Flow generated successfully.",
      NO: "Flow generert vellykket.",
      SE: "Flöde genererat framgångsrikt.",
    },
    FailedToGenerateFlow: {
      EN: "Failed to generate flow",
      NO: "Kunne ikke generere flyt",
      SE: "Misslyckades att generera flöde",
    },
    DeletingFlow: {
      EN: "Deleting flow. This can take a few minutes.",
      NO: "Sletter flyt. Dette kan ta noen minutter.",
      SE: "Raderar flöde. Detta kan ta några minuter.",
    },
    FlowDeleted: {
      EN: "Flow deleted successfully.",
      NO: "Flow slettet.",
      SE: "Flöde raderat framgångsrikt.",
    },
    FailedToDeleteFlow: {
      EN: "Failed to delete flow",
      NO: "Kunne ikke slette flyten",
      SE: "Misslyckades att radera flöde",
    },
    FlowModalOne: {
      EN: "The electricity flow generator will analyze the powerlines and the poles that exist on this project. It will calculate which direction the electricity flows based on the labels of the poles",
      NO: "Elektrisitetsstrømgeneratoren vil analysere kraftledningene og polene som finnes på dette prosjektet. Den vil beregne hvilken retning elektrisiteten flyter basert på etikettene til mastene",
      SE: "Elflödesgeneratorn kommer att analysera kraftledningarna och stolparna som finns på detta projekt. Den kommer att beräkna i vilken riktning elen flödar baserat på stolparnas etiketter",
    },
    FlowModalTwo: {
      EN: "The flow is from a lower label to a higher label. This will take about one minute for smaller projects. Larger projects will most likely take longer.",
      NO: "Flyten går fra en lavere etikett til en høyere etikett. Dette vil ta omtrent ett minutt for mindre prosjekter. Større prosjekter vil mest sannsynlig ta lengre tid.",
      SE: "Flödet är från en lägre littra till en högre littra. Detta tar cirka en minut för mindre projekt. Större projekt kommer troligen att ta längre tid.",
    },
    DeleteExisting: {
      EN: "Delete existing",
      NO: "Slett eksisterende",
      SE: "Radera befintlig",
    },
    GenerateFlow: {
      EN: "Generate flow",
      NO: "Generer flyt",
      SE: "Generera flöde",
    },
    ElectricityFlow: {
      EN: "Electricity flow",
      NO: "Elektrisitet flyt",
      SE: "Elflöde",
    },
  },
};

export default translations;
